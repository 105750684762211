import { PropsWithChildren } from "react";
import { useDrop } from "react-dnd";

type Props = {
  name: string;
};

export const ModuleDropField = ({
  children,
  name,
}: PropsWithChildren<Props>) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "module",
    drop: () => ({ name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = "transparent";
  if (isActive) {
    backgroundColor = "#f9f9f9";
  } else if (canDrop) {
    backgroundColor = "#fbfbfb";
  }

  return (
    <div
      ref={drop}
      style={{
        backgroundColor,
        minHeight: "500px",
        display: "flex",
        width: "300px",
        paddingTop: "1.5em",
        flexDirection: "column",
        marginLeft: "-16px",
        paddingLeft: "16px",
        borderRadius: "16px",
        paddingBottom: "2em",
      }}
      data-testid={name}
    >
      <div>{children}</div>
    </div>
  );
};
