import { gql } from "@apollo/client";
export type {
  DeleteSearchTagMutation,
  DeleteSearchTagMutationVariables,
} from "./__generated__/DeleteSearchTagMutation";

export const DELETE_SEARCH_TAG_MUTATION = gql`
  mutation DeleteSearchTagMutation($id: Float!) {
    deleteSearchTag(tagId: $id) {
      id
      tag
    }
  }
`;
