import { createSlice } from "@reduxjs/toolkit";
import { ListRewardActivities } from "queries/reward/listActivities";
import { ListRewards } from "queries/reward/listRewards";
import {
  deleteReward,
  getReward,
  listRewardActivities,
  listRewards,
  publishReward,
  reorderRewards,
  unpublishReward,
  updateRewardActivity,
  upsertReward,
} from "./thunks";

interface RewardState {
  rewards: ListRewards["rewards"];
  rewardType: "CHARITY" | "SHOP";
  activities: Omit<ListRewardActivities["rewardActions"], "__typename">;
  activityCounts?: ListRewardActivities["rewardActivityCount"];
}

const initialState: RewardState = {
  rewards: [],
  rewardType: "CHARITY",
  activities: {
    items: [],
    total: 0,
    limit: 0,
    offset: 0,
  },
};

export const rewardSlice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    setRewardsTypeFilter(state, action) {
      state.rewardType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listRewards.fulfilled, (state, action) => {
      state.rewards = action.payload.rewards;
    });
    builder.addCase(publishReward.fulfilled, (state, action) => {
      // find the reward in the state and update it
      state.rewards = state.rewards.map((reward) => {
        if (reward.id === action.payload.publishReward.id) {
          reward.publishedAt = action.payload.publishReward.publishedAt;
        }
        return reward;
      });
    });
    builder.addCase(unpublishReward.fulfilled, (state, action) => {
      // find the reward in the state and update it
      state.rewards = state.rewards.map((reward) => {
        if (reward.id === action.payload.unpublishReward.id) {
          reward.publishedAt = null;
        }
        return reward;
      });
    });
    builder.addCase(deleteReward.fulfilled, (state, action) => {
      // find the reward in the state and remove it
      state.rewards = state.rewards.filter((reward) => {
        return reward.id !== action.payload.deleteReward.id;
      });
    });
    builder.addCase(getReward.fulfilled, (state, action) => {
      // insert the reward into the state if it doesn't exist
      const { reward } = action.payload;
      const index = state.rewards.findIndex((r) => r.id === reward.id);
      if (index === -1) {
        state.rewards.push(reward);
      } else {
        state.rewards[index] = reward;
      }
    });
    builder.addCase(upsertReward.fulfilled, (state, action) => {
      // insert the reward into the state if it doesn't exist
      const reward = action.payload.upsertReward;
      const index = state.rewards.findIndex((r) => r.id === reward.id);
      if (index === -1) {
        state.rewards.push(reward);
      } else {
        state.rewards[index] = reward;
      }
    });
    // list reward activities
    builder.addCase(listRewardActivities.fulfilled, (state, action) => {
      const { offset, limit, total } = action.payload.rewardActions;
      state.activities.total = total;
      state.activities.limit = limit;
      state.activities.offset = offset;
      if (offset) {
        state.activities.items.splice(
          offset,
          0,
          ...action.payload.rewardActions.items
        );
      } else {
        state.activities.items = action.payload.rewardActions.items;
      }
      state.activityCounts = action.payload.rewardActivityCount;
    });
    // change reward activity status
    builder.addCase(updateRewardActivity.fulfilled, (state, action) => {
      // find the reward in the state and update it
      state.activities.items = state.activities.items.map((activity) => {
        if (activity.id === action.payload.updateRewardActivity.id) {
          activity.status = action.payload.updateRewardActivity.status;
        }
        return activity;
      });
    });
    // update sorting of rewards
    builder.addCase(reorderRewards.pending, (state, action) => {
      const { ids } = action.meta.arg;
      state.rewards.forEach((reward) => {
        if (ids.includes(reward.id)) {
          reward.sorting = ids.indexOf(reward.id);
        }
      });
    });
  },
});

export const { setRewardsTypeFilter } = rewardSlice.actions;
export default rewardSlice.reducer;
