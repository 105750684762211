import { gql } from "@apollo/client";
export type {
  UpdateLesson,
  UpdateLessonVariables,
} from "./__generated__/UpdateLesson";

export const UPDATE_LESSON = gql`
  mutation UpdateLesson($lesson: JSON!, $lessonId: Float!) {
    updateLesson(lesson: $lesson, lessonId: $lessonId)
  }
`;
