import { Box } from "ui";
import { QuoteElement } from "queries/richTextElementFragment";
import { RichTextEditor, RichTextViewer } from "components/rich-text";

export type QuoteProps = {
  element: QuoteElement;
  isEditMode: boolean;
  onChange: (element: QuoteElement) => void;
};

export const Quote = (props: QuoteProps) => {
  const { element, isEditMode, onChange } = props;

  const handleTextChange = (text: string) => {
    const updatedElement = { ...element, text: text.trim() };
    onChange(updatedElement);
  };

  const editView = (
    <Box>
      <RichTextEditor value={element.text} onChange={handleTextChange} />
    </Box>
  );
  const readView = (
    <Box borderLeftWidth={2} borderLeftStyle="solid">
      <Box ml="s">
        <RichTextViewer
          html={element.text!}
          textStyle={{
            fontSize: 18,
          }}
        />
      </Box>
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
