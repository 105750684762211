import { gql } from "@apollo/client";
import { MODULES_FRAGMENT } from "./modulesFragment";
export type {
  ModulesList,
  ModulesList_getModules,
  ModulesList_getModules_lessons,
} from "./__generated__/ModulesList";

export const LIST_MODULES_QUERY = gql`
  ${MODULES_FRAGMENT}
  query ModulesList {
    getModules {
      ...ModulesFragment
    }
  }
`;
