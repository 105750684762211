import { Edit, UnfoldMore, UnfoldLess, Public } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import DraftIcon from "@mui/icons-material/DesignServices";

import { Box, Text } from "ui";
import {
  ModulesList_getModules,
  ModulesList_getModules_lessons,
} from "queries/learning/modulesList";
import { LessonCard } from "./LessonCard";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils";
import { draftModule } from "state/learning";

type Props = {
  module: ModulesList_getModules;
  draft?: ModulesList_getModules;
  selectedLessonId: number;
  selectedModuleId: number;
  onLessonSelected: (id: number, editMode?: boolean) => void;
  onModuleSelected?: (id: number) => void;
  hideHeader?: boolean;
  collapsed?: boolean;
};
export const ModuleListItem = (props: Props) => {
  const [collapsed, setCollapsed] = useState(props.collapsed ?? true);
  const [editDraft, setEditDraft] = useState(false);
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState(props.module);
  const dispatch = useAppDispatch();

  const {
    onLessonSelected,
    selectedLessonId,
    selectedModuleId,
    onModuleSelected,
  } = props;

  useEffect(() => {
    setModule(editDraft && props.draft ? props.draft : props.module);
  }, [editDraft, props.draft, props.module]);

  const enableDraft = async () => {
    if (!props.draft && props.module.moduleType !== "DRAFT") {
      // dispatch copy the thing, add the draft later as wellt
      setLoading(true);
      const res = await dispatch(draftModule({ moduleId: props.module.id }));
      setLoading(false);
      onModuleSelected?.(res.payload.draftModule.id);
    } else {
      props.draft && onModuleSelected?.(props.draft.id);
    }
    setEditDraft(true);
  };

  const disableDraft = () => {
    setEditDraft(false);
    onModuleSelected?.(props.module.id);
  };

  return (
    <Box>
      {!props.hideHeader && (
        <Box
          display="flex"
          hovered={true}
          backgroundColor={selectedModuleId === module.id ? "grey1" : undefined}
          onClick={() => onModuleSelected?.(module.id)}
          alignItems="center"
          title={`id: ${module.id}`}
        >
          <Text
            mt="m"
            pl="m"
            mb="s"
            width="100%"
            weight="bold"
            style={{
              textDecoration: module.deleted ? "line-through" : undefined,
              color: module.deleted
                ? "#444"
                : module.moduleType === "DRAFT"
                ? "#594"
                : undefined,
            }}
          >
            {loading
              ? "creating the draft for you... 🪄✨"
              : `${module.title}${
                  module.moduleType === "DRAFT" ? " [draft]" : ""
                }`}
          </Text>
          <Box
            className="show-on-hover"
            pl="s"
            flexDirection="row"
            display="flex"
            onClick={(e: any) => e.stopPropagation()}
          >
            {collapsed ? (
              <Tooltip title="Show lessons">
                <UnfoldMore
                  style={{ height: "20px" }}
                  onClick={() => setCollapsed(false)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Hide lessons">
                <UnfoldLess
                  style={{ height: "20px" }}
                  onClick={() => setCollapsed(true)}
                />
              </Tooltip>
            )}
            {editDraft ? (
              <Tooltip title="Work on the live version of this module 🌍">
                <Public style={{ height: "20px" }} onClick={disableDraft} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  props.draft
                    ? "Work on the draft version of this module 💨"
                    : "Create a draft version of this module 🔥"
                }
              >
                <DraftIcon
                  style={{
                    height: "20px",
                    color: props.draft ? "black" : "darkgrey",
                  }}
                  onClick={enableDraft}
                />
              </Tooltip>
            )}
          </Box>
          {props.onModuleSelected && (
            <Box className="show-on-hover">
              <Tooltip title="Edit the module">
                <Edit style={{ height: "20px" }} />
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      {collapsed ? (
        <Box borderBottom="1px solid black" />
      ) : (
        <Box
          backgroundColor={module.moduleType === "DRAFT" ? "#efd" : undefined}
        >
          {module.lessons.map(
            (lesson: ModulesList_getModules_lessons, lIdx: number) => (
              <LessonCard
                key={`lessonsList_module${module.id}_lesson${lesson.id}`}
                lIdx={lIdx}
                selectedLessonId={selectedLessonId}
                deleted={module.deleted}
                lesson={lesson}
                onSelected={() => onLessonSelected(lesson.id)}
                onEditMetadata={() => onLessonSelected(lesson.id, true)}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};
