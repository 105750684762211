import {} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import {
  Fab,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PartnershipFilter } from "components/partnership/PartnershipFilter";
import { RewardsRow } from "components/rewards/RewardRow";
import { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  listPartnerships,
  selectSelectedPartnershipId,
} from "state/partnership";
import {
  listRewards,
  reorderRewards,
  selectRewards,
  selectRewardTypeFilter,
  setRewardsTypeFilter,
} from "state/reward";
import { Box, Text, Button } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

const sortedIds: { value: number[] } = {
  value: [],
};

export const RewardsManagement = () => {
  const rewardsType = useAppSelector(selectRewardTypeFilter);
  const selectedPartnershipId = useAppSelector(selectSelectedPartnershipId);
  const rewards = useAppSelector(
    selectRewards(rewardsType, selectedPartnershipId)
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listRewards());
    dispatch(listPartnerships());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortedIds.value = rewards.map((reward) => reward.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewards]);

  const setRewardsType = (type: string) => {
    dispatch(setRewardsTypeFilter(type));
  };

  const onDrop = useCallback(
    async (itemId: number, targetId: number) => {
      const ids = sortedIds.value.slice();
      let reordered: number[];

      if (targetId === -1) {
        // we're dragging to the bottom
        reordered = [...ids.filter((id) => id !== itemId), itemId];
      } else {
        // insert itemId before targetId
        reordered = ids.filter((id) => id !== itemId);
        const targetIndex = reordered.indexOf(targetId);
        reordered.splice(targetIndex, 0, itemId);
      }
      await dispatch(reorderRewards({ ids: reordered }));
    },
    [dispatch]
  );

  if (!rewards) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex" justifyContent="space-between">
        <Text size="large" mb="m">
          Rewards management
        </Text>
        <PartnershipFilter />
      </Box>
      <Box display="flex" my="l">
        <Button
          label="Donate"
          backgroundColor={rewardsType === "CHARITY" ? "#473FD0" : "#ECECEC"}
          color={rewardsType === "CHARITY" ? "white" : "black"}
          mr="s"
          onClick={() => dispatch(setRewardsTypeFilter("CHARITY"))}
        />
        <Button
          label="Shop"
          backgroundColor={rewardsType === "SHOP" ? "#473FD0" : "#ECECEC"}
          color={rewardsType === "SHOP" ? "white" : "black"}
          mr="s"
          onClick={() => setRewardsType("SHOP")}
        />
      </Box>
      <Text
        variant="info"
        size="small"
        color="#888888"
        mt="m"
        title="oh well this doesn't work yet...stay tuned!"
      >
        Drag and drop to order
      </Text>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Text weight="bold">Brand</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Subcopy</Text>
              </TableCell>
              <TableCell>
                {rewardsType === "CHARITY" && <Text weight="bold">Raised</Text>}
                {rewardsType === "SHOP" && <Text weight="bold">Redeemed</Text>}
              </TableCell>
              <TableCell>
                <Text weight="bold">Status</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map((reward) => (
              <RewardsRow key={reward.id} reward={reward} onDrop={onDrop} />
            ))}
            <RewardsRow
              reward={{ id: -1 } as any}
              onDrop={onDrop}
              unDraggable={true}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box position="absolute" bottom={20} right={20}>
        <Link to="/rewards-management/new">
          <Fab color="primary">
            <AddIcon />
          </Fab>
        </Link>
      </Box>
    </Box>
  );
};
