import { gql } from "@apollo/client";
export type {
  CommunityPollStatisticsQuery,
  CommunityPollStatisticsQueryVariables,
} from "./__generated__/CommunityPollStatisticsQuery";

export const COMMUNITY_POLL_STATISTICS_QUERY = gql`
  query CommunityPollStatisticsQuery($feedbackRequestId: Float!) {
    communityFeedbackStatistics(feedbackRequestId: $feedbackRequestId) {
      feedbackRequestId
      statistics {
        count
        option
        chosenPercentage
      }
    }
  }
`;
