import { sortBy } from "lodash";
import { RootState } from "store";

export const selectRewards =
  (rewardType: string, partnershipId: number | undefined) =>
  (state: RootState) =>
    sortBy(
      state.reward.rewards.filter(
        (r) =>
          r.rewardType === rewardType &&
          ((!partnershipId && !r.partnershipId) ||
            r.partnershipId === partnershipId)
      ),
      "sorting"
    );

export const selectReward = (rewardId: number) => (state: RootState) => {
  return state.reward.rewards.find((reward) => reward.id === rewardId);
};

export const selectRewardTypeFilter = (state: RootState) =>
  state.reward.rewardType;

export const selectRewardActivities = (state: RootState) =>
  state.reward.activities.items;

export const selectRewardActivitiesTotal = (state: RootState) =>
  state.reward.activities.total;

export const selectActivityCounts = (state: RootState) =>
  state.reward.activityCounts;
