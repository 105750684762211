import { gql } from "@apollo/client";
export type {
  PublishReward,
  PublishRewardVariables,
} from "./__generated__/PublishReward";

export const PUBLISH_REWARD = gql`
  mutation PublishReward($id: Float!) {
    publishReward(id: $id) {
      id
      publishedAt
    }
  }
`;
