import { useCallback, useState, useEffect } from "react";
import update from "immutability-helper";
import cloneDeep from "lodash/cloneDeep";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  LessonDetailsQuery_getLesson,
  LessonDetailsQuery_getLesson_cards,
} from "queries/learning/lessonDetails";

import { useAppSelector, useAppDispatch } from "utils/hooks";

import { Box, Text, Popup } from "ui";

import {
  selectCurrLesson,
  selectCurrCardIdx,
  setCurrCardIdx,
  updateLesson,
  selectCurrLessonId,
  createCard,
  getLessonDetails,
  deleteCard,
} from "state/learning";

import { cardsDefaults } from "../dataDefaults";
import { Card } from "./Card";

type SessionCard = LessonDetailsQuery_getLesson_cards & { id: number };

const cleanText = (text: string): string =>
  text
    .replaceAll("<p>", "")
    .replaceAll("</p>", "")
    .replaceAll("<u>", "")
    .replaceAll("</u>", "")
    .replaceAll("<strong>", "")
    .replaceAll("</strong>", "")
    .replaceAll("&nbsp;", "");

const getCardTitle = (card: SessionCard): string | undefined => {
  switch (card.__typename) {
    case "survey":
      if (!card.title) {
        return undefined;
      }
      return cleanText(card.title);
    case "actionitem":
      if (!card.title) {
        return undefined;
      }
      return cleanText(card.title);

    case "richtext":
    case "poll":
    case "quiz":
      // @ts-ignore
      if (!card.elements[0] || !card.elements[0].text) {
        return undefined;
      }
      // @ts-ignore
      return cleanText(card.elements[0].text);

    default:
      return undefined;
  }
};
export const CardsList = () => {
  const dispatch = useAppDispatch();

  const currLesson = useAppSelector(selectCurrLesson);
  const currLessonId = useAppSelector(selectCurrLessonId);
  const currCardIdx = useAppSelector(selectCurrCardIdx);

  const [sessionCards, setSessionCards] = useState<SessionCard[]>(
    currLesson
      ? currLesson.cards.map((card, idx) => ({ ...card, order: idx }))
      : []
  );
  useEffect(() => {
    setSessionCards(
      currLesson
        ? currLesson.cards.map((card, idx) => ({ ...card, order: idx }))
        : []
    );
  }, [currLesson]);

  const saveLesson = (lesson: LessonDetailsQuery_getLesson) => {
    dispatch(updateLesson({ lesson, lessonId: currLessonId! }));
  };

  const selectCard = (idx?: number) => {
    if (idx === currCardIdx) {
      dispatch(setCurrCardIdx(undefined));
    } else {
      dispatch(setCurrCardIdx(idx));
    }
  };

  const handleAddCard = async (type: string) => {
    const content = { ...cardsDefaults()[type] };
    const lessonId = currLessonId!;
    const card = { type, lessonId, content, order: currLesson!.cards.length };
    await dispatch(createCard({ card }));
    await dispatch(getLessonDetails({ lessonId }));
  };

  const saveNewOrder = (droppedProperly: boolean) => {
    selectCard();
    // TODO make this atomic as well

    if (droppedProperly) {
      const updatedLesson: LessonDetailsQuery_getLesson = cloneDeep(
        currLesson!
      );
      updatedLesson!.cards = sessionCards;
      saveLesson(updatedLesson);
    } else {
      setSessionCards(
        currLesson
          ? currLesson.cards.map((card, idx) => ({ ...card, order: idx }))
          : []
      );
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = sessionCards[dragIndex];
      setSessionCards(
        update(sessionCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [sessionCards]
  );

  return (
    <Box py="s">
      <Text ml="s" weight="bold">
        Cards
      </Text>
      {currLesson && (
        <>
          {sessionCards?.map((card: SessionCard, idx: number) => (
            <Card
              key={idx}
              index={idx}
              id={card.id}
              label={/*card.title || */ card.__typename}
              subLabel={getCardTitle(card)}
              cardType={card.__typename}
              selected={idx === currCardIdx}
              onClick={() => selectCard(idx)}
              moveCard={moveCard}
              cardDropped={async (inside, dropResult) => {
                if (dropResult.target === "lesson") {
                  const newCard = {
                    type: card.__typename,
                    order: -1,
                    content: card,
                    lessonId: dropResult.lessonId!,
                  };
                  await dispatch(createCard({ card: newCard }));
                  if (dropResult.dropEffect === "move") {
                    await dispatch(deleteCard({ cardId: card.id }));
                  }
                } else {
                  saveNewOrder(inside);
                }
              }}
            />
          ))}
          <Popup
            trigger={
              <Box mt="s" ml="s" hovered>
                <AddCircleIcon />
              </Box>
            }
            items={[
              "richtext",
              "video",
              "quiz",
              "poll",
              "actionitem",
              "survey",
            ]}
            onChoose={handleAddCard}
          />
        </>
      )}
    </Box>
  );
};
