import { useEffect } from "react";
import { Box, Loader } from "ui";

import { CardsList } from "./cards-list";
import { CardEditor } from "./CardEditor";
import { useAppDispatch, useAppSelector } from "utils";
import {
  deleteLesson,
  getLessonDetails,
  selectRequestStatus,
} from "state/learning";
import { LessonMetaDataEditor } from "./LessonMetaDataEditor";

type LessonEditorProps = {
  selectedLessonId: number;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
};

export const LessonEditor = (props: LessonEditorProps) => {
  const { editMode, selectedLessonId } = props;
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);

  useEffect(() => {
    if (selectedLessonId) {
      dispatch(getLessonDetails({ lessonId: selectedLessonId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLessonId]);

  const onDeleteLesson = async () => {
    await dispatch(deleteLesson({ lessonId: selectedLessonId }));
    props.setEditMode(false);
  };

  if (!selectedLessonId) {
    return <></>;
  }

  if (requestStatus === "getLessonDetails_loading") {
    return (
      <Box mt="l" display="flex" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  return (
    <Box display="flex">
      {editMode ? (
        <LessonMetaDataEditor onDelete={onDeleteLesson} />
      ) : (
        <>
          <Box
            showScroll
            width={300}
            style={{ minHeight: "100vh" }}
            borderRightStyle="solid"
            borderRightColor="grey1"
            borderRightWidth={2}
          >
            <CardsList />
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            showScroll
            mt="s"
          >
            <CardEditor />
          </Box>
        </>
      )}
    </Box>
  );
};
