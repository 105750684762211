import styled from "styled-components";
import { useRef } from "react";
import { useDrop } from "react-dnd";

import { ModulesList_getModules_lessons } from "queries/learning/modulesList";
import { Box, ListItem, theme } from "ui";
import { ItemTypes } from "./cards-list/itemTypes";

type Props = {
  lIdx: number;
  selectedLessonId: number;
  lesson: ModulesList_getModules_lessons;
  deleted: boolean;
  onSelected(): void;
  onEditMetadata(): void;
};

const HoverBox = styled(Box)`
  &:hover {
    background-color: ${theme.colors.mediumGrey};
  }
`;

export const LessonCard = ({
  lesson,
  lIdx,
  deleted,
  selectedLessonId,
  onEditMetadata,
  onSelected,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ hovered }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      hovered: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }),
    drop: () => ({ target: "lesson", lessonId: lesson.id }),
  });

  drop(ref);

  return (
    <HoverBox
      style={{
        color: deleted ? "grey" : undefined,
        backgroundColor: hovered ? theme.colors.green : undefined,
      }}
      ref={ref}
    >
      <Box>
        <ListItem
          label={lesson.title}
          selected={lesson.id === selectedLessonId}
          onSelected={onSelected}
          onEditMetadata={onEditMetadata}
          noBorder={lIdx !== 0}
          subLabel={lesson.milestone ? "milestone" : undefined}
        />
      </Box>
    </HoverBox>
  );
};
