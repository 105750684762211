import { gql } from "@apollo/client";
export type {
  DeleteLesson,
  DeleteLessonVariables,
} from "./__generated__/DeleteLesson";

export const DELETE_LESSON_MUTATION = gql`
  mutation DeleteLesson($lessonId: Float!) {
    deleteLesson(lessonId: $lessonId)
  }
`;
