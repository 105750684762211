import { useState } from "react";
import { authenticate, logout, selectIsLoggedIn } from "state/user";
import { Box, Button, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

export const AuthenticationPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();

  const onAuthenticate = async () => {
    if (!email || !password) return;
    setError("");
    setLoading(true);
    try {
      const res = await dispatch(authenticate({ email, password }));
      if (res.payload.error || res.payload.authenticate.error) {
        setError(res.payload.error || res.payload.authenticate.error);
      }
    } catch (err: any) {
      setError(err.message);
    }
    setLoading(false);
  };

  if (isLoggedIn) {
    return (
      <Box ml="m" mt="l" display="flex" flexDirection="column" width={300}>
        <h1>Authentication</h1>
        <Text mb="l">Yay, you're logged in...wanna log out?</Text>
        <Button label="Log out 👋" onClick={() => dispatch(logout())} />
      </Box>
    );
  }

  return (
    <Box ml="m" mt="l" display="flex" flexDirection="column" width={300}>
      <h1>Authentication</h1>
      <form onSubmit={onAuthenticate}>
        <Box display="flex" justifyContent="space-between" mb="s">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            size={30}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            autoComplete="username"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onAuthenticate();
              }
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            size={30}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            autoComplete="current-password"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onAuthenticate();
              }
            }}
          />
        </Box>
        <Button
          mt="m"
          width={200}
          label="Login"
          onClick={onAuthenticate}
          disabled={loading || !email || !password}
        />
        {error && (
          <Box color="red" mt="m">
            {error}
          </Box>
        )}
      </form>
    </Box>
  );
};
