import { Box, Circle } from "ui";
import { UserModulesQuery } from "queries/user/userModulesQuery";
import { Tooltip } from "@material-ui/core";

type Props = {
  lessons: UserModulesQuery["user"]["userModules"][0]["userLessons"];
  clickable: boolean;
};

type LessonDotProps = {
  lesson: Props["lessons"][0];
  onClick?: () => void;
};

type LessonStatus = LessonDotProps["lesson"]["status"];

function getCircleProps(status: LessonStatus): [string, string] {
  switch (status) {
    case "Completed":
      return ["#88B04B", "completed"]; // Green
    case "CompletedToday":
      return ["olive", "completed today"];
    case "Started":
    case "StartedToday":
      return ["#FF6F61", "started"]; // Coral
    case "Unseen":
      return ["#92A8D1", "unlocked"]; // Light blue
    case "Locked":
      return ["#92A8D1", "locked"]; // Light blue
    default:
      return ["grey", ""];
  }
}

const LessonDot = ({ lesson, onClick }: LessonDotProps) => {
  const [color, description] = getCircleProps(lesson.status);
  const title = `${lesson.title} - ${description}`;
  const full = lesson.status !== "Locked";
  const pointer =
    onClick && (lesson.status === "Locked" || lesson.status === "Unseen");
  return (
    <Tooltip title={title}>
      <Circle
        color={color}
        full={full}
        style={{ marginRight: "2px" }}
        onClick={onClick}
        pointer={pointer}
      />
    </Tooltip>
  );
};

export const UserLessons = (props: Props) => {
  return (
    <Box display="flex">
      {props.lessons.map((l, idx) => (
        <LessonDot key={idx} lesson={l} />
      ))}
    </Box>
  );
};
