import { gql } from "@apollo/client";
export type {
  MediaListQuery,
  MediaListQueryVariables,
} from "./__generated__/MediaListQuery";

export const MEDIA_LIST_QUERY = gql`
  query MediaListQuery($key: String!) {
    listMedia(key: $key) {
      id
      key
      type
      content
      json
      createdAt
      startDate
    }
  }
`;
