import { gql } from "@apollo/client";
export type {
  DeleteCard,
  DeleteCardVariables,
} from "./__generated__/DeleteCard";

export const DELETE_CARD_MUTATION = gql`
  mutation DeleteCard($cardId: Float!) {
    deleteCard(cardId: $cardId)
  }
`;
