import { Box, Text, TextField } from "ui";
import { DividerElement } from "queries/richTextElementFragment";

export type DividerProps = {
  element: DividerElement;
  isEditMode: boolean;
  onChange: (element: DividerElement) => void;
};

export const Divider = (props: DividerProps) => {
  const { element, isEditMode, onChange } = props;

  const handleChangeField = (field: string) => (e: any) => {
    const updatedElement = { ...element, [field]: e.target.value.trim() };
    onChange(updatedElement);
  };

  const editView = (
    <Box display="flex" alignItems="center">
      <Text mr="s">Color: </Text>
      <TextField
        value={element.color}
        variant="outlined"
        size="small"
        onChange={handleChangeField("color")}
      />
    </Box>
  );

  const readView = (
    <Box width="100%">
      <Box
        height={2}
        width="100%"
        backgroundColor={element.color === null ? "grey1" : element.color}
      />
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
