import { gql } from "@apollo/client";
export type {
  UpdateModule,
  UpdateModuleVariables,
} from "./__generated__/UpdateModule";

export const UPDATE_MODULE = gql`
  mutation UpdateModule($module: JSON!, $moduleId: Float!) {
    updateModule(module: $module, moduleId: $moduleId)
  }
`;
