import { useQuery } from "@apollo/client";
import { UsersQuery, USERS_QUERY } from "queries/user/usersQuery";
import { useEffect, useState } from "react";
import { Box, Select, Text } from "ui";
import { apolloClient } from "utils/apolloClient";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CopyField } from "components/CopyField";
import { SearchField } from "components/SearchField";
import { PartnershipFilter } from "components/partnership/PartnershipFilter";
import { useAppSelector } from "utils";
import { selectSelectedPartnershipId } from "state/partnership";

enum sortable {
  "points",
  "createdAt",
}

type OrderOptions = {
  value: keyof typeof sortable;
  label: string;
};

const orderOptions: OrderOptions[] = [
  { value: "points", label: "Points" },
  { value: "createdAt", label: "Registration Date" },
];

type QueryArgs = {
  search?: string;
  orderBy?: string;
  partnershipId?: number;
};

export const UsersPage = () => {
  const [search, setSearch] = useState("");
  const [usersCount, setUsersCount] = useState<UsersQuery["usersCount"]>();
  const selectedPartnershipId = useAppSelector(selectSelectedPartnershipId);
  const [users, setUsers] = useState<UsersQuery["searchUsers"]>([]);
  const [orderBy, setOrderBy] = useState<keyof typeof sortable>("createdAt");
  const { refetch } = useQuery<UsersQuery>(USERS_QUERY, {
    client: apolloClient,
    skip: true,
  });

  useEffect(() => {
    const args: QueryArgs = {};
    if (search.length >= 3) {
      args.search = search;
    } else {
      args.search = "";
    }
    if (orderBy) {
      args.orderBy = orderBy;
    }
    // if (selectedPartnershipId) {
    args.partnershipId = selectedPartnershipId;
    // }
    refetch(args).then((res) => {
      setUsers(res.data.searchUsers);
      setUsersCount(!args.search ? res.data.usersCount : undefined);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, orderBy, selectedPartnershipId]);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex">
        <Box flex={1} display="flex" flexDirection="column" mt="xs">
          <SearchField
            value={search}
            onChange={setSearch}
            placeholder="User name or email"
          />
        </Box>
        <Box ml="l">
          <PartnershipFilter />
        </Box>
        <Box ml="l" width={150}>
          <Select
            label="Order By"
            value={orderBy}
            options={orderOptions}
            onChange={(v: any) => setOrderBy(v)}
          />
        </Box>
      </Box>
      {usersCount ? (
        <Box display="flex">
          Users count:{" "}
          {selectedPartnershipId
            ? usersCount.partnerships.filter(
                (p) => p.partnershipId === selectedPartnershipId
              )[0]?.count ?? 0
            : usersCount.all}
        </Box>
      ) : (
        <Box>&nbsp;</Box>
      )}
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text weight="bold">User 💃</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Points 🥑</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Email 📧</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Lessons 🎓</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Streak ✨</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    <Link to={`/user/${user.id}`} style={{ color: "#393939" }}>
                      <Text>{user.name || user.email}</Text>
                    </Link>
                    <CopyField value={user.externalId} />
                  </TableCell>
                  <TableCell>
                    <Text>{user.points}</Text>
                  </TableCell>
                  <TableCell>
                    <a
                      href={`mailto:${user.email}`}
                      style={{ color: "#393939" }}
                    >
                      <Text>{user.email}</Text>
                    </a>
                  </TableCell>
                  <TableCell>
                    <Text>{user.lessonsCount}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>
                      {user.streakDays} days ({user.streaksCount} streaks)
                    </Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
