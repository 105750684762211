// this is work in progress
import { useQuery } from "@apollo/client";
import {
  LIST_AMITY_COMMUNITIES_QUERY,
  ListAmityCommunities,
} from "queries/community/listAmityCommunitiesQuery";
import {
  LIST_COMMUNITIES_QUERY,
  ListCommunities,
} from "queries/community/listCommunitiesQuery";
import { Box } from "ui";
import { apolloClient } from "utils/apolloClient";

export const CommunityPage = () => {
  const amityCommunitiesQuery = useQuery<ListAmityCommunities>(
    LIST_AMITY_COMMUNITIES_QUERY,
    { client: apolloClient }
  );
  const communitiesQuery = useQuery<ListCommunities>(LIST_COMMUNITIES_QUERY, {
    client: apolloClient,
  });

  return (
    <Box ml="m" mt="l" display="flex" flexDirection="column" width={300}>
      <h1>Communities</h1>
      <h2>Amity Communities</h2>
      {amityCommunitiesQuery.data?.listAmityCommunities.map((community) => (
        <div key={community.amityCommunityId}>{community.title}</div>
      ))}
      <h2>Communities</h2>
      {communitiesQuery.data?.communities.map((community) => (
        <div key={community.id}>{community.title}</div>
      ))}
    </Box>
  );
};
