import {
  LIST_ANNOUNCEMENTS,
  ListAnnouncements,
} from "queries/listAnnouncements";
import {
  UPSERT_ANNOUNCEMENT,
  UpsertAnnouncement,
  UpsertAnnouncementVariables,
} from "queries/upsertAnnouncement";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";

export const listAnnouncements = createQueryThunk<ListAnnouncements>(
  "announcements/list",
  LIST_ANNOUNCEMENTS
);

export const upsertAnnouncement = createMutationThunk<
  UpsertAnnouncement,
  UpsertAnnouncementVariables
>("/announcements/upsert", UPSERT_ANNOUNCEMENT);
