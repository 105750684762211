import { gql } from "@apollo/client";
export type {
  UpsertPathQuizMutation,
  UpsertPathQuizMutationVariables,
} from "./__generated__/UpsertPathQuizMutation";

export const UPSERT_PATH_QUIZ_MUTATION = gql`
  mutation UpsertPathQuizMutation($quiz: CreatePathQuizInput!) {
    upsertPathQuiz(quiz: $quiz) {
      id
      title
      type
      answers {
        id
        title
        moduleIds
      }
    }
  }
`;
