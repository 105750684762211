import { gql } from "@apollo/client";
export type { ListPathQuizzesQuery } from "./__generated__/ListPathQuizzesQuery";

export const LIST_PATH_QUIZZES_QUERY = gql`
  query ListPathQuizzesQuery {
    listPathQuizzes {
      id
      title
      type
      answers {
        id
        title
        moduleIds
      }
    }
  }
`;
