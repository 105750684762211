import { useState } from "react";

import { Box } from "ui";
import { ModulesList_getModules } from "queries/learning/modulesList";
import { ModuleListItem } from "./ModuleListItem";

type ModulesListProps = {
  modulesList: ModulesList_getModules[];
  moduleDrafts?: ModulesList_getModules[];
  selectedLessonId: number;
  selectedModuleId: number;
  onLessonSelected: (id: number, editMode: boolean) => void;
  onModuleSelected: (id: number) => void;
};

export const ModulesList = (props: ModulesListProps) => {
  const [search, setSearch] = useState("");
  const {
    moduleDrafts,
    modulesList,
    onLessonSelected,
    selectedLessonId,
    selectedModuleId,
    onModuleSelected,
  } = props;

  const selectLesson = (id: number, editMode = false) => {
    onModuleSelected?.(0);
    if (selectedLessonId === id && !editMode) {
      onLessonSelected(0, false);
    } else {
      onLessonSelected(id, editMode);
    }
  };

  const selectModule = (id: number) => {
    onLessonSelected(0, false);
    if (selectedModuleId === id) {
      onModuleSelected(0);
    } else {
      onModuleSelected(id);
    }
  };

  return (
    <Box pb="s">
      <Box
        borderBottom="1px solid grey"
        display="flex"
        height="32px"
        alignItems="center"
      >
        <input
          style={{
            padding: "2px 6px",
            border: "none",
            width: "100%",
            outline: "none",
          }}
          value={search}
          placeholder="🔍 search..."
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <Box
            onClick={() => setSearch("")}
            style={{
              cursor: "pointer",
              fontSize: "16px",
              paddingRight: "4px",
              color: "#666",
            }}
          >
            ⓧ
          </Box>
        )}
      </Box>
      <Box showScroll style={{ minHeight: "100vh" }}>
        {modulesList
          .filter(
            (module) => !search || new RegExp(search, "i").test(module.title)
          )
          .map((module: ModulesList_getModules) => (
            <ModuleListItem
              module={module}
              key={`module_${module.id}`}
              selectedLessonId={selectedLessonId}
              selectedModuleId={selectedModuleId}
              draft={moduleDrafts?.find((d) => d.sourceModuleId === module.id)}
              onModuleSelected={selectModule}
              onLessonSelected={selectLesson}
            />
          ))}
      </Box>
    </Box>
  );
};
