import { Box } from "ui";

import { ElementsEditor } from "../elements";

export const RichTextCard = () => {
  return (
    <Box>
      <ElementsEditor blocklist={["inlineVideo"]} />
    </Box>
  );
};
