import { createSlice } from "@reduxjs/toolkit";
import { omit, orderBy } from "lodash";
import { UserModulesQuery } from "queries/user/userModulesQuery";
import { RootState } from "store";
import {
  authenticate,
  giftAvocados,
  listUserModules,
  toggleUserAdmin,
} from "./thunks";

type ModuleItem = UserModulesQuery["user"]["userModules"][number];
type UserData = Omit<UserModulesQuery["user"], "userModules">;

interface UserState {
  // data related to currently viewed app user
  userModules: ModuleItem[];
  userData?: UserData;
  isLoading: boolean;
  // data related to admin login
  adminToken?: string;
  username?: string;
}

const initialState: UserState = {
  userModules: [],
  userData: undefined,
  isLoading: false,
  adminToken: localStorage.getItem("user.adminToken") || "",
  username: localStorage.getItem("user.username") || "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      state.adminToken = undefined;
      state.username = undefined;
      localStorage.removeItem("user.adminToken");
      localStorage.removeItem("user.username");
    },
  },
  extraReducers(builder) {
    builder.addCase(listUserModules.pending, (state, _action) => {
      state.isLoading = true;
    });
    builder.addCase(listUserModules.fulfilled, (state, action) => {
      state.userModules = action.payload.user.userModules;
      state.userData = omit(action.payload.user, "userModules");
      state.isLoading = false;
    });
    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.adminToken = action.payload.authenticate.token || undefined;
      state.username = action.payload.authenticate.username || undefined;
      localStorage.setItem(
        "user.adminToken",
        action.payload.authenticate.token || ""
      );
      localStorage.setItem(
        "user.username",
        action.payload.authenticate.username || ""
      );
    });
    builder.addCase(giftAvocados.fulfilled, (state, action) => {
      if (
        state.userData &&
        state.userData.id === action.payload.giftAvocados.id
      ) {
        state.userData.points = action.payload.giftAvocados.points;
      }
    });
    builder.addCase(toggleUserAdmin.fulfilled, (state, action) => {
      if (
        state.userData &&
        state.userData.id === action.payload.toggleUserAdmin.id
      ) {
        state.userData.role = action.payload.toggleUserAdmin.role;
      }
    });
  },
});

export const selectEnabledModules = (state: RootState) => {
  return orderBy(
    state.user.userModules.filter((module) => true), //module.inPath), TODO use inPath instead
    "sorting"
  );
};
export const selectDisabledModules = (state: RootState) => {
  return orderBy(
    state.user.userModules.filter((module) => false), //!module.enabled), // TODO use inPath instead
    "originalSorting"
  );
};
export const selectUserData = (state: RootState) => state.user.userData;
export const selectUserLoading = (state: RootState) => state.user.isLoading;
export const selectIsLoggedIn = (state: RootState) => !!state.user.adminToken;

export const { logout } = userSlice.actions;
export default userSlice.reducer;
