import { gql } from "@apollo/client";
export type { ListAnnouncements } from "./__generated__/ListAnnouncements";

export const LIST_ANNOUNCEMENTS = gql`
  query ListAnnouncements {
    announcements {
      id
      title
      text
      ctaLabel
      ctaLink
      ctaArgs
      startDate
      endDate
    }
  }
`;
