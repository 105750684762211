import { gql } from "@apollo/client";

export const REWARD_ACTIVITY_FRAGMENT = gql`
  fragment RewardActivityFragment on RewardActivity {
    id
    status
    name
    email
    data
    rewardId
    rewardType
    brand
		createdAt
  }
`;
