import { gql } from "@apollo/client";
export type {
  PublishDraftModule,
  PublishDraftModuleVariables,
} from "./__generated__/PublishDraftModule";

export const PUBLISH_DRAFT_MODULE = gql`
  mutation PublishDraftModule($moduleId: Float!) {
    publishDraftModule(moduleId: $moduleId)
  }
`;
