import { gql } from "@apollo/client";
export type {
  ReorderRewards,
  ReorderRewardsVariables,
} from "./__generated__/ReorderRewards";

export const REORDER_REWARDS = gql`
  mutation ReorderRewards($ids: [Float!]!) {
    reorderRewards(ids: $ids)
  }
`;
