import { useState } from "react";
import { selectCurrCard, updateCard } from "state/learning";
import { Box } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import { Element } from "components/content-editor/elements/Element";
import {
  RichTextElementFragment,
  RichTextElementFragment_paragraph,
} from "queries/__generated__/RichTextElementFragment";
import { ElementsEditor } from "../elements";
import { ActionItemCard as ActionItemCardType } from "queries/learning/lessonDetails";
import { cloneDeep } from "lodash";

const emptyTitle: RichTextElementFragment_paragraph = {
  __typename: "paragraph",
  text: "type title here",
  position: null,
};

export const ActionItemCard = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard) as ActionItemCardType;
  const [editMode, setEditMode] = useState(false);

  const element = emptyTitle;
  element.text = currCard!.title;

  const handleUpdateTitle = async (element: RichTextElementFragment) => {
    const titleElement = element as RichTextElementFragment_paragraph;
    const content = cloneDeep(currCard!) as ActionItemCardType;
    content.title = titleElement.text;
    const card = { content, id: currCard!.id };
    dispatch(updateCard({ card }));
    setEditMode(false);
  };

  return (
    <Box>
      <Box mt="m">
        <Element
          index={0}
          element={element}
          onUpdateElement={(element) => handleUpdateTitle(element)}
          isEditMode={editMode}
          isFirstElement={true}
          isLastElement={false}
          onToggleEditMode={(isEditMode) => setEditMode(isEditMode)}
          hidePosition={true}
          title="Action Item Title"
        />
      </Box>
      <Box mt="s">
        Task Duration{" "}
        <input
          value={currCard.taskDuration || undefined}
          size={3}
          type="number"
          onChange={(e) => {
            const taskDuration = parseInt(e.target.value, 10);
            const card = { id: currCard!.id, taskDuration };
            dispatch(updateCard({ card }));
          }}
        />
      </Box>
      <Box mt="m" borderBottom="1px solid black" />
      <Box mt="m">
        <ElementsEditor />
      </Box>
    </Box>
  );
};
