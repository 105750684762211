import { useEffect, useState } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Box, Button, Select, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import { ImageUpload } from "components/ImageUpload";
import { InputField } from "components/InputField";
import { PartnershipFragment } from "queries/partnership/partnershipFragment";
import {
  connectPartnershipModule,
  selectPartnershipLoading,
} from "state/partnership";
import {
  createLesson,
  loadModulesList,
  selectAllModulesList,
  selectModule,
  updateModule,
} from "state/learning";
import { RootState } from "store";
import { PartnershipInput } from "../../../__generated__/globalTypes";

type Props = {
  partnership: RootState["partnership"]["partnerships"][number];
  save: (data: PartnershipInput) => Promise<void>;
  archive: () => void;
};

type ModuleInput = {
  goalTitle?: string;
  goals?: string[];
};

export const PartnershipEdit = ({ partnership, ...props }: Props) => {
  const hasModule = partnership.modules.length;
  const [showArchive, setShowArchive] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(0);
  const [selectedModule, setSelectedModule] = useState<number>();
  const [success, setSuccess] = useState(false);
  const [localPartnership, setLocalPartnership] = useState<PartnershipFragment>(
    { ...partnership }
  );
  const [showUsers, setShowUsers] = useState(false);
  const modulesList = useAppSelector(selectAllModulesList);
  const module = useAppSelector(selectModule(localPartnership.modules[0]?.id));
  const [goalTitle, setGoalTitle] = useState("");
  const [goals, setGoals] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { connectModuleLoading } = useAppSelector(selectPartnershipLoading);

  const saveModule = (update: ModuleInput) => {
    if (!module) return;
    dispatch(updateModule({ module: update, moduleId: module.id }));
  };

  const updateBenefit =
    (index: number, key: keyof PartnershipFragment["benefits"][number]) =>
    (value: string) => {
      const benefits = [...localPartnership.benefits] || [];
      benefits[index] = {
        ...{ imageUrl: "", title: "", subcopy: "" },
        ...(benefits[index] || {}),
        [key]: value,
      };
      setLocalPartnership({ ...localPartnership, benefits });
    };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => setSuccess(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    setLocalPartnership({ ...partnership });
  }, [partnership]);

  useEffect(() => {
    setGoalTitle(module?.goalTitle || "");
    setGoals(module?.goals || []);
  }, [module]);

  const save = async () => {
    await props.save(localPartnership);
    setSuccess(true);
  };

  const addLesson = async () => {
    if (!module) return;
    await dispatch(createLesson({ moduleId: module.id }));
    await dispatch(loadModulesList());
  };

  return (
    <>
      {!hasModule && (
        <Box m="l" border="1px solid grey" p="m">
          <Box mb="m">
            ⚠️ please choose a module as a template for this challenge 🪄✨
          </Box>
          {connectModuleLoading && (
            <Box mb="m">
              working hard on copying module content into this challenge...
            </Box>
          )}
          <Box display="flex">
            <Select
              options={modulesList.map((m) => ({
                label: m.title + (m.partnershipId ? ` (challenge)` : ""),
                value: `${m.id}`,
              }))}
              label="Select a module"
              disabled={connectModuleLoading}
              onChange={(v) => {
                if (v) {
                  setSelectedModule(parseInt(v, 10));
                }
              }}
            />
            <Box pt="m">
              <Button
                label="ok"
                disabled={!selectedModule || connectModuleLoading}
                onClick={async () => {
                  if (!selectedModule) return;
                  await save();
                  const result = await dispatch(
                    connectPartnershipModule({
                      partnershipId: partnership.id,
                      moduleId: selectedModule,
                    })
                  );
                  if (result.payload.connectModule) {
                    setLocalPartnership(result.payload.connectModule);
                    dispatch(loadModulesList());
                  }
                }}
                px="m"
                py="s"
                ml="m"
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        py="m"
        px="l"
        style={{ display: "flex", gap: 10, flexDirection: "column" }}
      >
        <Text size="big" mb="m">
          Edit challenge
        </Text>
        <Text size="large">General</Text>
        <InputField
          label="Title"
          value={localPartnership.title}
          onChange={(v) =>
            setLocalPartnership({ ...localPartnership, title: v })
          }
        />
        <InputField
          label="Internal name"
          value={localPartnership.internalName}
          onChange={(v) =>
            setLocalPartnership({
              ...localPartnership,
              internalName: v,
            })
          }
        />
        <InputField
          label="Code"
          value={localPartnership.code}
          onChange={(v) =>
            setLocalPartnership({
              ...localPartnership,
              code: v,
            })
          }
        />
        <InputField
          label="Sponsor Copy"
          value={localPartnership.sponsorCopy!}
          onChange={(v) =>
            setLocalPartnership({
              ...localPartnership,
              sponsorCopy: v,
            })
          }
        />
        <InputField
          label="Sponsor URL"
          value={localPartnership.sponsorUrl!}
          onChange={(v) =>
            setLocalPartnership({
              ...localPartnership,
              sponsorUrl: v,
            })
          }
        />
        <InputField
          label="Welcome text"
          multiline
          value={localPartnership.welcomeText!}
          onChange={(v) =>
            setLocalPartnership({
              ...localPartnership,
              welcomeText: v,
            })
          }
        />
        <Box mb="s" display="flex">
          <Text width={150}>Starts&nbsp;at:</Text>
          <Box>
            <DatePicker
              selected={dayjs(localPartnership.startDate).toDate()}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                if (date) {
                  setLocalPartnership({
                    ...localPartnership,
                    startDate: date.toISOString(),
                  });
                }
              }}
            />
          </Box>
        </Box>
        <Text size="large">Goals</Text>
        {module ? (
          <Box width="200px">
            <InputField
              label="Title"
              value={goalTitle}
              onSave={() => saveModule({ goalTitle })}
              onChange={setGoalTitle}
            />
            <Text fontWeight="bold" mt="s" mb="xs">
              Goal Sentences
            </Text>
            {[0, 1, 2].map((i) => (
              <Box mb="s" key={i}>
                <input
                  value={goals[i] as string}
                  onChange={(e) =>
                    setGoals(
                      goals.map((g, idx) => (idx === i ? e.target.value : g))
                    )
                  }
                  onBlur={() => saveModule({ goals })}
                  size={50}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Text>please choose a module first</Text>
        )}
        <Text size="large">Communities</Text>
        {localPartnership.communities && (
          <Box display="flex" flexDirection="column" style={{ gap: 10 }}>
            {localPartnership.communities.map((c) => (
              <Box
                key={c.id}
                display="flex"
                flexDirection="row"
                gridGap="m"
                justifyContent="space-between"
                width={400}
              >
                <Text>{c.title}</Text>
                <input size={30} value={c.amityCommunityId} readOnly />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box mx="m">
        <Box display="flex" flexDirection="row" style={{ gap: 10 }}>
          <Box
            style={{ maxWidth: 200 }}
            border="1px solid grey"
            borderRadius="2px"
            p="s"
          >
            <ImageUpload
              label="Logo"
              src={localPartnership.logoImageUrl}
              width={200}
              onUpdateSrc={(src) => {
                setLocalPartnership({
                  ...localPartnership,
                  logoImageUrl: src,
                });
              }}
            />
          </Box>
          <Box
            style={{ maxWidth: 100 }}
            border="1px solid grey"
            borderRadius="2px"
            p="s"
          >
            <ImageUpload
              label="Small Logo"
              src={localPartnership.logoSmallUrl}
              width={200}
              onUpdateSrc={(src) => {
                setLocalPartnership({
                  ...localPartnership,
                  logoSmallUrl: src,
                });
              }}
            />
          </Box>
          <Box
            style={{ maxWidth: 200 }}
            border="1px solid grey"
            borderRadius="2px"
            p="s"
          >
            <ImageUpload
              label="Header image"
              src={module?.imageLargeUrl}
              width={200}
              onUpdateSrc={(src) => {
                if (!module) return;
                dispatch(
                  updateModule({
                    module: { imageLargeUrl: src },
                    moduleId: module?.id,
                  })
                );
              }}
            />
          </Box>
        </Box>
        <Box mt="l">
          <Text size="large">Onboarding screens</Text>
          <Box display="flex" style={{ gap: 8 }} mt="m">
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <Button
                  key={i}
                  label={`Screen ${i + 1}`}
                  onClick={() => setSelectedBenefit(i)}
                  backgroundColor={i === selectedBenefit ? "black" : "grey1"}
                />
              ))}
          </Box>
          <Box border="1px solid grey" mt="s" p="s" borderRadius="2px" mb="xl">
            <Box mb="m">
              <Box>
                <ImageUpload
                  label="upload image"
                  src={localPartnership.benefits[selectedBenefit]?.imageUrl}
                  width={200}
                  onUpdateSrc={updateBenefit(selectedBenefit, "imageUrl")}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
              <InputField
                label="Title"
                value={localPartnership.benefits[selectedBenefit]?.title || ""}
                onChange={updateBenefit(selectedBenefit, "title")}
              />
              <InputField
                label="Sub Copy"
                value={
                  localPartnership.benefits[selectedBenefit]?.subcopy || ""
                }
                onChange={updateBenefit(selectedBenefit, "subcopy")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        pr="m"
        ml="m"
        pb="xl"
        gridGap="m"
      >
        {showArchive ? (
          <Box display="flex">
            <Box display="flex" flexDirection="column" gridGap="xs">
              <Text mb="s">Do you really want to archive this challenge?</Text>
              <Button
                mb="s"
                backgroundColor="red"
                label="Archive 🔥"
                onClick={props.archive}
              />
              <Button
                backgroundColor="darkgreen"
                label="cancel"
                onClick={() => setShowArchive(false)}
              />
            </Box>
          </Box>
        ) : (
          <Button
            backgroundColor="red"
            label="Archive 🗑️"
            onClick={() => setShowArchive(true)}
          />
        )}
        {success && <Box color="darkgreen">Yay! Saved! 🥳</Box>}
        <Button height={24} label="Save" onClick={save} />
      </Box>
      {partnership?.participants && !showUsers && (
        <Box m="m">
          <Button
            label={`Show ${partnership.participants.length} participants`}
            onClick={() => setShowUsers(true)}
          />
        </Box>
      )}
      {partnership?.participants && showUsers && (
        <Box m="m">
          <Button
            label={`Hide ${partnership.participants.length} participants`}
            onClick={() => setShowUsers(false)}
          />
        </Box>
      )}
      {partnership?.participants && showUsers && (
        <Box m="m">
          <Text size="large">Participants</Text>
          <Box mt="m" p="m" border="1px solid grey">
            {partnership.participants.map((p) => (
              <Box key={p.id} display="flex">
                <Text weight="bold">{p.name}</Text>&nbsp;({p.email})
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Box display="flex" m="m">
        <Button label="+ Add lesson" onClick={addLesson} />
      </Box>
    </>
  );
};
