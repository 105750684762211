import styled, { css } from "styled-components";
import {
  color,
  shadow,
  flexbox,
  layout,
  space,
  typography,
  border,
  position,
} from "styled-system";

import { Props } from "./types";

import { Box, Text } from "ui";

const ButtonContainer = styled(Box)`
  background-color: ${({ disabled }) => (disabled ? "#afabab" : "#000000")};
  border-radius: 78px;

  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
          &:hover {
            background-color: #464343;
          }

          &:active {
            background-color: #afabab;
          }
        `
      : ""}

  ${flexbox};
  ${color};
  ${layout};
  ${space};
  ${typography};
  ${border}
  ${position}
  ${shadow}
`;

export const Button = (props: Props) => {
  const { label, onClick, disabled = false, textProps, ...rest } = props;
  return (
    <ButtonContainer
      display="flex"
      justifyContent="center"
      alignItems="center"
      py="xs"
      px="m"
      disabled={disabled}
      {...rest}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      <Text
        color={(props.color as string) || "white"}
        size="small"
        {...textProps}
      >
        {label}
      </Text>
    </ButtonContainer>
  );
};
