import { gql } from "@apollo/client";
export type { CardUpdate } from "../../../__generated__/globalTypes";
export type {
  UpdateCard,
  UpdateCardVariables,
} from "./__generated__/UpdateCard";

export const UPDATE_CARD_MUTATION = gql`
  mutation UpdateCard($card: CardUpdate!) {
    updateCard(card: $card)
  }
`;
