import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";

type Props = {
  value: string;
};

const Input = styled.input`
  color: grey;
  border: none;
  outline: none;
  padding: 0;
  cursor: copy;
  background: none;
  width: 100%;
`;

const Container = styled.div`
  width: 285px;
`;

async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
}

export const CopyField = (props: Props) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    copyTextToClipboard(props.value);
    setCopied(true);
  };

  return (
    <Tooltip title={copied ? "Copied!" : "Click to copy!"}>
      <Container onClick={copy} onMouseOut={() => setCopied(false)}>
        <Input
          onFocus={(e) => e.target.select()}
          defaultValue={props.value}
        />
      </Container>
    </Tooltip>
  );
};
