import { gql } from "@apollo/client";
export type {
  UserModulesQuery,
  UserModulesQueryVariables,
} from "./__generated__/UserModulesQuery";

export const USER_MODULES_QUERY = gql`
  query UserModulesQuery($userId: Float!) {
    user(userId: $userId) {
      id
      name
      email
      points
      imageUrl
      role
      lessonsCount
      streakDays
      streaksCount
      userModules {
        moduleId
        title
        sorting
        movable
        userLessons {
          lessonId
          userId
          title
          status
          completedAt
          startedAt
        }
      }
    }
  }
`;
