import { useQuery } from "@apollo/client";
import { Link } from "@mui/material";
import { PartnershipFilter } from "components/partnership/PartnershipFilter";
import {
  ReportQuery,
  REPORT_QUERY,
  ReportQueryVariables,
} from "queries/reportQuery";
import { useEffect, useState } from "react";
import { selectSelectedPartnershipId } from "state/partnership";
import { Box, Card, Select, Text } from "ui";
import { useAppSelector } from "utils";
import { apolloClient } from "utils/apolloClient";

type Unpacked<T> = T extends (infer U)[] ? U : T;

export const Reports = () => {
  const partnershipId = useAppSelector(selectSelectedPartnershipId);
  const { loading, error, data, refetch } = useQuery<
    ReportQuery,
    ReportQueryVariables
  >(REPORT_QUERY, {
    client: apolloClient,
  });

  useEffect(() => {
    refetch({
      partnershipId: partnershipId ? Number(partnershipId) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId]);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box m="l">
        <PartnershipFilter />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {error && <Text>Error: {error.message}</Text>}
        {loading && <Text>Loading...</Text>}
        {data &&
          data.listReports.map((report) => (
            <ReportCard report={report} partnershipId={partnershipId} />
          ))}
      </Box>
    </Box>
  );
};

type ReportOptionProps = {
  report: Unpacked<ReportQuery["listReports"]>;
  partnershipId?: number;
};

const ReportCard = ({ report, partnershipId }: ReportOptionProps) => {
  const [value, setValue] = useState("");
  return (
    <Card
      width={300}
      height={200}
      m={20}
      p={20}
      display="flex"
      flexDirection="column"
    >
      <Text fontWeight="bold" mb="l">
        {report.title}
      </Text>
      <Text mb="l">{report.description}</Text>
      {!!report.options.length && (
        <Box mb="l" width="100%" display="flex" flexDirection="column">
          <Select
            value={value}
            options={report.options.map((r) => ({
              value: r.key,
              label: r.title,
            }))}
            onChange={setValue}
          />
        </Box>
      )}
      {(!report.options.length || value) && (
        <Link
          href={`${process.env.REACT_APP_API_URL?.replace(
            "/graphql",
            ""
          )}/api/report/${report.key}/${value}?token=${localStorage.getItem(
            "user.adminToken"
          )}&partnershipId=${partnershipId ?? ""}`}
        >
          Download CSV
        </Link>
      )}
    </Card>
  );
};
