import { gql } from "@apollo/client";
import { REWARDS_FRAGMENT } from "./rewardsFragment";
export type { GetReward, GetRewardVariables } from "./__generated__/GetReward";

export const GET_REWARD = gql`
  ${REWARDS_FRAGMENT}
  query GetReward($id: Float!) {
    reward(id: $id) {
      ...RewardsFragment
    }
  }
`;
