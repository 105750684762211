import { UserModules } from "components/UserModules";
import { useParams } from "react-router-dom";
import { Box } from "ui";

export const UserModulesPage = () => {
  const { userId } = useParams<{ userId: string }>();
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <UserModules userId={parseInt(userId)} />
    </Box>
  );
};
