import { FC, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";

import { Box, Button, Select } from "ui";

type AddElementProps = {
  onAddElement: (type: string) => void;
  blocklist?: string[];
};

export const AddElement: FC<AddElementProps> = ({
  onAddElement,
  blocklist = [],
}) => {
  const [addMode, setAddMode] = useState(false);
  const [addedElement, setAddedElement] = useState("paragraph");

  const handleAddElement = () => {
    onAddElement(addedElement);
    setAddMode(false);
  };
  return (
    <Box
      mt="m"
      height={50}
      width="100%"
      border="1px dashed black"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {!addMode && (
        <Box hovered onClick={() => setAddMode(true)}>
          <AddCircleIcon />
        </Box>
      )}
      {addMode && (
        <>
          <Box position="absolute" top={0} right={0}>
            <Box hovered onClick={() => setAddMode(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box display="flex">
            <Select
              value={addedElement}
              options={[
                "paragraph",
                "heading1",
                "heading2",
                "callout",
                "quote",
                "list",
                "image",
                { value: "inlineVideo", label: "video" },
              ].filter((e: any) => !blocklist.includes(e.value || e))}
              onChange={setAddedElement}
            />
            <Button ml="l" label="Add" onClick={handleAddElement} />
          </Box>
        </>
      )}
    </Box>
  );
};
