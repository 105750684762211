import { startCase } from "lodash";
import { Box, Button } from "ui";

type Props = {
  value: string;
  options: { value: string; label?: string; count?: number }[];
  onChange: (value: string) => any;
};

export const PillSelector = ({
  value: selectedValue,
  options,
  onChange,
}: Props) => {
  return (
    <Box display="flex" mb="xl" height="32px">
      {options.map(({ value, label, count }) => (
        <Button
          onClick={() => {
            onChange(value);
          }}
          label={(label || startCase(value)) + (count ? ` (${count})` : "")}
          backgroundColor={value === selectedValue ? "#473FD0" : "#ECECEC"}
          color={value === selectedValue ? "white" : "black"}
          mr="s"
          key={value}
        />
      ))}
    </Box>
  );
};
