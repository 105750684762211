import { gql } from "@apollo/client";
export type {
  RichTextElementFragment as CardElement,
  RichTextElementFragment_heading1 as Heading1Element,
  RichTextElementFragment_heading2 as Heading2Element,
  RichTextElementFragment_paragraph as ParagraphElement,
  RichTextElementFragment_quote as QuoteElement,
  RichTextElementFragment_image as ImageElement,
  RichTextElementFragment_list as ListElement,
  RichTextElementFragment_divider as DividerElement,
  RichTextElementFragment_callout as CalloutElement,
  RichTextElementFragment_inlineVideo as InlineVideoElement,
} from "./__generated__/RichTextElementFragment";

export const RICHTEXT_ELEMENT_FRAGMENT = gql`
  fragment RichTextElementFragment on RichTextElement {
    __typename
    ... on heading1 {
      text
      position
    }
    ... on heading2 {
      text
      position
    }
    ... on paragraph {
      text
      position
    }
    ... on list {
      variant
      bulletChar
      list
    }
    ... on divider {
      color
    }
    ... on callout {
      text
      position
      bgColor
    }
    ... on image {
      svg
      url
      position
      text
      textPosition
    }
    ... on quote {
      text
    }
    ... on inlineVideo {
      uri
    }
  }
`;
