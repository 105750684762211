import {
  DeleteReward,
  DeleteRewardVariables,
  DELETE_REWARD,
} from "queries/reward/deleteReward";
import {
  GetReward,
  GetRewardVariables,
  GET_REWARD,
} from "queries/reward/getReward";
import {
  ListRewardActivities,
  ListRewardActivitiesVariables,
  LIST_REWARD_ACTIVITIES,
} from "queries/reward/listActivities";
import { ListRewards, LIST_REWARDS } from "queries/reward/listRewards";
import {
  PublishReward,
  PublishRewardVariables,
  PUBLISH_REWARD,
} from "queries/reward/publishReward";
import {
  ReorderRewards,
  ReorderRewardsVariables,
  REORDER_REWARDS,
} from "queries/reward/reorderRewards";
import {
  UnpublishReward,
  UnpublishRewardVariables,
  UNPUBLISH_REWARD,
} from "queries/reward/unpublishReward";
import {
  UpdateRewardActivity,
  UpdateRewardActivityVariables,
  UPDATE_REWARD_ACTIVITY,
} from "queries/reward/updateRewardActivity";
import {
  UpsertReward,
  UpsertRewardVariables,
  UPSERT_REWARD,
} from "queries/reward/upsertReward";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";

export const listRewards = createQueryThunk<ListRewards>(
  "reward/listRewards",
  LIST_REWARDS
);

export const getReward = createQueryThunk<GetReward, GetRewardVariables>(
  "reward/getReward",
  GET_REWARD
);

export const upsertReward = createMutationThunk<
  UpsertReward,
  UpsertRewardVariables
>("reward/upsertReward", UPSERT_REWARD);

export const publishReward = createMutationThunk<
  PublishReward,
  PublishRewardVariables
>("reward/publishReward", PUBLISH_REWARD);

export const unpublishReward = createMutationThunk<
  UnpublishReward,
  UnpublishRewardVariables
>("reward/unpublishReward", UNPUBLISH_REWARD);

export const deleteReward = createMutationThunk<
  DeleteReward,
  DeleteRewardVariables
>("reward/deleteReward", DELETE_REWARD);

export const listRewardActivities = createQueryThunk<
  ListRewardActivities,
  ListRewardActivitiesVariables
>("reward/listRewardActivities", LIST_REWARD_ACTIVITIES);

export const reorderRewards = createMutationThunk<
  ReorderRewards,
  ReorderRewardsVariables
>("reward/reorderRewards", REORDER_REWARDS);

export const updateRewardActivity = createMutationThunk<
  UpdateRewardActivity,
  UpdateRewardActivityVariables
>("reward/updateRewardActivity", UPDATE_REWARD_ACTIVITY);
