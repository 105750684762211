import { Box, Text, Select } from "ui";
import { ParagraphElement } from "queries/richTextElementFragment";

import { RichTextEditor, RichTextViewer } from "components/rich-text";

export type ParagraphProps = {
  element: ParagraphElement;
  isEditMode: boolean;
  hidePosition: boolean;
  onChange: (element: ParagraphElement) => void;
};

export const Paragraph = (props: ParagraphProps) => {
  const { element, isEditMode, onChange } = props;

  const handleTextChange = (text: string) => {
    const updatedElement = { ...element, text: text.trim() };
    onChange(updatedElement);
  };

  const handleChangeField = (field: string) => (newValue: string) => {
    const updatedElement = { ...element, [field]: newValue.trim() };
    onChange(updatedElement);
  };

  const editView = (
    <Box>
      <RichTextEditor value={element.text} onChange={handleTextChange} />
      {!props.hidePosition && (
        <Box mt="s" display="flex" alignItems="center">
          <Text mr="s">Position:</Text>
          <Select
            value={element.position!}
            onChange={handleChangeField("position")}
            options={[
              { value: "left", label: "left" },
              { value: "center", label: "center" },
            ]}
          />
        </Box>
      )}
    </Box>
  );

  const readView = (
    <Box justifyContent={element.position === "left" ? "flex-start" : "center"}>
      <RichTextViewer html={element.text!} position={element.position} />
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
