import styled from "styled-components";
import {
  TextField as MaterialTextField,
  TextFieldProps,
} from "@material-ui/core";

const StyledTextField = styled(MaterialTextField)`
  width: -webkit-fill-available;
`;

export const TextField = (props: TextFieldProps) => {
  return <StyledTextField {...props} />;
};
