import {
  CreateCard,
  CreateCardVariables,
  CREATE_CARD_MUTATION,
} from "queries/learning/createCardMutation";
import {
  CreateLessonMutation,
  CreateLessonMutationVariables,
  CREATE_LESSON_MUTATION,
} from "queries/learning/createLessonMutation";
import {
  DeleteCard,
  DeleteCardVariables,
  DELETE_CARD_MUTATION,
} from "queries/learning/deleteCardMutation";
import {
  CopyModuleAsDraft,
  CopyModuleAsDraftVariables,
  COPY_MODULE_AS_DRAFT,
} from "queries/learning/copyModuleAsDraft";
import {
  DeleteLesson,
  DeleteLessonVariables,
  DELETE_LESSON_MUTATION,
} from "queries/learning/lessonDeleteMutation";
import {
  LessonDetailsQuery,
  LessonDetailsQueryVariables,
  LESSON_DETAILS,
} from "queries/learning/lessonDetails";
import {
  MediaAddMutation,
  MediaAddMutationVariables,
  MEDIA_ADD_MUTATION,
} from "queries/mediaAddMutation";
import {
  MediaGetQuery,
  MediaGetQueryVariables,
  MEDIA_GET_QUERY,
} from "queries/mediaGetQuery";
import {
  MediaListQuery,
  MediaListQueryVariables,
  MEDIA_LIST_QUERY,
} from "queries/mediaListQuery";
import {
  MediaUpdateMutation,
  MediaUpdateMutationVariables,
  MEDIA_UPDATE_MUTATION,
} from "queries/mediaUpdateMutation";
import { LIST_MODULES_QUERY, ModulesList } from "queries/learning/modulesList";
import {
  SaveLessonOrder,
  SaveLessonOrderVariables,
  SAVE_LESSON_ORDER_MUTATION,
} from "queries/saveLessonOrderMutation";
import {
  UpdateCard,
  UpdateCardVariables,
  UPDATE_CARD_MUTATION,
} from "queries/learning/updateCardMutation";
import {
  UpdateLesson,
  UpdateLessonVariables,
  UPDATE_LESSON,
} from "queries/learning/updateLessonMutation";
import {
  UpdateModule,
  UpdateModuleVariables,
  UPDATE_MODULE,
} from "queries/learning/updateModuleMutation";
import {
  UploadFile,
  UploadFileVariables,
  UPLOAD_FILE_MUTATION,
} from "queries/uploadFileMutation";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";
import {
  CloneLesson,
  CloneLessonVariables,
  CLONE_LESSON_MUTATION,
} from "queries/learning/cloneLessonMutation";
import {
  PublishDraftModule,
  PublishDraftModuleVariables,
  PUBLISH_DRAFT_MODULE,
} from "queries/learning/publishdraftModule";
import {
  LIST_SEARCH_TAGS_QUERY,
  ListSearchTagsQuery,
} from "queries/learning/listSearchTagsQuery";
import {
  UPDATE_SEARCH_TAG_MUTATION,
  UpdateSearchTagMutation,
  UpdateSearchTagMutationVariables,
} from "queries/learning/updateSearchTagMutation";
import {
  DELETE_SEARCH_TAG_MUTATION,
  DeleteSearchTagMutation,
  DeleteSearchTagMutationVariables,
} from "queries/learning/deleteSearchTagMutation";
import {
  CREATE_SEARCH_TAG_MUTATION,
  CreateSearchTagMutation,
  CreateSearchTagMutationVariables,
} from "queries/learning/createSearchTagMutation";
import {
  LIST_PATH_QUIZZES_QUERY,
  ListPathQuizzesQuery,
} from "queries/learning/listPathQuizzesQuery";
import {
  UPSERT_PATH_QUIZ_MUTATION,
  UpsertPathQuizMutation,
  UpsertPathQuizMutationVariables,
} from "queries/learning/upsertPathQuizMutation";
import {
  DELETE_PATH_QUIZ_MUTATION,
  DeletePathQuizMutation,
  DeletePathQuizMutationVariables,
} from "queries/learning/deletePathQuizMutation";
import {
  REORDER_PATH_QUIZZES_MUTATION,
  ReorderPathQuizzesMutation,
  ReorderPathQuizzesMutationVariables,
} from "queries/learning/reorderPathQuizzesMutation";

export const loadModulesList = createQueryThunk<ModulesList>(
  "learning/loadModulesList",
  LIST_MODULES_QUERY
);

export const getLessonDetails = createQueryThunk<
  LessonDetailsQuery,
  LessonDetailsQueryVariables
>("learning/getLessonDetails", LESSON_DETAILS);

export const uploadFile = createMutationThunk<UploadFile, UploadFileVariables>(
  "learning/uploadFile",
  UPLOAD_FILE_MUTATION
);

export const updateLesson = createMutationThunk<
  UpdateLesson,
  UpdateLessonVariables
>("learning/updateLesson", UPDATE_LESSON);
export const deleteLesson = createMutationThunk<
  DeleteLesson,
  DeleteLessonVariables
>("learning/deleteLesson", DELETE_LESSON_MUTATION);
export const createLesson = createMutationThunk<
  CreateLessonMutation,
  CreateLessonMutationVariables
>("learning/createLesson", CREATE_LESSON_MUTATION);
export const cloneLesson = createMutationThunk<
  CloneLesson,
  CloneLessonVariables
>("learning/cloneLesson", CLONE_LESSON_MUTATION);
export const saveLessonOrder = createMutationThunk<
  SaveLessonOrder,
  SaveLessonOrderVariables
>("learning/saveLessonOrder", SAVE_LESSON_ORDER_MUTATION);

export const updateModule = createMutationThunk<
  UpdateModule,
  UpdateModuleVariables
>("learning/updateModule", UPDATE_MODULE);
export const draftModule = createMutationThunk<
  CopyModuleAsDraft,
  CopyModuleAsDraftVariables
>("learning/draftModule", COPY_MODULE_AS_DRAFT);
export const publishDraftModule = createMutationThunk<
  PublishDraftModule,
  PublishDraftModuleVariables
>("learning/publishDraftModule", PUBLISH_DRAFT_MODULE);

export const listMedia = createQueryThunk<
  MediaListQuery,
  MediaListQueryVariables
>("learning/listMedia", MEDIA_LIST_QUERY);

export const getMedia = createQueryThunk<MediaGetQuery, MediaGetQueryVariables>(
  "learning/getMedia",
  MEDIA_GET_QUERY
);

export const addMedia = createMutationThunk<
  MediaAddMutation,
  MediaAddMutationVariables
>("learning/addMedia", MEDIA_ADD_MUTATION);

export const updateMedia = createMutationThunk<
  MediaUpdateMutation,
  MediaUpdateMutationVariables
>("learning/updateMedia", MEDIA_UPDATE_MUTATION);

export const updateCard = createMutationThunk<UpdateCard, UpdateCardVariables>(
  "learning/updateCard",
  UPDATE_CARD_MUTATION
);

export const createCard = createMutationThunk<CreateCard, CreateCardVariables>(
  "learning/createCard",
  CREATE_CARD_MUTATION
);

export const deleteCard = createMutationThunk<DeleteCard, DeleteCardVariables>(
  "learning/deleateCard",
  DELETE_CARD_MUTATION
);

export const listSearchTags = createQueryThunk<ListSearchTagsQuery>(
  "learning/listSearchTags",
  LIST_SEARCH_TAGS_QUERY
);

export const updateSearchTag = createMutationThunk<
  UpdateSearchTagMutation,
  UpdateSearchTagMutationVariables
>("learning/updateSearchTag", UPDATE_SEARCH_TAG_MUTATION);

export const deleteSearchTag = createMutationThunk<
  DeleteSearchTagMutation,
  DeleteSearchTagMutationVariables
>("learning/deleteSearchTag", DELETE_SEARCH_TAG_MUTATION);

export const createSearchTag = createMutationThunk<
  CreateSearchTagMutation,
  CreateSearchTagMutationVariables
>("learning/createSearchTag", CREATE_SEARCH_TAG_MUTATION);

export const listPathQuizzes = createQueryThunk<ListPathQuizzesQuery>(
  "learning/listPathQuizzes",
  LIST_PATH_QUIZZES_QUERY
);

export const upsertPathQuiz = createMutationThunk<
  UpsertPathQuizMutation,
  UpsertPathQuizMutationVariables
>("learning/upsertPathQuiz", UPSERT_PATH_QUIZ_MUTATION);

export const deletePathQuiz = createMutationThunk<
  DeletePathQuizMutation,
  DeletePathQuizMutationVariables
>("learning/deletePathQuiz", DELETE_PATH_QUIZ_MUTATION);

export const reorderPathQuizzes = createMutationThunk<
  ReorderPathQuizzesMutation,
  ReorderPathQuizzesMutationVariables
>("learning/reorderPathQuizzes", REORDER_PATH_QUIZZES_MUTATION);
