import { Box, Text, TextField, Select } from "ui";
import { ImageElement } from "queries/richTextElementFragment";
import { ImageUpload } from "components/ImageUpload";

export type ImageProps = {
  element: ImageElement;
  isEditMode: boolean;
  onChange: (element: ImageElement) => void;
  onFile?: (file: File) => void;
  loading: boolean;
};

export const Image = (props: ImageProps) => {
  const { element, isEditMode, onChange } = props;

  const handleChangeField = (field: string) => (newValue: string) => {
    const updatedElement = { ...element, [field]: newValue };
    onChange(updatedElement);
  };

  const editView = (
    <Box>
      <ImageUpload
        src={element.url}
        svg={element.svg}
        onUpdateFile={(file) => {
          props.onFile && props.onFile(file);
        }}
        onUpdateSvg={(svg) => onChange({ ...element, url: "", svg })}
      />
      <Box mt="s" display="flex" alignItems="center">
        <Text mr="s">Position:</Text>
        <Select
          value={element.position!}
          onChange={handleChangeField("position")}
          options={[
            { value: "left", label: "left" },
            { value: "center", label: "center" },
          ]}
        />
      </Box>
      <Box mt="s" display="flex" alignItems="center">
        <Text mr="s">Text:</Text>
        <TextField
          value={element.text}
          variant="outlined"
          size="small"
          onChange={(e) => handleChangeField("text")(e.target.value)}
        />
      </Box>
      <Box mt="s" display="flex" alignItems="center">
        <Text mr="s">Text position:</Text>
        <Select
          value={element.textPosition!}
          onChange={handleChangeField("textPosition")}
          options={[
            { value: "left", label: "left" },
            { value: "right", label: "right" },
          ]}
        />
      </Box>
    </Box>
  );

  const readView = (
    <Box
      width="100%"
      display="flex"
      justifyContent={element.position === "center" ? "center" : "flex-start"}
    >
      <Box display="flex" alignItems="center">
        {element.text && element.textPosition === "left" && (
          <Text mr="s">{element.text}</Text>
        )}
        {element.svg && (
          <span dangerouslySetInnerHTML={{ __html: element.svg }} />
        )}
        {element.url && (
          <img alt="" src={element.url} style={{ maxWidth: "100%" }} />
        )}
        {element.text && element.textPosition === "right" && (
          <Text ml="s">{element.text}</Text>
        )}
      </Box>
    </Box>
  );
  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
