import { CardElement } from "queries/richTextElementFragment";
import { LessonDetailsQuery_getLesson_cards } from "queries/learning/lessonDetails";

export const elementsDefaults: { [key: string]: CardElement } = {
  paragraph: {
    __typename: "paragraph",
    text: "<p>text here</p>",
    position: "left",
  },
  heading1: {
    __typename: "heading1",
    text: "<p>text here</p>",
    position: "left",
  },
  heading2: {
    __typename: "heading2",
    text: "<p>text here</p>",
    position: "left",
  },
  list: {
    __typename: "list",
    variant: "bullet",
    bulletChar: "",
    list: ["options here"],
  },
  callout: {
    __typename: "callout",
    text: "<p>text here</p>",
    position: "left",
    bgColor: null,
  },
  quote: { __typename: "quote", text: "<p>text here</p>" },
  divider: { __typename: "divider", color: null },
  image: {
    __typename: "image",
    svg: `<svg width="100" height="100">
    <circle cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />
  </svg>`,
    url: "",
    position: "center",
    text: null,
    textPosition: "left",
  },
  inlineVideo: {
    __typename: "inlineVideo",
    uri: "",
  },
};

export const cardsDefaults = (): {
  [key: string]: LessonDetailsQuery_getLesson_cards;
} => ({
  richtext: {
    __typename: "richtext",
    id: 0,
    title: null,
    elements: [],
  },
  video: {
    __typename: "video",
    title: null,
    id: 0,
    uri: "",
  },
  quiz: {
    __typename: "quiz",
    title: null,
    id: 0,
    falseReply: null,
    trueReply: null,
    options: [
      {
        __typename: "quizOption",
        id: 0,
        label: "options here",
        answer: true,
        falseReply: null,
      },
    ],
    elements: [],
  },
  poll: {
    __typename: "poll",
    title: null,
    id: 0,
    options: [
      {
        __typename: "pollOption",
        id: 0,
        label: "options here",
      },
    ],
    elements: [],
  },
  actionitem: {
    __typename: "actionitem",
    id: 0,
    title: "action item title here",
    taskDuration: null,
    elements: [],
  },
  survey: {
    __typename: "survey",
    id: 0,
    title: "survey title here",
    elements: [],
  },
});
