const pallete = {
  // from figma file
  grey1: "#ACC2CE",
  grey2: "#F4F6F7",
  red1: "#EC383A",
  red2: "#FECCCD",
  violet1: "#C6C3F9",
  violet2: "#ECEBFD",
  junoBlue: "#8BB8BE",
  blue1: "#0662EB",
  blue2: "#ECF4FF",
  orange: "#FFA6A0",
  white: "#FFFFFF",
  green: "#12EEAF",

  // from mobile
  darkBlue: "#473FD0",
  limeGreen: "#CDEC85",
  almostWhite: "#FDFDFD",
  lightBlue: "#F0F0FB",
  almostBlack: "#393939",
  lightGrey: "#F4F6F7",
  mediumGrey: "#E4E6E7",
  placeholderGrey: "#ECECEC",
  grey: "#888888",
  black: "#000000",
  red: "#F15552",
  lilac: "#D0BEF4",

  // from catppuccin
  ctpLatteBase: "#eff1f5",
  ctpLatteBlue: "#1e66f5",
  ctpLatteCrust: "#dce0e8",
  ctpLatteFlamingo: "#dd7878",
  ctpLatteGreen: "#40a02b",
  ctpLatteLavender: "#7287fd",
  ctpLatteMantle: "#e6e9ef",
  ctpLatteMaroon: "#e64553",
  ctpLatteMauve: "#8839ef",
  ctpLatteOverlay0: "#9ca0b0",
  ctpLatteOverlay1: "#8c8fa1",
  ctpLatteOverlay2: "#7c7f93",
  ctpLattePeach: "#fe640b",
  ctpLattePink: "#ea76cb",
  ctpLatteRed: "#d20f39",
  ctpLatteRosewater: "#dc8a78",
  ctpLatteSapphire: "#209fb5",
  ctpLatteSky: "#04a5e5",
  ctpLatteSubtext0: "#6c6f85",
  ctpLatteSubtext1: "#5c5f77",
  ctpLatteSurface0: "#ccd0da",
  ctpLatteSurface1: "#bcc0cc",
  ctpLatteSurface2: "#acb0be",
  ctpLatteTeal: "#179299",
  ctpLatteText: "#4c4f69",
  ctpLatteYellow: "#df8e1d",
};

export const theme = {
  colors: {
    backgroundPrimary: pallete.grey2,
    backgroundSecondary: pallete.white,
    foregroundRegular: pallete.black,
    foregroundContrasting: pallete.white,
    card: pallete.lightBlue,
    primary: pallete.darkBlue,
    ...pallete,
  },
  fontFamily: { primary: "CircularXXWeb" },
  fontSizes: {
    tiny: 12,
    small: 14,
    normal: 16,
    large: 24,
    big: 32,
  },
  space: {
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
  },
  radii: { small: "4px", normal: "8px", large: "24px", xlarge: "44px" },
  buttonVariants: {
    primary: {
      backgroundColor: pallete.black,
    },
    secondary: {
      backgroundColor: pallete.grey1,
    },
  },
  textVariants: {
    normal: {
      fontSize: 16,
      lineHeight: 19,
    },
    error: {
      fontSize: 12,
      color: pallete.red1,
    },
    extraSmall: {
      fontSize: 12,
    },
    small: {
      fontSize: 14,
    },
    large: {
      fontSize: 18,
      lineHeight: 22,
    },
    heading: {
      fontSize: 20,
      lineHeight: 24,
      fontWeight: "bold",
    },
    headingBig: {
      fontSize: 32,
      lineHeight: 38,
      fontWeight: "bold",
    },
    headingSmall: {
      fontSize: 16,
      lineHeight: 19,
      fontWeight: "bold",
    },
    link: {
      fontSize: 16,
      fontWeight: "bold",
      textDecorationLine: "underline",
    },
  },
};

export type Theme = typeof theme;
export default theme;
