import dayjs from "dayjs";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { listAnnouncements, selectAnnouncements } from "state/communication";
import { Box, Button, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

export const AnnouncementsPage = () => {
  const dispatch = useAppDispatch();
  const announcements = useAppSelector(selectAnnouncements);

  useEffect(() => {
    dispatch(listAnnouncements()).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Text size="large" mb="m">
        Announcements
      </Text>
      <Link
        to={"/announcement/create"}
        style={{ width: "215px", textDecoration: "none" }}
      >
        <Button label="anounce the next big thing  📣" />
      </Link>
      <Box mt="s">
        {announcements.map((announcement) => (
          <Link
            key={announcement.id}
            to={`/announcement/edit/${announcement.id}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Text weight="bold" mt="m">
              {announcement.title}
            </Text>
            <Text fontSize="10" color="grey">
              {dayjs(announcement.startDate).format("MM/DD/YYYY")} -{" "}
              {dayjs(announcement.endDate).format("MM/DD/YYYY")}
            </Text>
            <Text mt="xs">{announcement.text}</Text>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
