import { gql } from "@apollo/client";

export type { StatsQuery, StatsQuery_stats } from "./__generated__/StatsQuery";

export const STATS_QUERY = gql`
  query StatsQuery {
    stats: getStats {
      users
      lessonsCompleted
      modulesCompleted
      todosTaken
      todos {
        title
        count
      }
    }
  }
`;
