import styled from "styled-components";
import { CircleProps } from "./types";

export const Circle = styled.div<CircleProps>`
  display: inline-block;
  height: 5px;
  width: 5px;
  border: 2px solid ${(props) => props.color};
  border-radius: 10px;
  background-color: ${(props) => (props.full ? props.color : "transparent")};
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
`;
