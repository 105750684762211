import { gql } from "@apollo/client";
export type {
  LeaderboardQuery,
  LeaderboardQueryVariables,
} from "./__generated__/LeaderboardQuery";

export const LEADERBOARD_QUERY = gql`
  query LeaderboardQuery(
    $type: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    leaderboard(type: $type, startDate: $startDate, endDate: $endDate) {
      id
      name
      points
      email
      imageUrl
    }
  }
`;
