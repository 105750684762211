import { gql } from "@apollo/client";
export type {
  UnpublishReward,
  UnpublishRewardVariables,
} from "./__generated__/UnpublishReward";

export const UNPUBLISH_REWARD = gql`
  mutation UnpublishReward($id: Float!) {
    unpublishReward(id: $id) {
      id
      publishedAt
    }
  }
`;
