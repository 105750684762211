import styled from "styled-components";
import { space, layout, flexbox, border, position } from "styled-system";

import { Props, StyledBoxProps } from "./types";

const StyledCard = styled.div<StyledBoxProps>`
  background-color: "white";
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  ${space}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
`;

export const Card = (props: Props) => {
  const { children, ...cardProps } = props;
  return <StyledCard {...cardProps}>{children}</StyledCard>;
};
