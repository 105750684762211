import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useAppSelector, useAppDispatch } from "utils/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";

import { Box, Button } from "ui";
import { updateCard, selectCurrCard } from "state/learning";

import { PollCard as PollCardType } from "queries/learning/lessonDetails";

import { ElementsEditor } from "../elements";
import { PollOption } from "components/polls/PollOption";

export const PollCard = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);

  const [pollData, setPollData] = useState<PollCardType>(
    currCard as PollCardType
  );
  const [editPoll, setEditPoll] = useState(false);

  useEffect(() => {
    setPollData(currCard as PollCardType);
  }, [currCard]);

  const addItem = () => {
    const updatedQuiz = cloneDeep(pollData);
    updatedQuiz.options.push({
      __typename: "pollOption",
      id: pollData.options.length,
      label: "new option",
    });
    setPollData(updatedQuiz);
  };

  const deleteItem = (idx: number) => {
    const updatedQuiz = cloneDeep(pollData);
    updatedQuiz.options.splice(idx, 1);
    setPollData(updatedQuiz);
  };
  const updateLabel = (e: any, idx: number) => {
    const updatedQuiz = cloneDeep(pollData);
    updatedQuiz.options[idx].label = e.target.value;
    setPollData(updatedQuiz);
  };

  const handleClick = () => {
    if (!editPoll) {
      setEditPoll(true);
    }
  };
  const handleCancelEdit = () => {
    setEditPoll(false);
    setPollData(currCard as PollCardType);
  };

  const handleSave = () => {
    if (pollData.options.length === 0) {
      return;
    }
    const content = cloneDeep(currCard as PollCardType);
    content.options = pollData.options;
    const card = { id: content.id, content };
    dispatch(updateCard({ card }));
    setEditPoll(false);
  };

  const optionTextAlign = pollData.options.every(
    (option) => option.label.length < 20
  )
    ? "center"
    : "left";

  const readView = (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      key={`poll_options${currCard!.id}`}
    >
      {pollData.options.map((option, idx) => (
        <PollOption
          key={`poll_options_${currCard!.id}_${idx}`}
          optionTextAlign={optionTextAlign}
          text={option.label}
        />
      ))}
    </Box>
  );

  const editView = (
    <Box width="100%" bg="white">
      {pollData.options.map((option, idx) => (
        <Box
          display="flex"
          flexDirection="column"
          key={`editPollOptions_${idx}`}
          width="100%"
          border="1px dashed black"
          p="s"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TextField
              label="option"
              value={option.label}
              variant="outlined"
              size="small"
              onChange={(e) => updateLabel(e, idx)}
              fullWidth
              onFocus={(event) => event.target.select()}
            />
            <Box
              hovered
              onClick={() => deleteItem(idx)}
              display="flex"
              justifyContent="center"
              px="s"
            >
              <DeleteIcon color="secondary" />
            </Box>
          </Box>
        </Box>
      ))}
      <Box mt="s" hovered onClick={addItem} display="inline-block">
        <AddCircleIcon />
      </Box>
      <Box mt="l" display="flex" justifyContent="flex-end">
        <Button label="Cancel" onClick={handleCancelEdit} />
        <Button ml="s" label="Save" onClick={handleSave} />
      </Box>
    </Box>
  );

  return (
    <Box>
      <ElementsEditor />
      <Box
        mt="l"
        display="flex"
        justifyContent="center"
        hovred
        onClick={handleClick}
      >
        {editPoll ? editView : readView}
      </Box>
    </Box>
  );
};
