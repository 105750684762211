import { useEffect, useRef, useState } from "react";
import { uploadFile } from "state/learning";
import { Box, Button, Loader } from "ui";
import { useAppDispatch } from "utils";

type Props = {
  src?: string | null | undefined;
  svg?: string | null | undefined;
  onUpdateSrc?: (src: string) => void;
  onUpdateSvg?: (svg: string) => void;
  onUpdateFile?: (file: File) => void;
  loading?: boolean;
  width?: number;
  label?: string;
};

export const ImageUpload = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState<string>();
  const fileInputRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSrc(props.src!);
  }, [props.src]);

  useEffect(() => {
    if (props.loading !== undefined) setLoading(props.loading);
  }, [props.loading]);

  const selectImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    // setFile(file);
    /* SVG gets returned as plain text */
    if (props.onUpdateSvg && /svg/.test(file.type)) {
      const fr = new FileReader();
      fr.onload = function () {
        const svg = fr.result as string;
        props.onUpdateSvg!(svg);
      };
      fr.readAsText(file);
    }
    /* This is an option for uploading the file later, not right away */
    if (props.onUpdateFile && /jpeg|jpg|png$/.test(file.type)) {
      const fr = new FileReader();
      props.onUpdateSvg!("");
      fr.onload = function () {
        const src = fr.result as string;
        setSrc(src);
      };
      fr.readAsDataURL(file);
      props.onUpdateFile(file);
    }
    if (props.onUpdateSrc && /jpeg|jpg|png$/.test(file.type)) {
      // Upload file to the backend
      // Set the link to the url property
      setLoading(true);
      const result = await dispatch(uploadFile({ file }));
      const url = result.payload.singleUpload.uri;
      if (url) {
        props.onUpdateSrc(url);
      }
      setLoading(false);
    }
  };

  return (
    <Box>
      {loading && <Loader />}
      {src && !loading && (
        <img
          alt=""
          src={src}
          style={{ maxWidth: "100%", width: props.width }}
        />
      )}
      {props.svg && !loading && (
        <span dangerouslySetInnerHTML={{ __html: props.svg }} />
      )}
      {!src && !props.svg && !loading && (
        // placeholder box
        <Box
          width={props.width}
          height={props.width}
          maxWidth="100%"
          border="1px solid grey"
          display="flex"
          justifyContent="center"
          alignItems="center"
        />
      )}
      <Box
        mt="s"
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          label={props.label || "Upload Image"}
          onClick={() => {
            fileInputRef.current!.click();
          }}
          disabled={loading}
        />
      </Box>
      <input
        ref={fileInputRef}
        type="file"
        id="getFile"
        accept="image/*"
        style={{ display: "none" }}
        onChange={selectImageFile}
      />
    </Box>
  );
};
