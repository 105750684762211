import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./Calendar.css";
import "./DateRangePicker.css";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { Box, Text } from "ui";
import dayjs from "dayjs";

type Props = {
  label?: string;
  value?: [start: Date, end: Date];
  onChange: (value: [Date, Date]) => void;
};

export const DateRanger = (props: Props) => {
  const [selectedDate, setDate] = useState("7d");

  useEffect(() => {
    switch (selectedDate) {
      case "30d":
        props.onChange([dayjs().subtract(30, "day").toDate(), new Date()]);
        break;
      case "7d":
        props.onChange([dayjs().subtract(7, "day").toDate(), new Date()]);
        break;
      case "yesterday":
        props.onChange([dayjs().subtract(1, "day").toDate(), new Date()]);
        break;
      case "today":
        props.onChange([dayjs().startOf("day").toDate(), new Date()]);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Box flexDirection="column" display="flex" my="s" mx="m">
      <Text mb="m">{props.label || "Time range"}</Text>
      <ToggleButtonGroup
        size="small"
        color="primary"
        exclusive
        value={selectedDate}
        onChange={(e, val) => val && setDate(val)}
      >
        <ToggleButton value="custom">
          {selectedDate === "custom" ? (
            <DateRangePicker
              value={props.value || [new Date(), new Date()]}
              onChange={props.onChange as any} // the type is wrong here
              calendarIcon={null}
              clearIcon={null}
              isOpen
            />
          ) : (
            "Custom"
          )}
        </ToggleButton>
        <ToggleButton value="yesterday">yesterday</ToggleButton>
        <ToggleButton value="today">today</ToggleButton>
        <ToggleButton value="7d">7d</ToggleButton>
        <ToggleButton value="30d">30d</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
