import { gql } from "@apollo/client";

export type {
  MediaUpdateMutation,
  MediaUpdateMutationVariables,
} from "./__generated__/MediaUpdateMutation";

export const MEDIA_UPDATE_MUTATION = gql`
  mutation MediaUpdateMutation(
    $id: Float!
    $key: String
    $content: String
    $startDate: DateTime
    $json: JSON
  ) {
    updateMedia(
      id: $id
      key: $key
      content: $content
      json: $json
      startDate: $startDate
    ) {
      id
    }
  }
`;
