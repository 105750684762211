import { gql } from "@apollo/client";
export type {
  ModulesFragment,
  ModulesFragment_lessons,
} from "./__generated__/ModulesFragment";

export const MODULES_FRAGMENT = gql`
  fragment ModulesFragment on ModuleEntry {
    id
    sourceModuleId
    title
    imageSmallUrl
    imageMediumUrl
    imageLargeUrl
    goalTitle
    goals
    order
    description
    deleted
    moduleType
    partnershipId
    lessons {
      id
      sourceLessonId
      title
      tags
      imageUrl
      order
      minutes
      startedAt
      completedAt
      milestone
    }
  }
`;
