import { useState, useEffect } from "react";
import uniqid from "uniqid";
import cloneDeep from "lodash/cloneDeep";
import { useAppSelector, useAppDispatch } from "utils/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import { Box, Button } from "ui";
import { selectCurrCard, updateCard } from "state/learning";

import { QuizCard as QuizCardType } from "queries/learning/lessonDetails";

import { ElementsEditor } from "../elements";
import { PollOption } from "components/polls/PollOption";

export const QuizCard = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);

  const [quizData, setQuizData] = useState<QuizCardType>(
    currCard as QuizCardType
  );
  useEffect(() => {
    setQuizData(currCard as QuizCardType);
  }, [currCard]);

  const [editQuiz, setEditQuiz] = useState(false);

  useEffect(() => setQuizData(currCard as QuizCardType), [currCard]);

  const addItem = () => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.options.push({
      __typename: "quizOption",
      id: quizData.options.length,
      label: "new option",
      answer: false,
      falseReply: null,
    });
    setQuizData(updatedQuiz);
  };

  const deleteItem = (idx: number) => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.options.splice(idx, 1);
    setQuizData(updatedQuiz);
  };
  const updateLabel = (e: any, idx: number) => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.options[idx].label = e.target.value;
    setQuizData(updatedQuiz);
  };
  const updateFalseReply = (e: any, idx: number) => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.options[idx].falseReply = e.target.value;
    setQuizData(updatedQuiz);
  };
  const handleAnswerChange = (idx: number) => {
    if (quizData.options[idx].answer) {
      return;
    }

    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.options.forEach((option) => {
      option.answer = false;
    });
    updatedQuiz.options[idx].answer = true;
    setQuizData(updatedQuiz);
  };
  const updateMainFalseReply = (e: any) => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.falseReply = e.target.value;
    setQuizData(updatedQuiz);
  };

  const updateMainTrueReply = (e: any) => {
    const updatedQuiz = cloneDeep(quizData);
    updatedQuiz.trueReply = e.target.value;
    setQuizData(updatedQuiz);
  };

  const handleClick = () => {
    if (!editQuiz) {
      setEditQuiz(true);
    }
  };
  const handleCancelEdit = () => {
    setEditQuiz(false);
    setQuizData(currCard! as QuizCardType);
  };

  const handleSave = () => {
    if (quizData.options.length === 0) {
      return;
    }
    const content = cloneDeep(currCard!) as QuizCardType;
    content.options = quizData.options;
    content.falseReply = quizData.falseReply;
    content.trueReply = quizData.trueReply;

    const card = { id: currCard!.id, content };

    dispatch(updateCard({ card }));
    setEditQuiz(false);
  };

  const optionTextAlign = quizData.options.every(
    (option) => option.label.length < 20
  )
    ? "center"
    : "left";
  const readView = (
    <Box display="flex" flexDirection="column" width="100%">
      {quizData.options.map((option, idx) => (
        <PollOption
          key={uniqid()}
          backgroundColor={option.answer ? "green" : "grey2"}
          optionTextAlign={optionTextAlign}
          text={option.label}
        />
      ))}
    </Box>
  );

  const editView = (
    <Box width="100%">
      {quizData.options.map((option, idx) => (
        <Box
          display="flex"
          flexDirection="column"
          key={`quiz_options${option.id}${idx}`}
          width="100%"
          border="1px dashed black"
          p="s"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TextField
              label="option"
              value={option.label}
              variant="outlined"
              size="small"
              onChange={(e) => updateLabel(e, idx)}
              fullWidth
              onFocus={(event) => event.target.select()}
            />
            <Checkbox
              checked={option.answer}
              defaultChecked
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              onChange={() => handleAnswerChange(idx)}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mt="s"
          >
            <TextField
              label="false reply"
              value={option.falseReply}
              variant="outlined"
              size="small"
              onChange={(e) => updateFalseReply(e, idx)}
              fullWidth
              onFocus={(event) => event.target.select()}
            />
            <Box
              hovered
              onClick={() => deleteItem(idx)}
              display="flex"
              justifyContent="center"
              px="s"
            >
              <DeleteIcon color="secondary" />
            </Box>
          </Box>
          <Box mt="s" display="flex" alignItems="center"></Box>
        </Box>
      ))}
      <Box mt="s" hovered onClick={addItem} display="inline-block">
        <AddCircleIcon />
      </Box>
      <Box mt="m">
        <TextField
          label="Main true reply"
          value={quizData.trueReply}
          variant="outlined"
          size="small"
          onChange={updateMainTrueReply}
          fullWidth={true}
          multiline
          maxRows={2}
        />
      </Box>
      <Box mt="m">
        <TextField
          label="Main false reply"
          value={quizData.falseReply}
          variant="outlined"
          size="small"
          onChange={updateMainFalseReply}
          fullWidth={true}
          multiline
          maxRows={2}
        />
      </Box>

      <Box mt="l" display="flex" justifyContent="flex-end">
        <Button label="Cancel" onClick={handleCancelEdit} />
        <Button ml="s" label="Save" onClick={handleSave} />
      </Box>
    </Box>
  );

  return (
    <Box>
      <ElementsEditor />
      <Box
        mt="l"
        display="flex"
        justifyContent="center"
        hovred
        onClick={handleClick}
      >
        {editQuiz ? editView : readView}
      </Box>
    </Box>
  );
};
