import { gql } from "@apollo/client";
export type {
  CreateCard,
  CreateCardVariables,
} from "./__generated__/CreateCard";

export const CREATE_CARD_MUTATION = gql`
  mutation CreateCard($card: CardInput!) {
    createCard(card: $card)
  }
`;
