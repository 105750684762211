import MaterialTable from "material-table";

import { Box, Text } from "ui";
import { PollDetails } from "./PollDetails";

import { Poll } from "./types";
import { listPolls, upsertPoll } from "state/polls";
import { useAppDispatch } from "utils";
import { startCase } from "lodash";
import dayjs from "dayjs";

type PollsTableProps = {
  polls: Poll[];
  isLoading: boolean;
};

export const PollsTable = (props: PollsTableProps) => {
  const { polls, isLoading } = props;
  const dispatch = useAppDispatch();

  return (
    <MaterialTable
      style={{ width: "95%" }}
      data={polls}
      columns={[
        {
          title: "Title",
          field: "poll.title",
          cellStyle: { textAlign: "left" },
        },
        {
          title: "Start Date",
          field: "startDate",
          type: "date",
          cellStyle: { textAlign: "left" },
          render(rowData)  {
            return <Box>{dayjs(rowData.startDate).format('DD.MM.YYYY')}</Box>
          }
        },
        {
          title: "",
          field: "",
          render: (rowData) => {
            return (
              <Box
                borderRadius={10}
                border="1px solid blue"
                p="s"
                display="inline-block"
              >
                <Text weight="bold">{startCase(rowData.status!)}</Text>
              </Box>
            );
          },
        },
      ]}
      options={{
        headerStyle: { fontWeight: 700, textAlign: "left" },
        addRowPosition: "first",
        search: false,
        grouping: false,
        paging: false,
        showTitle: false,
        toolbar: true,
        rowStyle: { overflowWrap: "break-word" },
      }}
      isLoading={isLoading}
      editable={{
        isDeleteHidden: (_) => true,
        isEditable: (rowData) => rowData.status !== "published",
        isDeletable: (rowData) => rowData.status !== "published",
        onRowAdd: async (newData) => {
          newData.startDate = newData.startDate || new Date();
          newData.poll = {
            title: "new poll",
            options: [
              { id: 1, label: "first option" },
              { id: 2, label: "second option" },
              { id: 3, label: "third option" },
            ],
          };
          await dispatch(upsertPoll({ poll: newData as any }));
          await dispatch(listPolls());
        },
        onRowUpdate: async (newData, oldData) => {
          const poll: Poll = {
            id: newData.id,
            slug: newData.poll.title.toLowerCase().replace(/\s/g, "-"),
            startDate: newData.startDate,
            poll: {
              title: newData.poll.title,
              options: newData.poll.options.map(({ id, label }) => ({
                id,
                label,
              })),
            },
          };
          await dispatch(upsertPoll({ poll }));
          await dispatch(listPolls());
        },
      }}
      detailPanel={(rowData) => (
        <PollDetails poll={rowData} disabled={rowData.status === "published"} />
      )}
    />
  );
};
