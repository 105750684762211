import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Text, Box, Button } from "ui";
import { GenericElementsEditor } from "components/content-editor/elements/GenericElementsEditor";
import { useCallback, useState } from "react";
import { useAppDispatch } from "utils";
import { addMedia } from "state/learning";

export const NewsCreatePage = () => {
  const [elements, setElements] = useState([]);
  const [startDate, setStartDate] = useState<Date>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onUpdate = useCallback((updatedElements: any) => {
    if (updatedElements) {
      setElements(updatedElements);
    }
  }, []);

  const onSave = useCallback(async () => {
    const res = await dispatch(
      addMedia({
        key: "news",
        type: "richtext",
        content: "",
        startDate,
        json: { elements },
      })
    );
    history.push(`/news/edit/${res.payload.addMedia.id}`);
  }, [dispatch, elements, history, startDate]);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex">
        <Link
          to="/news"
          style={{ color: "#393939", paddingTop: "2px", paddingRight: "4px" }}
        >
          <ArrowBackIosIcon />
        </Link>
        <Text size="large">Post News</Text>
      </Box>
      <Box
        display="flex"
        style={{ border: "1px solid #C8C8C8", width: "340px" }}
        flexDirection="column"
        mt="l"
        p="m"
      >
        <GenericElementsEditor elements={elements} onUpdate={onUpdate} />
      </Box>
      <Box mt="s" display="flex" width="600px">
        <Text>Publish at:</Text>
        <DatePicker
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            if (date) {
              setStartDate(date);
            }
          }}
        />
      </Box>
      <Box display="flex" mt="l">
        <Button label="Create newsletter" onClick={onSave} />
      </Box>
    </Box>
  );
};
