import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { LessonDetailsQuery_getLesson } from "queries/learning/lessonDetails";
import { ModulesList_getModules } from "queries/learning/modulesList";

import type { RootState } from "store";

import {
  deleteLesson,
  getLessonDetails,
  loadModulesList,
  saveLessonOrder,
  updateCard,
  deleteCard,
  updateLesson,
  updateModule,
  draftModule,
  listSearchTags,
  createSearchTag,
  deleteSearchTag,
  updateSearchTag,
  listPathQuizzes,
  deletePathQuiz,
  upsertPathQuiz,
  reorderPathQuizzes,
} from "./thunks";
import { ListSearchTagsQuery } from "queries/learning/listSearchTagsQuery";
import { ListPathQuizzesQuery } from "queries/learning/listPathQuizzesQuery";

// Define a type for the slice state
interface LearningState {
  requestStatus: string;
  requestProgress?: number;
  modulesList: ModulesList_getModules[];
  currLessonId?: number;
  currLesson: LessonDetailsQuery_getLesson | undefined;
  currCardIdx?: number;
  searchTags: ListSearchTagsQuery["listSearchTags"];
  pathQuizzes: ListPathQuizzesQuery["listPathQuizzes"];
}

const initialState: LearningState = {
  requestStatus: "idle",
  requestProgress: undefined,
  modulesList: [],
  currLessonId: undefined,
  currLesson: undefined,
  currCardIdx: undefined,
  searchTags: [],
  pathQuizzes: [],
};

export const learningSlice = createSlice({
  name: "learning",
  initialState,
  reducers: {
    setCurrCardIdx: (state, action) => {
      state.currCardIdx = action.payload;
    },
    setRequestProgress: (state, action) => {
      state.requestProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadModulesList.fulfilled, (state, action) => {
      state.requestStatus = "idle";
      state.modulesList = action.payload.getModules || [];
    });
    builder.addCase(loadModulesList.pending, (state) => {
      state.requestStatus = "loadModulesList_loading";
    });
    builder.addCase(getLessonDetails.fulfilled, (state, action) => {
      state.requestStatus = "idle";
      const { lessonId } = action.meta.arg;
      state.currLesson = action.payload.getLesson;
      state.currLessonId = lessonId;
    });
    builder.addCase(getLessonDetails.pending, (state) => {
      state.requestStatus = "getLessonDetails_loading";
    });
    builder.addCase(updateLesson.pending, (state, action) => {
      state.currLesson = action.meta.arg.lesson;
    });
    builder.addCase(updateLesson.fulfilled, (state, action) => {
      const lessonId = action.meta.arg.lessonId;
      state.modulesList.forEach((module) =>
        module.lessons.forEach((lesson) => {
          if (lesson.id === lessonId) {
            lesson.title = state.currLesson?.title!;
            lesson.milestone = state.currLesson?.milestone!;
          }
        })
      );
    });
    builder.addCase(deleteLesson.fulfilled, (state, _action) => {
      state.modulesList.forEach((module) => {
        module.lessons = module.lessons.filter(
          (lesson) => lesson.id !== state.currLessonId
        );
      });
      state.currLessonId = undefined;
      state.currLesson = undefined;
    });
    builder.addCase(updateModule.fulfilled, (state, action) => {
      const moduleId = action.payload.updateModule;
      const update = action.meta.arg.module;
      state.modulesList.forEach((module) => {
        if (module.id === moduleId) {
          if (update.title) module.title = update.title;
          if (update.imageSmallUrl) module.imageSmallUrl = update.imageSmallUrl;
          if (update.imageMediumUrl)
            module.imageMediumUrl = update.imageMediumUrl;
          if (update.imageLargeUrl) module.imageLargeUrl = update.imageLargeUrl;
          if (update.description) module.description = update.description;
          if (update.deleted !== undefined) module.deleted = update.deleted;
        }
      });
    });
    builder.addCase(draftModule.fulfilled, (state, action) => {
      state.modulesList.push(action.payload.draftModule);
    });
    builder.addCase(updateCard.fulfilled, (state, action) => {
      if (state.currCardIdx !== undefined) {
        const args = action.meta.arg.card;
        // There is currently a mismatch in how the cards are represented in the frontend
        // and how they're represented in the backend - the "content" property contains the
        // version that is present in the frontend
        if (args.content)
          state.currLesson!.cards[state.currCardIdx!] = args.content;
        if (args.taskDuration !== undefined) {
          (state.currLesson!.cards[state.currCardIdx!] as any).taskDuration =
            args.taskDuration;
        }
      }
    });
    builder.addCase(deleteCard.pending, (state, action) => {
      const { cardId } = action.meta.arg;
      const cardIdx = state.currLesson!.cards.findIndex(
        (card) => card.id === cardId
      );
      console.log(cardId, cardIdx, state.currLesson!.cards);
      state.currLesson!.cards.splice(cardIdx, 1);
    });
    builder.addCase(saveLessonOrder.pending, (state, action) => {
      state.requestStatus = "saveLessonOrder_loading";
      const lessonOrder = action.meta.arg.lessonIds!;
      const { currLessonId } = state;
      for (const module of state.modulesList) {
        for (const lesson of module.lessons) {
          if (lesson.id === currLessonId) {
            module.lessons.forEach((lesson) => {
              lesson.order = lessonOrder.indexOf(lesson.id);
            });
            module.lessons = _.orderBy(module.lessons, "order");
          }
        }
      }
    });
    builder.addCase(saveLessonOrder.fulfilled, (state, _action) => {
      state.requestStatus = "idle";
    });
    builder.addCase(listSearchTags.fulfilled, (state, action) => {
      state.searchTags = action.payload.listSearchTags;
    });
    builder.addCase(createSearchTag.fulfilled, (state, action) => {
      state.searchTags.push(action.payload.createSearchTag);
    });
    builder.addCase(deleteSearchTag.pending, (state, action) => {
      const { id: tagId } = action.meta.arg;
      state.searchTags = state.searchTags.filter((tag) => tag.id !== tagId);
    });
    builder.addCase(updateSearchTag.pending, (state, action) => {
      const { id: tagId, tag: newName, sorting } = action.meta.arg;
      state.searchTags.forEach((tag) => {
        if (tag.id === tagId) {
          tag.tag = newName;
          tag.sorting = sorting;
        }
      });
    });
    builder.addCase(listPathQuizzes.fulfilled, (state, action) => {
      state.pathQuizzes = action.payload.listPathQuizzes;
    });
    builder.addCase(deletePathQuiz.fulfilled, (state, action) => {
      const { id: quizId } = action.meta.arg;
      state.pathQuizzes = state.pathQuizzes.filter(
        (quiz) => quiz.id !== quizId
      );
    });
    builder.addCase(upsertPathQuiz.fulfilled, (state, action) => {
      const { upsertPathQuiz: quiz } = action.payload;
      if (!quiz) console.log(action.payload);
      const idx = state.pathQuizzes.findIndex((q) => q.id === quiz.id);
      if (idx === -1) {
        state.pathQuizzes.push(quiz);
      } else {
        state.pathQuizzes[idx] = quiz;
      }
    });
    builder.addCase(updateSearchTag.rejected, (state, _action) => {
      state.requestStatus = "updateSearchTag_error";
    });
    builder.addCase(createSearchTag.rejected, (state, _action) => {
      state.requestStatus = "createSearchTag_error";
    });
    builder.addCase(reorderPathQuizzes.fulfilled, (state, action) => {
      const { ids } = action.meta.arg;
      state.pathQuizzes = ids.map(
        (id) => state.pathQuizzes.find((q) => q.id === id)!
      );
    });
  },
});

export const selectRequestStatus = (state: RootState) =>
  state.learning.requestStatus;
export const selectRequestProgress = (state: RootState) =>
  state.learning.requestProgress;
export const selectModulesList = (state: RootState) =>
  state.learning.modulesList.filter(
    (m) => m.moduleType === "NORMAL" && !m.partnershipId
  );
export const selectAllModulesList = (state: RootState) =>
  state.learning.modulesList.filter((m) => m.moduleType === "NORMAL");
export const selectModulesDrafts = (state: RootState) =>
  state.learning.modulesList.filter((m) => m.moduleType === "DRAFT");
export const selectCurrLessonId = (state: RootState) =>
  state.learning.currLessonId;
export const selectCurrCardIdx = (state: RootState) =>
  state.learning.currCardIdx;
export const selectCurrLesson = (state: RootState) => state.learning.currLesson;
export const selectCurrCard = (state: RootState) =>
  state.learning.currCardIdx !== undefined
    ? state.learning.currLesson?.cards[state.learning.currCardIdx]
    : null;
export const selectModule =
  (moduleId: number | undefined) => (state: RootState) =>
    moduleId
      ? state.learning.modulesList.find((module) => module.id === moduleId)
      : undefined;
export const selectLessonOrder = (state: RootState) => {
  const { currLessonId } = state.learning;
  for (const module of state.learning.modulesList) {
    for (const lesson of module.lessons) {
      if (lesson.id === currLessonId) {
        return _.orderBy(module.lessons, "order").map((l) => l.id);
      }
    }
  }
  return [];
};
export const { setCurrCardIdx, setRequestProgress } = learningSlice.actions;
export const selectSearchTags = (tagType: string) => (state: RootState) =>
  _.orderBy(
    state.learning.searchTags.filter((t) => t.tagType === tagType),
    "sorting"
  );
export const selectPathQuizModules = (state: RootState) =>
  state.learning.modulesList.map((m) => ({
    id: m.id,
    title: m.title,
  }));
export const selectPathQuizzes = (state: RootState) =>
  state.learning.pathQuizzes;
export const selectPathQuiz = (quizId: number | null) => (state: RootState) =>
  state.learning.pathQuizzes.find((q) => q.id === quizId);
export const selectAvailablePathQuizModules =
  (quizId: number | null) => (state: RootState) => {
    // return modules that aren't used by any other quiz except the current one
    const usedModules = state.learning.pathQuizzes
      .filter((q) => q.id !== quizId)
      .flatMap((q) => q.answers.flatMap((a) => a.moduleIds));
    return state.learning.modulesList
      .filter((m) => !usedModules.includes(m.id))
      .filter((m) => m.moduleType === "NORMAL")
      .map((m) => ({
        id: m.id,
        title: m.title,
      }));
  };

export default learningSlice.reducer;
