import { Box, Button, Text } from "ui";
import { Routes } from "./Routes";

import logo from "assets/logo.png";
import { Menu } from "./Menu";
import { useAppDispatch, useAppSelector } from "utils";
import { logout, selectIsLoggedIn } from "state/user";
import { Link } from "react-router-dom";
import { AuthenticationPage } from "pages";

export const Layout = () => {
  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  return (
    <Box style={{ minHeight: "100vh" }}>
      <Box
        display="flex"
        height={50}
        borderTop="1px black solid"
        borderBottom="1px black solid"
        backgroundColor="grey1"
      >
        <Box
          width={200}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={logo} alt="logo" height={40} width={40} />
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {isLoggedIn ? (
            <>
              <Text mr="m" color="white" weight="bold">
                {username}
              </Text>
              <Button
                backgroundColor="transparent"
                label="🍂"
                onClick={() => dispatch(logout())}
                title="leaf the backoffice"
              />
            </>
          ) : (
            <Box mr="l">
              <Link to="/login">log in</Link>
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ minHeight: "100vh" }}>
        {isLoggedIn ? (
          <Box display="flex" style={{ minHeight: "100vh" }}>
            <Box
              width={200}
              backgroundColor="grey1"
              borderRight="1px black solid"
            >
              <Menu />
            </Box>
            <Box width="100%">
              <Routes />
            </Box>
          </Box>
        ) : (
          <AuthenticationPage />
        )}
      </Box>
    </Box>
  );
};
