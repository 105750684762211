import uniqid from "uniqid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

type radioButtonsGroupProps = {
  name?: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (val: string) => void;
};
export const RadioButtonsGroup = (props: radioButtonsGroupProps) => {
  const { name, options, value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        name={name || uniqid()}
        value={value}
        onChange={handleChange}
      >
        {options.map((item) => (
          <FormControlLabel
            key={`radio_${item.value}`}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
