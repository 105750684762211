import { gql } from "@apollo/client";
export type {
  AuditLogQuery,
  AuditLogQueryVariables,
} from "./__generated__/AuditLogQuery";

export const AUDITLOG_QUERY = gql`
  query AuditLogQuery(
    $search: String
    $userId: Float
    $page: Float
    $pageSize: Float
  ) {
    auditLogs(
      search: $search
      userId: $userId
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      entries {
        userName
        userId
        text
        date
      }
    }
  }
`;
