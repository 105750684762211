import { useState } from "react";
import { Box, Text, TextField, Select } from "ui";
import { InlineVideoElement } from "queries/richTextElementFragment";
import { ImageUpload } from "components/ImageUpload";
import { uploadFile } from "state/learning";
import { useAppDispatch } from "utils";

type Props = {
  element: InlineVideoElement;
  isEditMode: boolean;
  onChange: (element: InlineVideoElement) => void;
  onFile?: (file: File) => void;
  loading: boolean;
};

export const Video = (props: Props) => {
  const { element, isEditMode, onChange } = props;
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState(false);

  const handleChangeField = (field: string) => (newValue: string) => {
    const updatedElement = { ...element, [field]: newValue };
    onChange(updatedElement);
  };

  const videoPlaybackId = element.uri
    ? element.uri.substring(
        element.uri.lastIndexOf("/") + 1,
        element.uri.lastIndexOf(".")
      )
    : "";

  const uploadVideo = async (file: File) => {
    setUploading(true);
    try {
      const result = await dispatch(uploadFile({ file }));
      const videoUrl = result.payload.singleUpload.uri;
      // const content = cloneDeep(currCard! as VideoCardType);
      // content.uri = videoUrl!;
      // const card = { id: currCard!.id, content };
      // dispatch(updateCard({ card }));
      handleChangeField("uri")(videoUrl!);
    } catch (e) {
      console.log(e);
    }
    setUploading(false);
  };

  const editView = (
    <Box>
      {videoPlaybackId && (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          src={`https://image.mux.com/${videoPlaybackId}/thumbnail.jpg?time=0`}
          width="100%"
        />
      )}
      {uploading ? (
        <Box>
          <Text>Uploading video, please wait...</Text>
        </Box>
      ) : (
        <Box>
          <Text>Upload video:</Text>
          <input
            type="file"
            onChange={(event) => {
              if (!event.target.files?.length) {
                return;
              }
              const file = event.target.files[0];
              uploadVideo(file);
            }}
          />
        </Box>
      )}
    </Box>
  );

  const readView = (
    <Box width="100%" display="flex">
      <Box display="flex" flexDirection="column">
        {videoPlaybackId ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            src={`https://image.mux.com/${videoPlaybackId}/thumbnail.jpg?time=0`}
            width="100%"
          />
        ) : (
          <Box
            width={224}
            height={126}
            border="1px solid black"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>upload video 📼🎥</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
