import {gql} from '@apollo/client';
export type {
  UploadFile,
  UploadFileVariables,
} from './__generated__/UploadFile';

export const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!) {
    singleUpload(file: $file) {
      uri
      success
      error
    }
  }
`;
