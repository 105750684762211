import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "./partnershipFragment";
export type { ListPartnerships } from "./__generated__/ListPartnerships";

export const LIST_PARTNERSHIPS = gql`
  ${PARTNERSHIP_FRAGMENT}
  query ListPartnerships {
    partnerships {
      ...PartnershipFragment
    }
  }
`;
