import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Text, Box, Button, theme } from "ui";
import { GenericElementsEditor } from "components/content-editor/elements/GenericElementsEditor";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "utils";
import { updateMedia } from "state/learning";
import {
  MediaGetQuery,
  MediaGetQueryVariables,
  MEDIA_GET_QUERY,
} from "queries/mediaGetQuery";
import { useQuery } from "@apollo/client";
import { apolloClient } from "utils/apolloClient";
import { Tooltip } from "@material-ui/core";
import dayjs from "dayjs";
import { ImageUpload } from "components/ImageUpload";

export const NewsEditPage = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const [elements, setElements] = useState([]);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [startDate, setStartDate] = useState<Date>();
  const dispatch = useAppDispatch();
  const [edited, setEdited] = useState(false);
  const [open, setOpen] = useState(false); // the unsaved changes warning
  const { refetch: fetchNews } = useQuery<
    MediaGetQuery,
    MediaGetQueryVariables
  >(MEDIA_GET_QUERY, { client: apolloClient, skip: true });

  useEffect(() => {
    fetchNews({ id: parseInt(newsId, 10) }).then((news) => {
      setElements(news.data.getMedia.json.elements);
      setTitle(news.data.getMedia.json.title || "");
      setImageUrl(news.data.getMedia.json.imageUrl || "");
      const { startDate } = news.data.getMedia;
      if (startDate) {
        setStartDate(dayjs(startDate).toDate());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  const onUpdate = useCallback((updatedElements: any) => {
    if (updatedElements) {
      setElements(updatedElements);
    }
    setEdited(true);
  }, []);

  const onSave = useCallback(async () => {
    await dispatch(
      updateMedia({
        id: parseInt(newsId, 10),
        startDate,
        json: { elements, title, imageUrl },
      })
    );
    setEdited(false);
  }, [dispatch, elements, imageUrl, newsId, startDate, title]);

  return (
    <Box
      width="340px"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex" alignItems="center">
        <Tooltip
          open={open && edited}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          title={
            edited && (
              <Text weight="bold">
                ⚠️ You have unsaved changes! Do you really want to go back? 🫣
              </Text>
            )
          }
        >
          <Link
            to="/news"
            style={{ color: "#393939", paddingTop: "2px", paddingRight: "4px" }}
          >
            <ArrowBackIosIcon />
          </Link>
        </Tooltip>
        <Text>Edit Newsletter</Text>
      </Box>
      <Box
        display="flex"
        border="1px solid #C8C8C8"
        flexDirection="column"
        mt="l"
        p="m"
      >
        <ImageUpload
          src={imageUrl}
          onUpdateSrc={(imageUrl) => {
            setImageUrl(imageUrl);
            setEdited(true);
          }}
        />
        <textarea
          style={{
            fontSize: theme.fontSizes.big,
            marginBottom: theme.space.l,
            border: "1px dotted lightgrey",
            marginTop: theme.space.l,
            fontFamily: theme.fontFamily.primary,
          }}
          title="title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setEdited(true);
          }}
          placeholder="title goes here..."
        />
        <GenericElementsEditor elements={elements} onUpdate={onUpdate} />
      </Box>
      <Box mt="s" display="flex">
        <Text>Publish&nbsp;at:&nbsp;</Text>
        <DatePicker
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            if (date) {
              setStartDate(date);
              setEdited(true);
            }
          }}
        />
      </Box>
      <Box display="flex" mt="l">
        <Button label="Update newsletter" onClick={onSave} disabled={!edited} />
      </Box>
    </Box>
  );
};
