import { useState, useRef } from "react";
import styled from "styled-components";
import { uploadFile } from "state/learning";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";

import { Box, Text, Button, theme } from "ui";

import {
  CardElement,
  ParagraphElement,
  Heading1Element,
  Heading2Element,
  ListElement,
  QuoteElement,
  DividerElement,
  CalloutElement,
  ImageElement,
  InlineVideoElement,
} from "queries/richTextElementFragment";

import {
  Paragraph,
  Heading1,
  Heading2,
  List,
  Quote,
  Divider,
  Callout,
  Image,
  Video,
} from "./elements";

import { AddElement } from "./AddElement";
import { useAppDispatch } from "utils";

const HoveredBox = styled(Box)`
  cursor: ${({ isEditMode }) => (isEditMode ? undefined : "pointer")};
  opacity: ${({ opacity }) => opacity};
`;

export const ItemTypes = {
  CARD: "element",
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface DragItem {
  index: number;
  id: string;
}

const elementSwitch = (
  element: CardElement,
  isEditMode: boolean,
  hidePosition: boolean,
  handleChange: (element: CardElement) => void,
  onFile: (file: File) => void,
  loading: boolean
) => {
  switch (element.__typename) {
    case "paragraph":
      return (
        <Paragraph
          element={element as ParagraphElement}
          isEditMode={isEditMode}
          hidePosition={hidePosition}
          onChange={handleChange}
        />
      );
    case "heading1":
      return (
        <Heading1
          element={element as Heading1Element}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "heading2":
      return (
        <Heading2
          element={element as Heading2Element}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "list":
      return (
        <List
          element={element as ListElement}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "quote":
      return (
        <Quote
          element={element as QuoteElement}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "divider":
      return (
        <Divider
          element={element as DividerElement}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "callout":
      return (
        <Callout
          element={element as CalloutElement}
          isEditMode={isEditMode}
          onChange={handleChange}
        />
      );
    case "image":
      return (
        <Image
          element={element as ImageElement}
          isEditMode={isEditMode}
          onChange={handleChange}
          onFile={onFile}
          loading={loading}
        />
      );
    case "inlineVideo":
      return (
        <Video
          element={element as InlineVideoElement}
          isEditMode={isEditMode}
          onChange={handleChange}
          onFile={onFile}
          loading={loading}
        />
      );
  }
};

type ElementProps = {
  index?: number;
  element: CardElement;
  isEditMode: boolean;
  isFirstElement: boolean;
  isLastElement: boolean;
  hidePosition?: boolean;
  title?: string;
  onToggleEditMode: (isEdit: boolean) => void;
  onUpdateElement: (element: CardElement) => void;
  onDeletElement?: () => void;
  onAddElementBefore?: (type: string) => void;
  onAddElementAfter?: (type: string) => void;
  onMoveCard?: (dragIndex: number, hoverIndex: number) => void;
  onCardDropped?: (inPlace: boolean) => void;
  enableDragging?: boolean;
  blocklist?: string[];
};

export const Element = (props: ElementProps) => {
  const {
    index,
    element,
    isEditMode,
    isFirstElement,
    isLastElement,
    hidePosition,
    title,
    onToggleEditMode,
    onUpdateElement,
    onDeletElement,
    onAddElementBefore,
    onAddElementAfter,
    onMoveCard,
    onCardDropped,
    blocklist = [],
    enableDragging = false,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [elementData, setElementData] = useState(element);
  const [file, onFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const detectSVG = require("detect-svg");

  const handleMouseOver = () => {
    setIsHovering(!isEditMode);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    if (!isEditMode) {
      onToggleEditMode(true);
    }
  };

  const handleCancelEdit = () => {
    setElementData(element);
    onToggleEditMode(false);
  };

  const handleSave = async () => {
    if (
      element.__typename === "image" &&
      !detectSVG((elementData as ImageElement).svg) &&
      (elementData as ImageElement).svg
    ) {
      alert("This is a wrong SVG. Saving is not possible");
      return;
    }
    if (file && element.__typename === "image") {
      setLoading(true);
      const result = await dispatch(uploadFile({ file }));
      setLoading(false);
      const url = result.payload.singleUpload.uri;
      if (url) {
        (elementData as ImageElement).svg = "";
        (elementData as ImageElement).url = url;
      }
    }
    onUpdateElement(elementData);
  };

  // const handleChange = (updatedElement: CardElement) => {
  //   setElementData(updatedElement);
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_x, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex! && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex! && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      setTimeout(() => {
        onMoveCard && onMoveCard(dragIndex, hoverIndex!);
      }, 50);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex!;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_, monitor: any) => {
      onCardDropped && onCardDropped(monitor.didDrop());
    },
  });

  if (enableDragging) {
    drag(drop(ref));
  }

  return (
    <Box ref={ref}>
      <HoveredBox
        opacity={isDragging ? 0 : 1}
        borderWidth={isHovering || isEditMode ? 1 : 0}
        borderStyle={isEditMode ? undefined : "dashed"}
        px={isHovering ? 0 : 1}
        py={isHovering ? 8 : 9}
        position="relative"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        isEditMode={isEditMode}
        backgroundColor={isEditMode ? "white" : undefined}
      >
        {isHovering && (
          <Text
            size="small"
            backgroundColor={theme.colors.card}
            position="absolute"
            top={-10}
            left={10}
          >
            {title || elementData.__typename}
          </Text>
        )}
        {isEditMode && isFirstElement && onAddElementBefore && (
          <Box mb="s">
            <AddElement onAddElement={onAddElementBefore} />
          </Box>
        )}
        <Box border={isEditMode ? "1px black solid" : undefined}>
          {elementSwitch(
            elementData,
            isEditMode,
            !!hidePosition,
            setElementData,
            onFile,
            loading
          )}
        </Box>
        {isEditMode && (
          <Box>
            <Box
              mt="l"
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              {onDeletElement && (
                <Button
                  label="Delete"
                  backgroundColor="red"
                  onClick={onDeletElement}
                />
              )}
              <Box display="flex">
                <Button label="Cancel" onClick={handleCancelEdit} />
                <Button ml="s" label="Save" onClick={handleSave} />
              </Box>
            </Box>
            {!isLastElement && onAddElementAfter && (
              <Box mt="s">
                <AddElement onAddElement={onAddElementAfter} blocklist={blocklist} />
              </Box>
            )}
          </Box>
        )}
      </HoveredBox>
    </Box>
  );
};
