import { Box, Text, theme } from "ui";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
import { RewardType } from "queries/reward/listRewards";

type Props = {
  reward: RewardType;
  unDraggable?: boolean;
  onDrop(itemId: number, targetId: number): void;
};

export const RewardsRow = ({ reward, onDrop, unDraggable }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "REWARD",
    item: () => reward,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "REWARD",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item: any, monitor) => {
      onDrop(item.id, reward.id);
    },
    canDrop: (item: any, monitor) => {
      return item.id !== reward.id;
    },
  }));
  drop(dragPreview(ref));

  return (
    <TableRow
      key={reward.id}
      ref={ref as any}
      role="Dustbin"
      title={`Reward #${reward.id}`}
      style={{
        opacity: isDragging ? 0.5 : 1,
        borderTop:
          isOver && canDrop
            ? `2px solid ${theme.colors.limeGreen}`
            : `2px solid ${theme.colors.lightGrey}`,
        borderBottom: unDraggable ? "2px solid white" : undefined,
        backgroundColor: isDragging ? "lightgray" : "white",
        height: unDraggable ? "150px" : undefined,
      }}
    >
      <TableCell ref={drag}>
        {!unDraggable && (
          <DragHandleIcon role="Handle" style={{ cursor: "move" }} />
        )}
      </TableCell>
      <TableCell>
        <Link
          to={`/rewards-management/${reward.id}`}
          style={{ color: "#393939" }}
        >
          <Text>{reward.brand}</Text>
        </Link>
      </TableCell>
      <TableCell>
        <Box display="flex">
          <Link
            to={`/rewards-management/${reward.id}`}
            style={{ color: "#393939" }}
          >
            <Text>{reward.title}</Text>
          </Link>
          {reward.autoDelivery && (
            <>
              <div>&nbsp;</div>
              <Text title="auto delivery">🪄</Text>
            </>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Link
          to={`/rewards-management/${reward.id}`}
          style={{ color: "#393939" }}
        >
          {reward.rewardType === "CHARITY" && (
            <Text>{reward.raised || 0} Raised</Text>
          )}
          {reward.rewardType === "SHOP" && (
            <Text>
              {reward.participants}/{reward.allocation}
            </Text>
          )}
        </Link>
      </TableCell>
      <TableCell>
        {unDraggable // dummy element, nothing to display
          ? ""
          : !reward.publishedAt // not published
          ? "hidden 🙈"
          : !!reward.allocation &&
            (reward.participants || 0) >= reward.allocation // exhausted
          ? "full 🎉"
          : reward.endDate && // if end date is set
            reward.endDate < new Date() && // and end date is in the past
            reward.rewardType === "SHOP" // expired, only used for shops so far
          ? "expired 🕰"
          : "published ✨"}
      </TableCell>
    </TableRow>
  );
};
