import { Box, Text, TextField, Select } from "ui";
import { CalloutElement } from "queries/richTextElementFragment";
import { RichTextEditor, RichTextViewer } from "components/rich-text";

export type CalloutProps = {
  element: CalloutElement;
  isEditMode: boolean;
  onChange: (element: CalloutElement) => void;
};

export const Callout = (props: CalloutProps) => {
  const { element, isEditMode, onChange } = props;

  const handleTextChange = (text: string) => {
    const updatedElement = { ...element, text: text };
    onChange(updatedElement);
  };

  const handleChangeField = (field: string) => (newValue: string) => {
    const updatedElement = { ...element, [field]: newValue.trim() };
    onChange(updatedElement);
  };

  const editView = (
    <Box>
      <RichTextEditor value={element.text} onChange={handleTextChange} />
      <Box mt="s" display="flex" alignItems="center">
        <Text mr="s">Position:</Text>
        <Select
          value={element.position!}
          onChange={handleChangeField("position")}
          options={[
            { value: "left", label: "left" },
            { value: "center", label: "center" },
          ]}
        />
        <Box ml="s" display="flex" alignItems="center">
          <Text mr="s">color:</Text>
          <TextField
            value={element.bgColor}
            variant="outlined"
            size="small"
            onChange={(e) => handleChangeField("bgColor")(e.target.value)}
          />
        </Box>
      </Box>
    </Box>
  );

  const readView = (
    <Box
      width="100%"
      borderRadius={8}
      display="block"
      p="s"
      backgroundColor={element.bgColor === null ? "grey2" : element.bgColor}
    >
      <RichTextViewer html={element.text!} position={element.position} />
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
