import { gql } from "@apollo/client";
export type { ListAmityCommunities } from "./__generated__/ListAmityCommunities";

export const LIST_AMITY_COMMUNITIES_QUERY = gql`
  query ListAmityCommunities {
    listAmityCommunities {
      title
      amityCommunityId
    }
  }
`;
