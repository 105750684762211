import { gql } from "@apollo/client";
export type {
  CreateLessonMutation,
  CreateLessonMutationVariables,
} from "./__generated__/CreateLessonMutation";

export const CREATE_LESSON_MUTATION = gql`
  mutation CreateLessonMutation($moduleId: Float!) {
    createLesson(moduleId: $moduleId) {
      id
    }
  }
`;
