import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import TvIcon from "@material-ui/icons/Tv";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import PollIcon from "@material-ui/icons/Poll";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UserIcon from "@material-ui/icons/People";
import NewsIcon from "@material-ui/icons/FeaturedPlayList";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import RewardsManagementIcon from "@mui/icons-material/CardGiftcardOutlined";
import RewardsDeliveryIcon from "@mui/icons-material/LocalShippingOutlined";
import GradingIcon from "@mui/icons-material/Grading";
import AnnouncementIcon from "@mui/icons-material/Campaign";
import TagIcon from "@mui/icons-material/Style";
import RouteIcon from "@mui/icons-material/Route";
import Groups3Icon from '@mui/icons-material/Groups3';
import CommunityIcon from "@mui/icons-material/Forum";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Box, Text } from "ui";
import { useAppSelector } from "utils";
import { selectIsLoggedIn } from "state/user";
import { useState } from "react";

type MenuItem = {
  title: string;
  path: string;
  Icon: JSX.Element;
  default?: boolean;
  hidden?: boolean;
};

const menuItems: MenuItem[] = [
  {
    title: "Modules Management",
    path: "/content",
    Icon: <MenuBookIcon />,
    default: true,
  },
  { title: "Polls", path: "/polls", Icon: <PollIcon /> },
  { title: "Statistics", path: "/stats", Icon: <TrendingUpIcon /> },
  { title: "Reports", path: "/reports", Icon: <ListAltIcon /> },
  { title: "Users", path: "/users", Icon: <UserIcon /> },
  { title: "Leaderboard", path: "/leaderboard", Icon: <LeaderboardIcon /> },
  { title: "News", path: "/news", Icon: <NewsIcon /> },
  {
    title: "Announcements",
    path: "/announcements",
    Icon: <AnnouncementIcon />,
  },
  {
    title: "Rewards Management",
    path: "/rewards-management",
    Icon: <RewardsManagementIcon />,
  },
  {
    title: "Rewards Delivery",
    path: "/rewards-delivery",
    Icon: <RewardsDeliveryIcon />,
  },
  {
    title: "Search Terms",
    path: "/search-tags",
    Icon: <TagIcon />,
  },
  {
    title: "B2B",
    path: "/partnerships",
    Icon: <Groups3Icon />,
  },
  {
    title: "Path Quiz",
    path: "/path-quiz",
    Icon: <RouteIcon />,
    hidden: true,
  },
  {
    title: "Communities",
    path: "/communities",
    Icon: <CommunityIcon />,
    hidden: true,
  },
  {
    title: "Audit Logs",
    path: "/audit-logs",
    Icon: <GradingIcon />,
    hidden: true,
  },
  { title: "Juno's pick", path: "/pick", Icon: <TvIcon />, hidden: true },
];

export const Menu = () => {
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [showMore, setShowMore] = useState(false);

  if (!isLoggedIn)
    return (
      // vertically center the text
      <Box display="flex" flexDirection="column" alignItems="center" pt="l">
        <Text>
          please <Link to="/login">log in</Link> 👮‍♀️
        </Text>
      </Box>
    );

  return (
    <List>
      {menuItems
        .filter((item) => showMore || !item.hidden)
        .map((item, idx) => (
          <Box key={`menu${idx}`}>
            <ListItem
              button
              dense
              selected={
                location.pathname === item.path ||
                (item.default && location.pathname === "/")
              }
              onClick={() => {
                history.push(item.path);
              }}
              style={{ color: item.hidden ? "gray" : undefined }}
            >
              <ListItemAvatar>{item.Icon}</ListItemAvatar>
              <ListItemText>
                <ListItemText primary={item.title} />
              </ListItemText>
            </ListItem>
            <Divider />
          </Box>
        ))}
      <Box
        display="flex"
        justifyContent="center"
        style={{ cursor: "pointer" }}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "▲" : "…"}
      </Box>
    </List>
  );
};
