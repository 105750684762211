import { Box, Text, Select } from "ui";
import { Heading2Element } from "queries/richTextElementFragment";
import { RichTextEditor, RichTextViewer } from "components/rich-text";

export type Heading2Props = {
  element: Heading2Element;
  isEditMode: boolean;
  onChange: (element: Heading2Element) => void;
};

export const Heading2 = (props: Heading2Props) => {
  const { element, isEditMode, onChange } = props;

  const handleTextChange = (text: string) => {
    const updatedElement = { ...element, text: text.trim() };
    onChange(updatedElement);
  };

  const handleChangeField = (field: string) => (newValue: string) => {
    const updatedElement = { ...element, [field]: newValue };
    onChange(updatedElement);
  };

  const editView = (
    <Box>
      <RichTextEditor
        value={element.text}
        onChange={handleTextChange}
        preventsBold
      />{" "}
      <Box mt="s" display="flex" alignItems="center">
        <Text mr="s">Position:</Text>
        <Select
          value={element.position!}
          onChange={handleChangeField("position")}
          options={[
            { value: "left", label: "left" },
            { value: "center", label: "center" },
          ]}
        />
      </Box>
    </Box>
  );

  const readView = (
    <Box
      display="flex"
      justifyContent={element.position === "left" ? "flex-start" : "center"}
    >
      <RichTextViewer
        html={element.text!}
        textStyle={{
          fontSize: 20,
          fontWeight: "bold",
          textAlign: element.position || "left",
        }}
      />
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
