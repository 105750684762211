import { useEffect, useState } from "react";
import {
  createSearchTag,
  deleteSearchTag,
  listSearchTags,
  selectSearchTags,
  updateSearchTag,
} from "state/learning";
import { Box, Button, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

export const SearchTagsPage = () => {
  const dispatch = useAppDispatch();
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<string>();
  const [editMode, setEditMode] = useState<string>();
  const [newValue, setNewValue] = useState<string>();
  const [sorting, setSorting] = useState<number>();
  const [tagType, setTagType] = useState<"lesson" | "trending">("lesson");
  const searchTags = useAppSelector(selectSearchTags(tagType));

  useEffect(() => {
    dispatch(listSearchTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    setIsAddMode(false);
    setEditMode(undefined);
    setNewValue(undefined);
    setSorting(undefined);
    setDeleteMode(undefined);
  };

  const onSearchTagUpdate = (id: number) => {
    if (newValue && sorting !== undefined) {
      dispatch(updateSearchTag({ id, tag: newValue, sorting }));
      onCancel();
    }
  };

  const onCreateSearchTag = () => {
    if (newValue) {
      dispatch(createSearchTag({ tag: newValue, tagType }));
      onCancel();
    }
  };

  const onDelete = (id: number) => {
    onCancel();
    dispatch(deleteSearchTag({ id }));
  };

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      p="xl"
    >
      <Box display="flex">
        <Text
          size="large"
          mb="l"
          onClick={() => setTagType("lesson")}
          style={{
            textDecoration: tagType === "lesson" ? "underline" : "none",
            color: tagType === "lesson" ? "black" : "grey",
            cursor: "pointer",
          }}
        >
          Search Tags
        </Text>
        <Text
          size="large"
          mb="l"
          ml="l"
          onClick={() => setTagType("trending")}
          style={{
            textDecoration: tagType === "trending" ? "underline" : "none",
            color: tagType === "trending" ? "black" : "grey",
            cursor: "pointer",
          }}
        >
          Trending Searches
        </Text>
      </Box>
      <Box flexDirection="column" alignItems="center" flex="1">
        {searchTags.map((searchTag) => (
          <Box
            key={searchTag.id}
            flexDirection="row"
            alignItems="center"
            height={44}
            border="1px solid grey"
            borderRadius="10px"
            mt="s"
            p="s"
            width="390px"
          >
            {editMode !== searchTag.tag ? (
              <Box display="flex" mt="xxs" ml="s" alignItems="space-between">
                <Box flex={1}>
                  <Text pt="xs"> {searchTag.tag}</Text>
                </Box>
                <Box display="flex" alignItems="center">
                  {deleteMode === searchTag.tag ? (
                    <>
                      delete for real?
                      <Button
                        color="black"
                        backgroundColor="white"
                        width="20px"
                        onClick={() => onDelete(searchTag.id)}
                        title="yes. burn it."
                        label="🔥"
                      />
                      <Button
                        color="black"
                        backgroundColor="white"
                        width="20px"
                        onClick={() => setDeleteMode(undefined)}
                        title="no. i changed my mind. keep it."
                        label="❌"
                      />
                    </>
                  ) : (
                    <>
                      <Text size="small" color="grey">
                        #{searchTag.sorting}
                      </Text>
                      <Button
                        color="black"
                        backgroundColor="white"
                        width="20px"
                        onClick={() => {
                          setEditMode(searchTag.tag);
                          setNewValue(searchTag.tag);
                          setSorting(searchTag.sorting);
                          setIsAddMode(false);
                          setDeleteMode(undefined);
                        }}
                        label="✏️"
                      />
                      <Button
                        color="black"
                        backgroundColor="white"
                        width="20px"
                        onClick={() => {
                          setDeleteMode(searchTag.tag);
                          setEditMode(undefined);
                          setIsAddMode(false);
                        }}
                        title="get rid of this tag. forever."
                        label="🗑️"
                      />
                    </>
                  )}
                </Box>
              </Box>
            ) : (
              <Box display="flex">
                <input
                  type="text"
                  style={{ flex: 1 }}
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onSearchTagUpdate(searchTag.id);
                  }}
                />
                <input
                  type="number"
                  style={{ width: "30px", marginLeft: "10px" }}
                  value={sorting}
                  onChange={(e) => setSorting(parseInt(e.target.value))}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onSearchTagUpdate(searchTag.id);
                  }}
                />
                <Button
                  width={16}
                  color="black"
                  backgroundColor="white"
                  onClick={() => onSearchTagUpdate(searchTag.id)}
                  title="yeah, save this!!"
                  label="✅"
                />
                <Button
                  width={16}
                  color="black"
                  backgroundColor="white"
                  onClick={onCancel}
                  title="ah...never mind"
                  label="❌"
                />
              </Box>
            )}
          </Box>
        ))}
        <Box width={200} mt="l">
          {isAddMode && (
            <Box display="flex">
              <input
                type="text"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onCreateSearchTag();
                }}
              />
              <Button
                label="Save"
                ml="s"
                mr="s"
                disabled={!newValue}
                onClick={onCreateSearchTag}
              />
              <Button label="Cancel" onClick={() => setIsAddMode(false)} />
            </Box>
          )}
          {!isAddMode && (
            <Button
              label={`Add ${tagType === "lesson" ? "Tag" : "Search"}`}
              width={120}
              onClick={() => {
                setIsAddMode(true);
                setDeleteMode(undefined);
                setNewValue("");
                setEditMode(undefined);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
