import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "store";

import { loadStats } from "./thunks";

// Define a type for the slice state
interface StatsState {
  requestStatus: "idle" | "loading";
  globalStats: {
    users: number;
    lessonsCompleted: number;
    modulesCompleted: number;
    todosTaken: number;
    todos: { title: string; count: number }[];
  };
}

const initialState: StatsState = {
  requestStatus: "idle",
  globalStats: {
    users: 0,
    lessonsCompleted: 0,
    modulesCompleted: 0,
    todosTaken: 0,
    todos: [],
  },
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadStats.fulfilled, (state, action) => {
      state.requestStatus = "idle";
      state.globalStats = action.payload.stats;
    });
    builder.addCase(loadStats.pending, (state) => {
      state.requestStatus = "loading";
    });
  },
});

export const selectRequestStatus = (state: RootState) =>
  state.stats.requestStatus;
export const selectGlobalStats = (state: RootState) => state.stats.globalStats;

export default statsSlice.reducer;
