import { createElement } from "react";
import { useAppSelector, useAppDispatch } from "utils/hooks";
import { Box, Button, theme } from "ui";
import styled from "styled-components";
import {
  deleteCard,
  getLessonDetails,
  selectCurrCard,
  selectCurrLessonId,
} from "state/learning";
import {
  RichTextCard,
  VideoCard,
  QuizCard,
  PollCard,
  ActionItemCard,
  SurveyCard,
} from "./cards";

const cardsComponent = {
  richtext: RichTextCard,
  video: VideoCard,
  actionitem: ActionItemCard,
  quiz: QuizCard,
  poll: PollCard,
  survey: SurveyCard,
};

const CardContainer = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0 0 12px rgb(0 0 0 / 20%);
`;

const VisiblitySeparator = styled(Box)`
  position: absolute;
  border-bottom: 4px dotted lightgrey;
  width: 100%;
  pointerevents: none;
`;

export const CardEditor = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);
  const currLessonId = useAppSelector(selectCurrLessonId);

  if (!currCard) {
    return <></>;
  }

  const onDeleteCard = async () => {
    const cardId = currCard.id;
    await dispatch(deleteCard({ cardId }));
    await dispatch(getLessonDetails({ lessonId: currLessonId! }));
  };

  return (
    <Box>
      <CardContainer
        showScroll
        width={830 / 2.5}
        height={1792 / 3 + 180}
        mt="s"
        borderRadius={8}
        backgroundColor={theme.colors.card}
        scrollbarWidth="none"
        position="relative"
      >
        <Box px="m" py="m">
          {createElement(cardsComponent[currCard.__typename])}
        </Box>
        <VisiblitySeparator
          top={
            currCard.__typename === "actionitem"
              ? "680px"
              : ["quiz", "survey", "poll"].includes(currCard.__typename)
              ? "730px"
              : "650px"
          }
        />
      </CardContainer>
      <Box mt="l">
        <Button
          label="Delete Card"
          onClick={onDeleteCard}
          backgroundColor="red"
        />
      </Box>
    </Box>
  );
};
