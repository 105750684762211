import { RootState } from "store";

export const selectPartnerships = (state: RootState) =>
  state.partnership.partnerships;

export const selectPartnershipLoading = (state: RootState) => ({
  listLoading: state.partnership.listLoading,
  upsertLoading: state.partnership.upsertLoading,
  connectModuleLoading: state.partnership.connectModuleLoading,
});

export const selectPartnershipError = (state: RootState) =>
  state.partnership.error;

export const selectCurrentPartnership = (state: RootState) =>
  state.partnership.partnerships.find(
    (partnership) => partnership.id === state.partnership.selectedPartnershipId
  );

export const selectSelectedPartnershipId = (state: RootState) =>
  state.partnership.selectedPartnershipId;
