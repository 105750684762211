import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { startCase } from "lodash";
import { FC, useState } from "react";
import { Box, Text } from "ui";

type Props = {
  status: string;
  options?: string[];
  onChange?: (value: string) => any;
};

const colors: { [key: string]: string } = {
  // free: "#B8C5FC", // Blue
  cancelled: "#FCB8C0", // Red
  binary: "#FCB8C0", // Red
  // binary: "#FFEEED", // Rosé
  delivered: "#CDEC85", // Limegreen
  automaticallyDelivered: "#CDEC85", // Limegreen
  complication: "#FFFCBA", // Yellow
  selection: "#31d0b8", // Turquoise
};

export const StatusBadge: FC<Props> = ({ status, options, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const bgColor = colors[status] || colors.cancelled;

  return (
    <Box
      borderRadius="8px"
      backgroundColor={bgColor}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      width={options ? "132px" : "112px"}
      p="xs"
      cursor={options ? "pointer" : undefined}
    >
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        style={{ cursor: options ? "pointer" : undefined }}
      >
        <Box flexGrow={1} display="flex" justifyContent="center">
          <Text
            size="small"
            ml={options ? "s" : undefined}
            mr={options ? "-8px" : undefined}
          >
            {startCase(status)}
          </Text>
        </Box>
        {options && <KeyboardArrowDown />}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options?.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              handleClose();
              onChange && onChange(option);
            }}
          >
            {startCase(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
