import {
  UserModulesQuery,
  UserModulesQueryVariables,
  USER_MODULES_QUERY,
} from "queries/user/userModulesQuery";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";
import {
  AuthenticateMutation,
  AuthenticateMutationVariables,
  AUTH_MUTATION,
} from "queries/user/authenticate";
import {
  GIFT_AVOCADOS_MUTATION,
  GiftAvocadosMutation,
  GiftAvocadosMutationVariables,
} from "queries/user/giftAvocadosMutation";
import {
  TOGGLE_USER_ADMIN_MUTATION,
  ToggleUserAdminMutation,
  ToggleUserAdminMutationVariables,
} from "queries/user/toggleUserAdmin";

export const listUserModules = createQueryThunk<
  UserModulesQuery,
  UserModulesQueryVariables
>("user/modules", USER_MODULES_QUERY);

export const authenticate = createMutationThunk<
  AuthenticateMutation,
  AuthenticateMutationVariables
>("user/authenticate", AUTH_MUTATION);

export const giftAvocados = createMutationThunk<
  GiftAvocadosMutation,
  GiftAvocadosMutationVariables
>("user/giftAvocados", GIFT_AVOCADOS_MUTATION);

export const toggleUserAdmin = createMutationThunk<
  ToggleUserAdminMutation,
  ToggleUserAdminMutationVariables
>("user/toggleUserAdmin", TOGGLE_USER_ADMIN_MUTATION);
