import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect } from "react";
import dayjs from "dayjs";

import { Text, Box, Button } from "ui";
import {
  MediaListQuery,
  MediaListQueryVariables,
  MEDIA_LIST_QUERY,
} from "queries/mediaListQuery";
import { CardElement } from "queries/richTextElementFragment";
import { apolloClient } from "utils/apolloClient";

export const NewsPage = () => {
  const { data: newsItems, refetch } = useQuery<
    MediaListQuery,
    MediaListQueryVariables
  >(MEDIA_LIST_QUERY, { client: apolloClient, variables: { key: "news" } });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Text size="large" mb="m">
        News
      </Text>
      <Link
        to={"/news/create"}
        style={{ width: "190px", textDecoration: "none" }}
      >
        <Button label="🖋 Write something new" />
      </Link>
      <Box mt="s">
        {newsItems?.listMedia.map((newsItem) => (
          <Link
            to={`/news/edit/${newsItem.id}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Text weight="bold" mt="m">
              {_.truncate(
                newsItem.json.title ||
                  newsItem.json.elements
                    .filter((e: CardElement) =>
                      /heading|paragraph/.test(e.__typename)
                    )[0]
                    ?.text.replace(/(<([^>]+)>)/gi, "")
                    .replaceAll("&nbsp;", ""),
                { length: 100, omission: "..." }
              )}
            </Text>
            <Text>{dayjs(newsItem.createdAt).format("DD.MM.YYYY HH:mm")}</Text>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
