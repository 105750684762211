import { useEffect } from "react";
import { Box, Select } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import {
  listPartnerships,
  selectPartnerships,
  selectSelectedPartnershipId,
  setSelectedPartnershipId,
} from "state/partnership";

export const PartnershipFilter = () => {
  const dispatch = useAppDispatch();
  const selectedPartnershipId = useAppSelector(selectSelectedPartnershipId);
  const partnerships = useAppSelector(selectPartnerships);

  useEffect(() => {
    // fetch partnerships if there is none
    if (!partnerships?.length) {
      dispatch(listPartnerships());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width={300} maxWidth={300} display="flex" flexDirection="top">
      <Select
        label="Filter by Partnership"
        value={selectedPartnershipId ? `${selectedPartnershipId}` : "juno"}
        options={[
          { value: "juno", label: "Juno" },
          ...(partnerships ?? []).map((p) => ({
            value: `${p.id}`,
            label: `${p.title} (${p.internalName})`,
          })),
        ]}
        onChange={(value) =>
          dispatch(
            setSelectedPartnershipId(
              value !== "juno" ? parseInt(value, 10) : undefined
            ) as any
          )
        }
      />
    </Box>
  );
};
