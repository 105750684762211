import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "./partnershipFragment";
export type {
  ConnectPartnershipModule,
  ConnectPartnershipModuleVariables,
} from "./__generated__/ConnectPartnershipModule";

export const CONNECT_PARTNERSHIP_MODULE_MUTATION = gql`
  ${PARTNERSHIP_FRAGMENT}
  mutation ConnectPartnershipModule($partnershipId: Float!, $moduleId: Float!) {
    connectModule(partnershipId: $partnershipId, moduleId: $moduleId) {
      ...PartnershipFragment
    }
  }
`;
