import { createSlice } from "@reduxjs/toolkit";
import { ListAnnouncements } from "queries/listAnnouncements";
import { listAnnouncements, upsertAnnouncement } from "./thunks";
import { sortBy } from "lodash";

interface CommunicationState {
  requestStatus: "idle" | "loading";
  announcements: ListAnnouncements["announcements"];
}

const initialState: CommunicationState = {
  requestStatus: "idle",
  announcements: [],
};

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(upsertAnnouncement.fulfilled, (state, action) => {
      const index = state.announcements.findIndex(
        (announcement) =>
          announcement.id === action.payload.upsertAnnouncement.id
      );
      if (index === -1) {
        state.announcements.push(action.payload.upsertAnnouncement);
      } else {
        state.announcements[index] = action.payload.upsertAnnouncement;
      }
    });
    builder.addCase(listAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload.announcements;
    });
    // set requestStatus to loading when either of these thunks are pending
    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state) => {
        state.requestStatus = "loading";
      }
    );
    // set requestStatus to idle when either of these thunks are fulfilled
    builder.addMatcher(
      (action) => action.type.endsWith("/fulfilled"),
      (state) => {
        state.requestStatus = "idle";
      }
    );
  },
});

export const selectRequestStatus = (state: {
  communication: CommunicationState;
}) => state.communication.requestStatus;

// sorted by start date, descending
export const selectAnnouncements = (state: {
  communication: CommunicationState;
}) => sortBy(state.communication.announcements, "startDate").reverse();

export const selectAnnouncement =
  (id: number) => (state: { communication: CommunicationState }) =>
    state.communication.announcements.find(
      (announcement) => announcement.id === id
    );

export default communicationSlice.reducer;
