import { gql } from "@apollo/client";
import { REWARD_ACTIVITY_FRAGMENT } from "./rewardActivityFragment";
export type {
  ListRewardActivities,
  ListRewardActivitiesVariables,
} from "./__generated__/ListRewardActivities";

export const LIST_REWARD_ACTIVITIES = gql`
  ${REWARD_ACTIVITY_FRAGMENT}
  query ListRewardActivities(
    $status: String
    $offset: Float
    $limit: Float
    $search: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    rewardActions(
      status: $status
      offset: $offset
      limit: $limit
      search: $search
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        ...RewardActivityFragment
      }
      total
      limit
      offset
    }
    rewardActivityCount {
      all
      requested
      complication
      delivered
      automaticallyDelivered
    }
  }
`;
