import MaterialTable from "material-table";

type ToDosTableProps = {
  todos: { title: string; count: number }[];
  isLoading: boolean;
};
export const ToDosTable = (props: ToDosTableProps) => {
  const { todos, isLoading } = props;

  return (
    <MaterialTable
      data={todos}
      columns={[
        { title: "To-Do", field: "title", headerStyle: { fontWeight: 700 } },
        {
          title: "Completed",
          field: "count",
          defaultSort: "desc",
          headerStyle: { fontWeight: 700 },
          width: "35%",
        },
      ]}
      options={{
        search: false,
        grouping: false,
        showTitle: false,
        toolbar: false,
        rowStyle: {
          overflowWrap: "break-word",
        },
      }}
      isLoading={isLoading}
      // components={{
      //   Header: () => <Box height={0} />,
      // }}
    />
  );
};
