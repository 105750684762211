import { gql } from "@apollo/client";
export type { RewardsFragment } from "./__generated__/RewardsFragment";

export const REWARDS_FRAGMENT = gql`
  fragment RewardsFragment on RewardOutput {
    id
    sorting
    title
    about
    rewardType
    brand
    target
    tag
    link
    linkTitle
    logoUrl
    carousel
    allocation
    price
    participants
    raised
    displayDate
    autoDelivery
    endDate
    publishedAt
    createdAt
    updatedAt
    emailInfo
    partnershipId
  }
`;
