import HTMLRenderer from "react-html-renderer";
import { Box, Text, theme } from "ui";

export const RichTextViewer = (props) => {
  const { html, position, textStyle } = props;

  return html.split("\n").map((paragraph, idx) => (
    <Box mt={idx > 0 ? "m" : undefined}>
      <HTMLRenderer
        html={paragraph}
        components={{
          p: (props) => (
            <Text
              textAlign={position === "center" ? "center" : "left"}
              {...textStyle}
              {...props}
            />
          ),
          u: (props) => (
            <span
              style={{
                textDecoration: "underline",
                textDecorationColor: theme.colors.green,
              }}
              {...props}
            />
          ),
        }}
      />
    </Box>
  ));
};
