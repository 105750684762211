import {
  CommunityPollStatisticsQuery,
  CommunityPollStatisticsQueryVariables,
  COMMUNITY_POLL_STATISTICS_QUERY,
} from "queries/communityPollStatisticsQuery";
import { ListPollsQuery, LIST_POLLS_QUERY } from "queries/listPollsQuery";
import {
  UpsertPollMutation,
  UpsertPollMutationVariables,
  UPSERT_POLL_MUTATION,
} from "queries/upsertPollMutation";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";

export const upsertPoll = createMutationThunk<
  UpsertPollMutation,
  UpsertPollMutationVariables
>("polls/upsertPoll", UPSERT_POLL_MUTATION);

export const listPolls = createMutationThunk<ListPollsQuery>(
  "polls/listPolls",
  LIST_POLLS_QUERY
);

export const getCommunityPollStatistics = createQueryThunk<
  CommunityPollStatisticsQuery,
  CommunityPollStatisticsQueryVariables
>("feedback/pollStatistics", COMMUNITY_POLL_STATISTICS_QUERY);
