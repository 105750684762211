import { useState } from "react";
import ReactPlayer from "react-player";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, ProgressBar, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import {
  uploadFile,
  selectRequestProgress,
  selectCurrCard,
  updateCard,
} from "state/learning";
import { VideoCard as VideoCardType } from "queries/learning/lessonDetails";
import { cloneDeep } from "lodash";

export const VideoCard = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);

  const requestProgress = useAppSelector(selectRequestProgress);
  const [uploading, setUploading] = useState(false);

  const { uri } = currCard as VideoCardType;

  const videoPlaybackId = uri
    ? uri.substring(uri.lastIndexOf("/") + 1, uri.lastIndexOf("."))
    : "";

  const uploadVideo = async (file: File) => {
    setUploading(true);
    try {
      const result = await dispatch(uploadFile({ file }));
      const videoUrl = result.payload.singleUpload.uri;
      const content = cloneDeep(currCard! as VideoCardType);
      content.uri = videoUrl!;
      const card = { id: currCard!.id, content };
      dispatch(updateCard({ card }));
    } catch (e) {
      console.log(e);
    }
    setUploading(false);
  };

  const deleteVideo = () => {
    const content = cloneDeep(currCard! as VideoCardType);
    content.uri = "";
    const card = { id: currCard!.id, content };
    dispatch(updateCard({ card }));
  };

  return (
    <Box>
      {(currCard as VideoCardType).uri && (
        <Box>
          <ReactPlayer
            url={(currCard as VideoCardType).uri}
            controls
            width={300}
            height={500}
            volume={1}
          />
        </Box>
      )}
      <Box mt="m" display="flex" justifyContent="space-between">
        <input
          type="file"
          onChange={(event) => {
            if (!event.target.files?.length) {
              return;
            }
            const file = event.target.files[0];
            uploadVideo(file);
          }}
        />
        <Box hovered onClick={deleteVideo}>
          <DeleteIcon color="secondary" />
        </Box>
      </Box>
      {uploading && requestProgress && (
        <Box mt="l">
          <ProgressBar value={requestProgress * 100} />
        </Box>
      )}
      <Box>
        <Text color="grey" size="tiny" mt="xl">
          {videoPlaybackId}
        </Text>
      </Box>
    </Box>
  );
};
