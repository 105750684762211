import { useQuery } from "@apollo/client";
import { SearchField } from "components/SearchField";
import {
  AuditLogQuery,
  AuditLogQueryVariables,
  AUDITLOG_QUERY,
} from "queries/auditLogQuery";
import { useEffect, useState } from "react";
import { Box, Text } from "ui";
import { apolloClient } from "utils/apolloClient";

export const AuditLogPage = () => {
  const [search, setSearch] = useState("");
  const [entries, setEntries] = useState<AuditLogQuery["auditLogs"]["entries"]>(
    []
  );
  const { refetch } = useQuery<AuditLogQuery, AuditLogQueryVariables>(
    AUDITLOG_QUERY,
    {
      client: apolloClient,
      skip: true,
    }
  );

  useEffect(() => {
    refetch({ search: search || null }).then((res) => {
      setEntries(res.data.auditLogs.entries);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex">
        <Box flex={1} display="flex" flexDirection="column">
          <SearchField
            value={search}
            onChange={setSearch}
            placeholder="search term"
          />
          <Box>
            {entries.map((entry) => (
              <Box display="flex" flexDirection="row">
                <Text fontWeight="bold" size="tiny">
                  {entry.userName ? `[${entry.userName}]` : ""}
                </Text>
                <Text size="tiny">{entry.text}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
