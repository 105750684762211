import { Box, Text, theme } from "ui";

type Props = {
  label: string;
  value: string | number;
  size?: number;
  type?: string;
  onChange: (value: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  style?: Object;
  error?: string;
  required?: boolean;
  multiline?: boolean;
  title?: string;
};

export const InputField = (props: Props) => {
  return (
    <Box mb="xxs" display="flex" title={props.title || props.placeholder}>
      <label style={{ display: "flex" }}>
        <Text
          width={150}
          color={props.required && !props.value ? "red" : undefined}
        >
          {props.label}
          {props.required ? "*" : ""}:&nbsp;
        </Text>
        {!props.multiline ? (
          <input
            value={props.value}
            size={props.size || 30}
            type={props.type || "text"}
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.onSave}
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                props.onCancel && props.onCancel();
              }
              if (e.key === "Enter") {
                props.onSave && props.onSave();
              }
            }}
          />
        ) : (
          <textarea
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.onSave}
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                props.onCancel && props.onCancel();
              }
            }}
            style={{
              width: 300,
              height: 100,
              fontFamily: theme.fontFamily.primary,
            }}
          >
            {props.value}
          </textarea>
        )}
      </label>
    </Box>
  );
};
