import { Link, useHistory, useParams } from "react-router-dom";
import { Box, Text, Button } from "ui";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useAppDispatch, useAppSelector } from "utils";
import {
  listPathQuizzes,
  loadModulesList,
  selectAvailablePathQuizModules,
  selectPathQuiz,
  upsertPathQuiz,
} from "state/learning";
import { Tooltip } from "@mui/material";
import { omit } from "lodash";
import { PathQuizAnswerEdit } from "components/path-quiz/PathQuizAnswerEdit";

const typeOptions = [
  { value: "selection", label: "Selection" },
  { value: "binary", label: "Binary" },
];

const emptyAnswers = ["", "", ""].map((title) => ({
  id: null as number | null,
  title,
  moduleIds: [] as number[],
}));

export const PathQuizEditPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams<{ pathQuizId: string }>();
  const pathQuizId = params.pathQuizId ? parseInt(params.pathQuizId, 10) : null;
  const isNew = params.pathQuizId === "new" || !pathQuizId;
  const modules = useAppSelector(selectAvailablePathQuizModules(pathQuizId));
  const pathQuiz = useAppSelector(selectPathQuiz(pathQuizId));
  const [quizType, setQuizType] = useState(pathQuiz?.type || "");
  const [title, setTitle] = useState(pathQuiz?.title);
  const [answers, setAnswers] = useState(pathQuiz?.answers || emptyAnswers);
  const allAnserModules = answers.flatMap((a) => a.moduleIds);

  useEffect(() => {
    // load pathquizzes if not present but not isNew
    if (!isNew && !pathQuiz) {
      dispatch(listPathQuizzes());
      console.log("listing path quizzes");
    }
    // load modules list if empty
    if (modules.length === 0) {
      dispatch(loadModulesList());
      console.log("loading modules list");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveQuiz = async () => {
    // clean up empty entries and props from graphql
    const newAnswers = answers
      .filter((a) => !!a)
      .map((a) => omit(a, "__typename"));
    if (!title) {
      alert("Please enter a title");
      return;
    }
    if (!quizType) {
      alert("Please select a type");
      return;
    }
    // make sure when type is binary, that there are only 2 answers
    if (quizType === "binary" && newAnswers.length !== 2) {
      newAnswers.splice(2, newAnswers.length - 2);
    }
    const newQuiz = {
      id: pathQuizId,
      title,
      type: quizType,
      answers: newAnswers,
    };
    await dispatch(upsertPathQuiz({ quiz: newQuiz }));
    history.push("/path-quiz");
  };

  const udpateAnswer = (index: number, title: string, moduleIds: number[]) => {
    setAnswers((answers) => {
      const newAnswers = [...answers];
      newAnswers[index] = { id: answers[index]?.id, title, moduleIds };
      return newAnswers;
    });
  };

  const appendAnswer = () => {
    setAnswers((answers) => [
      ...answers,
      { id: null, title: "", moduleIds: [] },
    ]);
  };

  const removeAnswer = (index: number) => {
    setAnswers((answers) => {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      return newAnswers;
    });
  };

  return (
    <Box my="l" mx="xl" pb="xl">
      <Box mb="s">
        <Link to="/path-quiz" style={{ color: "#393939" }}>
          <ArrowBackIosIcon />
        </Link>
      </Box>
      <h1>{isNew ? "Create" : "Edit"} quiz</h1>
      <h2>Questions</h2>
      <Box mb="m">
        <Box mb="m">
          <Text weight="bold" mb="s">
            Question Title
          </Text>{" "}
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            size={100}
          />
        </Box>
        <Box mb="m">
          <Text weight="bold" mb="s">
            Type
          </Text>
          <select
            disabled={!isNew}
            value={quizType}
            onChange={(e) => setQuizType(e.target.value)}
          >
            <option value="" disabled>
              Select a type
            </option>
            {typeOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Box>
        {quizType === "binary" && (
          <>
            <PathQuizAnswerEdit
              answer={answers[0] || {}}
              disabled={isNew}
              textLabel="Answer 1: Yes 👍"
              moduleLabel="Associated modules to answer 1"
              modules={modules}
              onChange={(answer) =>
                udpateAnswer(0, answer.title, answer.moduleIds)
              }
              allAnserModules={allAnserModules}
            />
            <PathQuizAnswerEdit
              answer={answers[1] || {}}
              disabled={isNew}
              textLabel="Answer 2: No 👎"
              moduleLabel="Associated modules to answer 2"
              modules={modules}
              onChange={(answer) =>
                udpateAnswer(1, answer.title, answer.moduleIds)
              }
              allAnserModules={allAnserModules}
            />
          </>
        )}
        {quizType === "selection" &&
          answers.map((answer, index) => (
            <Box mb="l" key={index}>
              <Box display="flex">
                <PathQuizAnswerEdit
                  answer={answer}
                  disabled={isNew}
                  textLabel={`Answer ${index + 1}`}
                  moduleLabel={`Associated modules to answer ${index + 1}`}
                  modules={modules}
                  onChange={(answer) =>
                    udpateAnswer(index, answer.title, answer.moduleIds)
                  }
                  allAnserModules={allAnserModules}
                />
                <Tooltip title="Remove this answer">
                  <Text
                    style={{ cursor: "pointer" }}
                    ml="s"
                    onClick={() => removeAnswer(index)}
                  >
                    🗑️
                  </Text>
                </Tooltip>
              </Box>
            </Box>
          ))}
        {quizType === "selection" && (
          <Box display="flex">
            <Button
              label="Add answer"
              disabled={isNew}
              onClick={appendAnswer}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row">
        <Button
          backgroundColor="primary"
          label={isNew ? "Create" : "Save"}
          onClick={saveQuiz}
        />
      </Box>
    </Box>
  );
};
