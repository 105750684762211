import React from "react";
import { EditorState, getDefaultKeyBinding, RichUtils } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import { theme, Button } from "ui";

import "./RichTextEditor.css";

import "../../../../node_modules/draft-js/dist/Draft.css";
import "../../../../node_modules/@draft-js-plugins/emoji/lib/plugin.css";

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

// props:
// value: string (HTML)
// onChange: (html:string)=>void;
// preventsBold: boolean;

export class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        stateFromHTML(this.props.value)
      ),
      commandKeyPressed: false,
    };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      this.setState({ editorState });
      this.props.onChange(stateToHTML(editorState.getCurrentContent()));
    };

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    // prevent italic
    if (e.keyCode === 73 && e.metaKey) {
      return "handled";
    }

    // prevent bold if required
    if (e.keyCode === 66 && e.metaKey && this.props.preventsBold) {
      return "handled";
    }

    //prevent tab
    if (e.keyCode === 9 /* TAB */) {
      return "handled";
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  render() {
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    const inlineStyles = !this.props.preventsBold
      ? [{ label: "B", style: "BOLD" }]
      : [];
    // inlineStyles.push({ label: "I", style: "ITALIC" });
    inlineStyles.push({ label: "U", style: "UNDERLINE" });

    return (
      <>
        <div className="RichEditor-root">
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
            inlineStyles={inlineStyles}
          />
          <div className={className} onClick={this.focus}>
            <Editor
              // handleReturn={() => "handled"}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.onChange}
              ref="editor"
              spellCheck={true}
              plugins={[emojiPlugin]}
            />
            <EmojiSuggestions />
            <EmojiSelect />
          </div>
        </div>
      </>
    );
  }
}

const styleMap = {
  UNDERLINE: {
    textDecoration: "underline",
    textDecorationColor: theme.colors.green,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      if (e !== undefined) {
        e.preventDefault();
      }
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    return (
      <Button
        ml="s"
        label={this.props.label}
        onClick={this.onToggle}
        backgroundColor={this.props.active ? "black" : "white"}
        textProps={{ color: this.props.active ? "white" : "black" }}
      />
    );
  }
}

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {props.inlineStyles.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
