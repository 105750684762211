import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  MenuItem,
} from "@material-ui/core";

type Option = { value: string; label: string } | string;
type SelectProps = {
  value?: string;
  onChange: (newValue: string) => void;
  options: Option[];
  label?: string;
  disabled?: boolean;
};

export const Select = (props: SelectProps) => {
  const { value, onChange, options } = props;

  return (
    <FormControl fullWidth>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <MaterialSelect
        value={value}
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        disabled={props.disabled}
      >
        {options.map((option: Option) => {
          if (typeof option !== "string") {
            return (
              <MenuItem value={option.value} key={option.value}>
                {option.label || option.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            );
          }
        })}
      </MaterialSelect>
    </FormControl>
  );
};
