import { gql } from "@apollo/client";
export type {
  ToggleUserAdminMutation,
  ToggleUserAdminMutationVariables,
} from "./__generated__/ToggleUserAdminMutation";

export const TOGGLE_USER_ADMIN_MUTATION = gql`
  mutation ToggleUserAdminMutation($userId: Float!) {
    toggleUserAdmin(userId: $userId) {
      id
      role
    }
  }
`;
