import { gql } from "@apollo/client";

export type {
  UpsertPollMutation,
  UpsertPollMutationVariables,
} from "./__generated__/UpsertPollMutation";

export const UPSERT_POLL_MUTATION = gql`
  mutation UpsertPollMutation($poll: FeedbackRequest!) {
    upsertPoll(feedbackRequest: $poll)
  }
`;
