import { useState } from "react";
import { Box, Text, Card, RadioButtonsGroup, Button, ProgressBar } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import { selectRequestProgress, addMedia, uploadFile } from "state/learning";

export const JunoPick = () => {
  const [mediaType, setMediaType] = useState("video");
  const [file, setFile] = useState<File | undefined>(undefined);
  const requestProgress = useAppSelector(selectRequestProgress);
  const [uploading, setUploading] = useState(false);
  const dispatch = useAppDispatch();

  const handleUploadFile = async () => {
    setUploading(true);
    if (!file || !mediaType) {
      return;
    }

    try {
      const result = await dispatch(uploadFile({ file }));
      const videoUrl = result.payload.singleUpload.uri;
      if (!videoUrl) {
        return;
      }
      await dispatch(
        addMedia({ key: "junosPick", content: videoUrl, type: mediaType })
      );
    } catch (e) {
      console.log(e);
    }
    setUploading(false);
  };

  return (
    <Box
      width="100%"
      pt={100}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        width={400}
        height={300}
        p="l"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text size="large">Juno's Pick</Text>
        <Text mt="s">Upload a video or an image </Text>
        <Box pt="l">
          <RadioButtonsGroup
            options={[
              { label: "Video", value: "video" },
              { label: "Image", value: "image" },
            ]}
            value={mediaType}
            onChange={setMediaType}
          />
        </Box>
        <Box pt="xl" display="flex" justifyContent="center">
          <input
            type="file"
            onChange={(event) => {
              if (!event.target.files?.length) {
                return;
              }
              setFile(event.target.files[0]);
            }}
          />
        </Box>
        <Button
          mt="l"
          label="Upload"
          onClick={handleUploadFile}
          disabled={uploading}
        />
        {uploading && requestProgress && (
          <Box width={200} height={10}>
            <Box mt="l">
              <ProgressBar value={requestProgress * 100} />
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
};
