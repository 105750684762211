type Props = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => any;
};

export const SearchField = (props: Props) => {
  return (
    <input
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      style={{
        border: "1px solid #C8C8C8",
        borderRadius: "24px",
        fontSize: "16px",
        padding: "10px 20px",
        marginBottom: "26px",
      }}
      placeholder={props.placeholder}
      autoComplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
  );
};
