import { useParams } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useHistory } from "react-router-dom";
import {
  deleteReward,
  getReward,
  publishReward,
  selectReward,
  selectRewardTypeFilter,
  unpublishReward,
  upsertReward,
} from "state/reward";
import { Box, Button, Select, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";
import { useEffect, useState } from "react";
import { InputField } from "components/InputField";
import { ImageUpload } from "components/ImageUpload";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import { Alert, Snackbar } from "@mui/material";
import { listPartnerships, selectCurrentPartnership } from "state/partnership";

const DATE_FORMAT = "dd/MM/yyyy";

export const RewardsManagementDetails = () => {
  const { rewardId } = useParams<{ rewardId: string }>();
  const rewardTypeFilter = useAppSelector(selectRewardTypeFilter);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [tag, setTag] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [link, setLink] = useState("");
  const [about, setAbout] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [target, setTarget] = useState(0);
  const [carousel, setCarousel] = useState<string[]>([]);
  const [emailInfo, setEmailInfo] = useState("");
  const [price, setPrice] = useState(0);
  const [allocation, setAllocation] = useState(0);
  const [endDate, setEndDate] = useState<Date>();
  const [displayDate, setDisplayDate] = useState(false);
  const [rewardType, setRewardType] = useState<string>();
  const [autoDelivery, setAutoDelivery] = useState(false);
  const [isShop, setIsShop] = useState(false);
  const [isCharity, setIsCharity] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const reward = useAppSelector(selectReward(parseInt(rewardId, 10)));
  const dispatch = useAppDispatch();

  const isNew = !reward?.id;
  const partnership = useAppSelector(selectCurrentPartnership);

  useEffect(() => {
    setIsShop(rewardType === "SHOP");
    setIsCharity(rewardType === "CHARITY");
    // charities always have auto delivery turned on
    if (rewardType === "CHARITY") {
      setAutoDelivery(true);
    }
  }, [rewardType]);

  useEffect(() => {
    if (!reward && rewardId) {
      dispatch(getReward({ id: parseInt(rewardId, 10) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardId, reward]);

  useEffect(() => {
    if (!reward) return;
    setTitle(reward.title!);
    setBrand(reward.brand!);
    setTag(reward.tag!);
    setLinkTitle(reward.linkTitle!);
    setLink(reward.link!);
    setAbout(reward.about!);
    setLogoUrl(reward.logoUrl!);
    setTarget(reward.target!);
    setCarousel(reward.carousel || []);
    setEmailInfo(reward.emailInfo!);
    setPrice(reward.price || 0);
    setAllocation(reward.allocation || 0);
    setEndDate(reward.endDate ? new Date(reward.endDate) : undefined);
    setDisplayDate(reward.displayDate);
    setRewardType(reward.rewardType);
    setAutoDelivery(reward.rewardType === "CHARITY" || reward.autoDelivery);
  }, [reward]);

  // useEffect change set reward type to rewardTypeFilter  if not set
  useEffect(() => {
    if (!rewardType && rewardTypeFilter) {
      setRewardType(rewardTypeFilter);
    }
  }, [rewardType, rewardTypeFilter]);

  // load partnership list if not loaded
  useEffect(() => {
    if (!partnership && reward?.partnershipId) {
      dispatch(listPartnerships());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = async () => {
    if (!rewardType) return;
    return dispatch(
      upsertReward({
        reward: {
          id: reward?.id,
          title,
          brand,
          tag,
          link,
          linkTitle,
          about,
          logoUrl,
          target,
          carousel,
          emailInfo,
          price,
          allocation,
          endDate,
          displayDate,
          rewardType,
          autoDelivery,
          partnershipId: reward?.partnershipId ?? partnership?.id,
        },
      })
    );
  };

  const onSave = async () => {
    if (!rewardType) {
      setError('Please select a "Reward Type"');
      setLoading(false);
      return;
    }
    setLoading(true);
    setError("");
    const res = await save();
    setLoading(false);
    if (!res) return;
    if (res.payload.upsertReward?.id) {
      setSuccess(true);
      history.push(`/rewards-management/${res.payload.upsertReward.id}`);
      return;
    }
    setError(res.payload.error || "There was an error saving the reward.");
  };

  const onDelete = async () => {
    if (!reward) return;
    dispatch(deleteReward({ id: reward.id }));
    history.push("/rewards-management");
  };

  const valid = () => {
    const generalInfo =
      title &&
      brand &&
      emailInfo &&
      linkTitle &&
      link &&
      about &&
      logoUrl &&
      carousel.filter((c) => !!c).length >= 3;
    if (isShop) return generalInfo && price && allocation;
    if (isCharity) return generalInfo && target;
  };

  const onPublish = async () => {
    if (!reward) return;
    setLoading(true);
    await save();
    await dispatch(publishReward({ id: reward.id }));
    setLoading(false);
  };
  const onUnpublish = async () => {
    if (!reward) return;
    await save();
    await dispatch(unpublishReward({ id: reward.id }));
  };

  if (!reward && !!rewardId) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box mb="s" display="flex">
        <Link to="/rewards-management" style={{ color: "#393939" }}>
          <ArrowBackIosIcon />
        </Link>
        <Box>
          <Text size="large" mb="m">
            {reward?.title}
          </Text>
          {((isNew && partnership) || reward?.partnershipId) && (
            <Text mb="l" size="small">
              Challenge: {partnership?.title} ({partnership?.internalName})
            </Text>
          )}
        </Box>
      </Box>
      {error && <Text color="error">{error}</Text>}
      <Box mb="s" display="flex" alignItems="center">
        <Text mr="l">
          Reward Type: {(isShop && "Shop") || (isCharity && "Donate") || ""}
        </Text>
        {isNew && !rewardType && (
          <Select
            value={rewardType}
            options={[
              { value: "SHOP", label: "Shop" },
              { value: "CHARITY", label: "Donate" },
            ]}
            onChange={setRewardType}
          />
        )}
      </Box>
      <Box mb="s" display="flex">
        <Text>
          Date created: {dayjs(reward?.createdAt).format("DD/MM/YYYY")}
        </Text>
        {isCharity && (
          <>
            <Text ml="l">Amount raised: {reward?.raised || 0}</Text>
            <Text ml="l">Donors: {reward?.participants || 0}</Text>
          </>
        )}
        {isShop && (
          <>
            <Text ml="l">Redeemed: {reward?.participants || 0}</Text>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
        <InputField
          label="Brand"
          value={brand}
          onChange={setBrand}
          required
          placeholder="Brand"
        />
        {isCharity && (
          <InputField
            type="number"
            label="Target"
            required
            value={target}
            onChange={(s) => setTarget(parseInt(s, 10))}
          />
        )}
        {isShop && (
          <>
            <InputField
              type="number"
              label="Price 🥑"
              required
              placeholder="amount of avocados to redeem"
              value={price}
              onChange={(s) => setPrice(parseInt(s, 10))}
            />
            <InputField
              label="Amount available"
              type="number"
              required
              value={allocation}
              onChange={(s) => setAllocation(parseInt(s, 10))}
            />
            <Box display="flex">
              <Text width={169}>Expiry date:</Text>
              <ReactDatePicker
                dateFormat={DATE_FORMAT}
                selected={endDate as any}
                onChange={(date) => {
                  setEndDate(date || undefined);
                }}
              />
            </Box>
            <Box display="flex" mb="s">
              <input
                type="checkbox"
                id="displayExpiryDate"
                checked={displayDate}
                onChange={(e) => setDisplayDate(e.target.checked)}
              />
              <label htmlFor="displayExpiryDate">
                <Text size="small">display expiry date</Text>
              </label>
            </Box>
            <Box display="flex" mb="s">
              <input
                type="checkbox"
                id="autoDelivery"
                checked={autoDelivery}
                onChange={(e) => setAutoDelivery(e.target.checked)}
              />
              <label htmlFor="autoDelivery">
                <Text size="small">this reward is autmatically delivered</Text>
              </label>
            </Box>
          </>
        )}
        <InputField
          label="Title"
          placeholder="Sub Copy"
          value={title}
          required
          onChange={setTitle}
          onCancel={() => setTitle(reward?.title || "")}
          multiline
        />
        <InputField
          label="Tag"
          value={tag}
          onChange={setTag}
          onCancel={() => setTag(reward?.tag || "")}
          placeholder="e.g. Hot Offer"
        />
        <InputField
          label="Link"
          value={link}
          required
          onChange={setLink}
          onCancel={() => setLink(reward?.link || "")}
        />
        <InputField
          label="Link Copy"
          value={linkTitle}
          required
          onChange={setLinkTitle}
          onCancel={() => setLinkTitle(reward?.linkTitle || "")}
          placeholder="Link Title"
        />
        <InputField
          label="About"
          required
          value={about}
          onChange={setAbout}
          onCancel={() => setAbout(reward?.about || "")}
          multiline
          placeholder="Tell us about the charity"
        />
        <Box width={400} mb="l" mt="m">
          <Text mb="s">Logo*:</Text>
          <ImageUpload src={logoUrl} onUpdateSrc={setLogoUrl} />
        </Box>
        <Text mb="s">Carousel*:</Text>
        <Box display="flex" mb="l">
          <Box mr="s">
            <ImageUpload
              src={carousel[0]}
              onUpdateSrc={(src) => setCarousel(([a, b, c]) => [src, b, c])}
            />
          </Box>
          <Box mr="s">
            <ImageUpload
              src={carousel[1]}
              onUpdateSrc={(src) => setCarousel(([a, b, c]) => [a, src, c])}
            />
          </Box>
          <Box mr="s">
            <ImageUpload
              src={carousel[2]}
              onUpdateSrc={(src) => setCarousel(([a, b, c]) => [a, b, src])}
            />
          </Box>
        </Box>
        <InputField
          label={`Email ${
            (isCharity && "donation") || (isShop && "redeeming") || ""
          } info`}
          required
          value={emailInfo}
          onChange={setEmailInfo}
          onCancel={() => setEmailInfo(reward?.emailInfo || "")}
          multiline
          placeholder="Email Info"
        />
        <Box mt="xl" display="flex">
          <Button
            label="Save"
            onClick={onSave}
            mr="s"
            disabled={(reward?.publishedAt && !valid()) || loading}
            title={!valid() ? "The data is not valid, plase check 🕵️" : "Save"}
          />
          {(!reward?.publishedAt && (
            <Button
              mr="s"
              label="Publish"
              disabled={!valid() || isNew || loading}
              onClick={onPublish}
              title={
                (!valid() && "The data is not valid, plase check 🕵️") ||
                (isNew && "Please save first, before you publish 🙏") ||
                "Publish"
              }
            />
          )) || <Button label="Unpublish" onClick={onUnpublish} mr="s" />}
          {!isNew && !showDelete && (
            <Button
              mr="s"
              label="🗑️ Delete"
              onClick={() => setShowDelete(true)}
              backgroundColor="red"
            />
          )}
        </Box>
        {showDelete && (
          <Box display="flex" alignItems="center">
            <Text>Are you sure you want to delete this reward?</Text>
            <Button
              label="yes, delete 🔥"
              ml="s"
              onClick={() => {
                setShowDelete(false);
                onDelete();
              }}
              backgroundColor="red"
            />
            <Button
              label="ok maybe not..."
              ml="s"
              onClick={() => {
                setShowDelete(false);
              }}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={success}
        autoHideDuration={1500}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success">The reward has been saved! 🔥</Alert>
      </Snackbar>
    </Box>
  );
};
