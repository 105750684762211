import { createSlice } from "@reduxjs/toolkit";
import { PartnershipFragment } from "queries/partnership/partnershipFragment";
import {
  archivePartnership,
  connectPartnershipModule,
  getPartnership,
  listPartnerships,
  upsertPartnership,
} from "./thunks";
import { PartnershipQuery } from "queries/partnership/partershipQuery";

interface PartnershipState {
  partnerships: (PartnershipFragment & {
    participants?: PartnershipQuery["partnership"]["participants"];
  })[];
  listLoading: boolean;
  upsertLoading: boolean;
  connectModuleLoading: boolean;
  selectedPartnershipId?: number;
  error: string | null;
}

const initialState: PartnershipState = {
  partnerships: [],
  listLoading: false,
  upsertLoading: false,
  connectModuleLoading: false,
  error: null,
};

export const partnershipSlice = createSlice({
  name: "partnerships",
  initialState,
  reducers: {
    setSelectedPartnershipId: (state, action) => {
      state.selectedPartnershipId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartnership.fulfilled, (state, action) => {
      state.error = null;
      // replace in place
      const partnership = action.payload.partnership;
      const index = state.partnerships.findIndex(
        (p) => p.id === partnership.id
      );
      state.partnerships[index] = partnership;
    });
    builder.addCase(listPartnerships.pending, (state) => {
      state.listLoading = true;
      state.error = null;
    });
    builder.addCase(listPartnerships.fulfilled, (state, action) => {
      state.partnerships = action.payload.partnerships;
      state.listLoading = false;
    });
    builder.addCase(listPartnerships.rejected, (state) => {
      state.listLoading = false;
      state.error = "Failed to load partnerships";
    });
    builder.addCase(upsertPartnership.pending, (state) => {
      state.upsertLoading = true;
      state.error = null;
    });
    builder.addCase(upsertPartnership.fulfilled, (state, action) => {
      // if partnership is new, add it to the list, sort by id reverse
      // otherwise, replace in place
      const partnership = action.payload.upsertPartnership;
      const index = state.partnerships.findIndex(
        (p) => p.id === partnership.id
      );
      if (index === -1) {
        state.partnerships.push(partnership);
        state.partnerships.sort((a, b) => b.id - a.id);
      } else {
        state.partnerships[index] = partnership;
      }
      state.upsertLoading = false;
    });
    builder.addCase(upsertPartnership.rejected, (state) => {
      state.upsertLoading = false;
      state.error = "Failed to save partnership";
    });
    builder.addCase(archivePartnership.fulfilled, (state, action) => {
      const { id } = action.payload.archivePartnership;
      state.partnerships = state.partnerships.filter((p) => p.id !== id);
    });
    builder.addCase(archivePartnership.rejected, (state) => {
      state.error = "Failed to archive partnership";
    });
    builder.addCase(connectPartnershipModule.pending, (state) => {
      state.connectModuleLoading = true;
      state.error = null;
    });
    builder.addCase(connectPartnershipModule.fulfilled, (state, action) => {
      // like upsert, replace in place
      const partnership = action.payload.connectModule;
      const index = state.partnerships.findIndex(
        (p) => p.id === partnership.id
      );
      state.partnerships[index] = partnership;
      state.connectModuleLoading = false;
    });
    builder.addCase(connectPartnershipModule.rejected, (state) => {
      state.connectModuleLoading = false;
      state.error = "Failed to connect module";
    });
  },
});

export const { setSelectedPartnershipId } = partnershipSlice.actions;
export default partnershipSlice.reducer;
