import { gql } from "@apollo/client";
export type {
  CreateSearchTagMutation,
  CreateSearchTagMutationVariables,
} from "./__generated__/CreateSearchTagMutation";

export const CREATE_SEARCH_TAG_MUTATION = gql`
  mutation CreateSearchTagMutation($tag: String!, $tagType: String!) {
    createSearchTag(tag: $tag, tagType: $tagType) {
      id
      tag
      sorting
      tagType
    }
  }
`;
