import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "./partnershipFragment";
export type {
  ArchivePartnershipMutation,
  ArchivePartnershipMutationVariables,
} from "./__generated__/ArchivePartnershipMutation";

export const ARCHIVE_PARTNERSHIP_MUTATION = gql`
  ${PARTNERSHIP_FRAGMENT}
  mutation ArchivePartnershipMutation($partnershipId: Float!) {
    archivePartnership(partnershipId: $partnershipId) {
      ...PartnershipFragment
    }
  }
`;
