import { gql } from "@apollo/client";

export type {
  MediaAddMutation,
  MediaAddMutationVariables,
} from "./__generated__/MediaAddMutation";

export const MEDIA_ADD_MUTATION = gql`
  mutation MediaAddMutation(
    $key: String!
    $content: String!
    $type: String!
    $startDate: DateTime
    $json: JSON
  ) {
    addMedia(
      key: $key
      content: $content
      type: $type
      json: $json
      startDate: $startDate
    ) {
      id
    }
  }
`;
