import { gql } from "@apollo/client";
export type {
  ReorderPathQuizzesMutation,
  ReorderPathQuizzesMutationVariables,
} from "./__generated__/ReorderPathQuizzesMutation";

export const REORDER_PATH_QUIZZES_MUTATION = gql`
  mutation ReorderPathQuizzesMutation($ids: [Float!]!) {
    reorderPathQuizzes(ids: $ids)
  }
`;
