import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Text, TextField, Select } from "ui";
import { ListElement } from "queries/richTextElementFragment";

export type ListProps = {
  element: ListElement;
  isEditMode: boolean;
  onChange: (element: ListElement) => void;
};

export const List = (props: ListProps) => {
  const { element, isEditMode, onChange } = props;

  const addItem = () => {
    const updatedElement = {
      ...element,
      list: [...element.list, ""],
    };
    onChange(updatedElement);
  };

  const deleteItem = (idx: number) => {
    const updatedElement = {
      ...element,
      list: element.list.filter((_, itemIdx) => {
        return idx !== itemIdx;
      }),
    };

    onChange(updatedElement);
  };
  const updateItem = (e: any, idx: number) => {
    const updatedElement = {
      ...element,
      list: element.list.map((item, itemIdx) =>
        idx === itemIdx ? e.target.value : item
      ),
    };
    onChange(updatedElement);
  };

  const trimItem = (idx: number) => {
    const updatedElement = {
      ...element,
      list: element.list.map((item, itemIdx) =>
        idx === itemIdx ? item.trim() : item
      ),
    };
    onChange(updatedElement);
  };

  // const handleKeyDown = (e: any) => {
  //   if (e.keyCode === 13) {
  //     addItem();
  //   }
  // };

  const handleChangeVariant = (variant: string) => {
    const updatedElement = {
      ...element,
      variant,
    };
    if (variant === "custom") {
      updatedElement.bulletChar = "•";
    }

    onChange(updatedElement);
  };

  const handleChangeBulletChar = (e: any) => {
    const updatedElement = {
      ...element,
      bulletChar: e.target.value.trim(),
    };
    onChange(updatedElement);
  };

  const editView = (
    <Box display="flex" flexDirection="column" px="m">
      <Box display="flex" alignItems="center">
        <Text>bullet:</Text>
        <Box ml="s">
          <Select
            value={element.variant!}
            onChange={handleChangeVariant}
            options={[
              { value: "bullet", label: "bullet (•)" },
              { value: "number", label: "number (1..2..3)" },
              { value: "custom", label: "other" },
            ]}
          />
        </Box>
        {element.variant === "custom" && (
          <Box ml="s" display="flex" alignItems="center">
            <Text>char:</Text>
            <Box width={50}>
              <TextField
                value={element.bulletChar}
                variant="outlined"
                size="small"
                onChange={handleChangeBulletChar}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box mt="m">
        {element.list.map((item, idx) => (
          <div key={`editListItem_${idx}`}>
            {idx > 0 && <Box height={8} />}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Text mr="s">
                {element.variant === "bullet"
                  ? "•"
                  : element.variant === "number"
                  ? idx + 1
                  : element.bulletChar}
              </Text>
              <TextField
                value={item}
                variant="outlined"
                size="small"
                onChange={(e) => updateItem(e, idx)}
                onBlur={() => trimItem(idx)}
              />
              <Box ml="m" hovered onClick={() => deleteItem(idx)}>
                <DeleteIcon />
              </Box>
            </Box>
          </div>
        ))}
        <Box mt="s" hovered onClick={addItem}>
          <AddCircleIcon />
        </Box>
      </Box>
    </Box>
  );

  const readView = (
    <Box>
      {element.list.map((item, idx) => (
        <Box
          mt={idx > 0 ? "s" : undefined}
          display="flex"
          key={`viewListItem_${idx}`}
        >
          <Text display="flex">
            {element.variant === "bullet"
              ? "•"
              : element.variant === "number"
              ? idx + 1
              : element.bulletChar}{" "}
            {item}
          </Text>
        </Box>
      ))}
    </Box>
  );

  return <Box width="100%">{isEditMode ? editView : readView}</Box>;
};
