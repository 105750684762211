import { FC, useEffect, useState } from "react";
import { Box, Button, Text } from "ui";
import { ModuleCard } from "./user-modules/ModuleCard";
import { ModuleDropField } from "./user-modules/ModuleDropfield";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils";
import {
  giftAvocados,
  listUserModules,
  selectDisabledModules,
  selectEnabledModules,
  selectUserData,
  selectUserLoading,
  toggleUserAdmin,
} from "state/user";
import { UserModulesQuery } from "queries/user/userModulesQuery";

type Props = {
  userId: number;
};
type ModuleItem = UserModulesQuery["user"]["userModules"][number];

export const UserModules: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);
  const enabledModulesOriginal = useAppSelector(selectEnabledModules);
  const disabledModulesOriginal = useAppSelector(selectDisabledModules);
  const [userModules, setEnabledModules] = useState<ModuleItem[]>([]);
  const isLoading = useAppSelector(selectUserLoading);

  useEffect(() => {
    dispatch(listUserModules({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    setEnabledModules([...enabledModulesOriginal, ...disabledModulesOriginal]);
  }, [enabledModulesOriginal, disabledModulesOriginal]);

  if (isLoading) {
    return <Box>...</Box>;
  }

  function handleGiftAvocados() {
    const answer = prompt(
      "how many avocados would you like to gift this user?"
    );
    if (!answer) {
      return;
    }
    const avocados = parseInt(answer, 10);
    if (isNaN(avocados)) {
      return;
    }
    dispatch(giftAvocados({ userId, avocados }));
  }

  function handleToggleAdmin() {
    dispatch(toggleUserAdmin({ userId }));
  }

  return (
    <Box
      ml="xl"
      mt="xl"
      display="flex"
      width="100%"
      flexDirection="column"
      maxWidth="800px"
    >
      <Box mb="s">
        <Link to="/users" style={{ color: "#393939" }}>
          <ArrowBackIosIcon />
        </Link>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box>
          <img
            alt="profile"
            style={{ borderRadius: "50px", height: "68px" }}
            src={userData?.imageUrl || "/avatar_placeholder.png"}
          />
        </Box>
        <Box pl="m">
          <Box display="flex" flexDirection="row" pb="s">
            <Text mr="m" size="large" fontWeight="bold">
              {userData?.name}
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <Text>
                <a
                  style={{ color: "#393939" }}
                  href={`mailto:${userData?.email}`}
                >
                  {userData?.email}
                </a>
              </Text>
            </Box>
            <Box>
              <Text
                px="m"
                py="xs"
                style={{ cursor: "pointer" }}
                onClick={handleGiftAvocados}
                title="Gift avocados"
              >
                {userData?.points} 🥑
              </Text>
            </Box>
            {/* streak and lessons */}
            <Box mx="m">
              <Text>
                Streak day {userData?.streakDays} ({userData?.streaksCount}{" "}
                streaks)
              </Text>
              <Text>Lessons {userData?.lessonsCount}</Text>
            </Box>
            <Box mx="m">
              <Button
                label={
                  userData?.role === "ADMIN" ? "revoke admin" : "make admin"
                }
                onClick={handleToggleAdmin}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box pr="xl" pt="xl">
          <Text size="medium" fontWeight="medium" mb="xs">
            Path
          </Text>
          <ModuleDropField name="enabled">
            {userModules.map((module) => (
              <ModuleCard
                key={module.moduleId}
                variant={"enabled"} //{module.enabled ? "enabled" : "disabled"}
                module={module}
              />
            ))}
          </ModuleDropField>
        </Box>
      </Box>
    </Box>
  );
};
