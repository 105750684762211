import { gql } from "@apollo/client";
export type {
  UpsertAnnouncement,
  UpsertAnnouncementVariables,
} from "./__generated__/UpsertAnnouncement";

export const UPSERT_ANNOUNCEMENT = gql`
  mutation UpsertAnnouncement($announcement: AnnouncementInput!) {
    upsertAnnouncement(announcement: $announcement) {
      id
      title
      text
      ctaLabel
      ctaLink
      ctaArgs
      startDate
      endDate
    }
  }
`;
