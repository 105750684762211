import { createElement, useRef, FC } from "react";
import styled from "styled-components";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { ItemTypes } from "./itemTypes";
import { XYCoord } from "dnd-core";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import VideocamIcon from "@material-ui/icons/Videocam";
import HelpIcon from "@material-ui/icons/Help";
import PollIcon from "@material-ui/icons/Poll";
import SurveyIcon from "@material-ui/icons/QuestionAnswer";

import { Box, ListItem, theme } from "ui";

type DropResult = {
  dropEffect: string;
  target?: string;
  lessonId?: number;
};

const BoxWithOpacity = styled(Box)`
  opacity: ${({ opacity }) => opacity};
  &:hover {
    background-color: ${theme.colors.mediumGrey};
  }
`;

const cardIcons = {
  richtext: TextFieldsIcon,
  video: VideocamIcon,
  actionitem: TextFieldsIcon,
  quiz: HelpIcon,
  poll: PollIcon,
  survey: SurveyIcon,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface DragItem {
  index: number;
  id: string;
  type: string;
}

type CardProps = {
  id: number;
  label: string;
  subLabel?: string;
  cardType: "richtext" | "video" | "quiz" | "poll" | "actionitem" | "survey";
  selected: boolean;
  onClick: () => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  cardDropped: (inside: boolean, dropResult: DropResult) => void;
};

export const Card: FC<CardProps> = ({
  id,
  label,
  subLabel,
  selected,
  onClick,
  cardType,
  index,
  moveCard,
  cardDropped,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [x, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const handlerId = x.handlerId;

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(_, monitor: any) {
      const dropResult = monitor.getDropResult() as DropResult;
      cardDropped(monitor.didDrop(), dropResult);
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <BoxWithOpacity
      ref={ref}
      opacity={opacity}
      data-handler-id={handlerId}
      hovered
      backgroundColor="white"
    >
      <ListItem
        label={label}
        subLabel={subLabel}
        icon={createElement(cardIcons[cardType])}
        selected={selected}
        onSelected={onClick}
        noBorder={index !== 0}
      />
    </BoxWithOpacity>
  );
};
