import { gql } from "@apollo/client";
export type { UsersQuery } from "./__generated__/UsersQuery";

export const USERS_QUERY = gql`
  query UsersQuery($search: String, $orderBy: String, $partnershipId: Float) {
    searchUsers(
      search: $search
      orderBy: $orderBy
      partnershipId: $partnershipId
    ) {
      id
      externalId
      name
      email
      imageUrl
      points
      partnershipId
      lessonsCount
      streakDays
      streaksCount
    }
    usersCount {
      all
      partnerships {
        partnershipId
        count
      }
    }
  }
`;
