import { useState } from "react";
import { selectCurrCard, updateCard } from "state/learning";
import { Box } from "ui";
import { useAppSelector, useAppDispatch } from "utils/hooks";
import { Element } from "components/content-editor/elements/Element";
import {
  RichTextElementFragment,
  RichTextElementFragment_paragraph,
} from "queries/__generated__/RichTextElementFragment";
import { ElementsEditor } from "../elements";
import { SurveyCard as CardType } from "queries/learning/lessonDetails";
import cloneDeep from "lodash/cloneDeep";

const emptyTitle: RichTextElementFragment_paragraph = {
  __typename: "paragraph",
  text: "type title here",
  position: null,
};

export const SurveyCard = () => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);
  const [editMode, setEditMode] = useState(false);

  const element = emptyTitle;
  element.text = currCard!.title;

  const handleUpdateTitle = async (element: RichTextElementFragment) => {
    const titleElement = element as RichTextElementFragment_paragraph;
    const content = cloneDeep(currCard!) as CardType;
    content.title = titleElement.text;
    const card = { content, id: currCard!.id };
    dispatch(updateCard({ card }));
    setEditMode(false);
  };

  return (
    <Box>
      <Box mt="m">
        <Element
          index={0}
          element={element}
          onUpdateElement={(element) => handleUpdateTitle(element)}
          isEditMode={editMode}
          isFirstElement={true}
          isLastElement={false}
          onToggleEditMode={(isEditMode) => setEditMode(isEditMode)}
          hidePosition={true}
          title="Survey Title"
        />
      </Box>
      <Box mt="m" borderBottom="1px solid black" />
      <Box mt="m">
        <ElementsEditor />
      </Box>
    </Box>
  );
};
