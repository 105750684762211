import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Box, Button, Text } from "ui";
import { useEffect, useState } from "react";
import { InputField } from "components/InputField";
import { useAppDispatch, useAppSelector } from "utils";
import {
  selectAnnouncement,
  selectRequestStatus,
  upsertAnnouncement,
} from "state/communication";
import dayjs from "dayjs";

export const AnnouncementsEdit = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [ctaLabel, setCtaLabel] = useState("");
  const [ctaLink, setCtaLink] = useState("");
  const [ctaArgs, setCtaArgs] = useState<{ name: string; value: string }[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { announcementId } = useParams<{ announcementId: string }>();
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);
  const announcement = useAppSelector(
    selectAnnouncement(parseInt(announcementId, 10))
  );

  const onSave = async () => {
    await dispatch(
      upsertAnnouncement({
        announcement: {
          id: announcementId ? parseInt(announcementId, 10) : undefined,
          title,
          text,
          ctaLabel,
          ctaLink,
          ctaArgs,
          // two hours of padding to account for timezones in Europe
          startDate: dayjs(startDate).startOf("day").add(2, "hours").toDate(),
          endDate: dayjs(endDate).endOf("day").subtract(2, "hours").toDate(),
        },
      })
    );
    // go back to the list
    window.history.back();
  };

  useEffect(() => {
    if (announcement) {
      setTitle(announcement.title);
      setText(announcement.text);
      setCtaLabel(announcement.ctaLabel);
      setCtaLink(announcement.ctaLink);
      setCtaArgs(announcement.ctaArgs);
      setStartDate(new Date(announcement.startDate));
      setEndDate(new Date(announcement.endDate));
    }
  }, [announcement]);

  const setArg = (index: number, key: "name" | "value", value: string) => {
    const newArgs = [...ctaArgs];
    if (!newArgs[index]) newArgs[index] = { name: "", value: "" };
    newArgs[index][key] = value;
    setCtaArgs(newArgs);
  };

  return (
    <Box
      width="420px"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex" alignItems="center">
        <Link
          to="/announcements"
          style={{ color: "#393939", paddingTop: "2px", paddingRight: "4px" }}
        >
          <ArrowBackIosIcon />
        </Link>
        <Text>{announcementId ? "Edit" : "Create"} Announcement</Text>
      </Box>
      <Box display="flex" flexDirection="column" mt="l" p="m">
        <InputField label="Title" value={title} onChange={setTitle} />
        <InputField label="Text" value={text} onChange={setText} />
        <InputField
          label="CTA Label"
          value={ctaLabel}
          onChange={setCtaLabel}
          placeholder="what's on the button"
        />
        <InputField
          label="CTA Link"
          value={ctaLink}
          onChange={setCtaLink}
          placeholder="screen name or url"
        />
        <Box my="s" border="1px solid lightGrey" />
        <Text mb="s">CTA Parameters</Text>
        {[...Array(3)].map((_, index) => (
          <Box>
            <InputField
              label={`Name ${index + 1}`}
              value={ctaArgs[index]?.name}
              onChange={setArg.bind(null, index, "name")}
            />
            <InputField
              label={`Value ${index + 1}`}
              value={ctaArgs[index]?.value}
              onChange={setArg.bind(null, index, "value")}
            />
          </Box>
        ))}
        <Box mt="s" mb="m" border="1px solid lightGrey" />
        <Box mb="s" display="flex">
          <Text>Starts&nbsp;at:&nbsp;</Text>
          <DatePicker
            selected={startDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              if (date) {
                setStartDate(date);
              }
            }}
          />
        </Box>
        <Box mb="s" display="flex">
          <Text>Ends&nbsp;at:&nbsp;</Text>
          <DatePicker
            selected={endDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              if (date) {
                setEndDate(date);
              }
            }}
          />
        </Box>
        <Button
          label="Save"
          onClick={onSave}
          disabled={
            requestStatus === "loading" ||
            [title, text, ctaLabel, ctaLink, startDate, endDate].filter(
              (i) => !i
            ).length > 0
          }
        />
      </Box>
    </Box>
  );
};
