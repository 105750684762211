import type { RootState } from "store";
import { Poll } from "components/polls/types";
import { createSlice } from "@reduxjs/toolkit";
import { getCommunityPollStatistics, listPolls } from "./thunks";
import { CommunityPollStatisticsQuery } from "queries/communityPollStatisticsQuery";

interface PollState {
  polls: Poll[];
  statistics: {
    [
      pollId: string
    ]: CommunityPollStatisticsQuery["communityFeedbackStatistics"]["statistics"];
  };
}
const initialState: PollState = {
  polls: [],
  statistics: {},
};

export const pollSlice = createSlice({
  name: "polls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listPolls.fulfilled, (state, action) => {
      state.polls = action.payload.listPolls as Poll[];
    });
    builder.addCase(getCommunityPollStatistics.fulfilled, (state, action) => {
      const pollId =
        action.payload.communityFeedbackStatistics.feedbackRequestId;
      state.statistics[pollId] =
        action.payload.communityFeedbackStatistics!.statistics;
    });
  },
});

export const selectPolls = (state: RootState) => state.polls.polls;
export const selectPollStatistics =
  (requestFeedbackId: Number) => (state: RootState) =>
    state.polls.statistics[`${requestFeedbackId}`];

export default pollSlice.reducer;
