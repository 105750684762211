import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { PillSelector } from "components/PillSelector";
import { SearchField } from "components/SearchField";
import { StatusBadge } from "components/StatusBadge";
import dayjs from "dayjs";
import { debounce, startCase } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  listRewardActivities,
  selectActivityCounts,
  selectRewardActivities,
  selectRewardActivitiesTotal,
  updateRewardActivity,
} from "state/reward";
import { Box, Button, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

export const RewardsDelivery = () => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [offset, setOffset] = useState(0);
  const totalCounts = useAppSelector(selectRewardActivitiesTotal);
  const counts = useAppSelector(selectActivityCounts);
  const deliveries = useAppSelector(selectRewardActivities);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const s = debouncedSearch;
    setLoading(true);
    dispatch(
      listRewardActivities({
        status: filter !== "all" ? filter : undefined,
        search: s.length >= 3 ? s : undefined,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
        offset,
      })
    ).finally(() => setLoading(false));
  }, [dateRange, debouncedSearch, dispatch, filter, offset]);

  const onUpdateActivityStatus = (id: number, status: string) => {
    dispatch(updateRewardActivity({ id, status }));
  };

  // We're using debounce to avoid race conditions when typing in the search field
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerSearchRefresh = useCallback(
    debounce(setDebouncedSearch, 500),
    []
  );
  useEffect(() => {
    triggerSearchRefresh(search);
  }, [search, triggerSearchRefresh]);

  const loadMore = () => {
    setOffset(deliveries.length);
  };

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Box display="flex">
        <Box flex={1} display="flex" flexDirection="column">
          <SearchField
            value={search}
            onChange={setSearch}
            placeholder="User id, name, email or reward title"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          pb="l"
          width={400}
        >
          <Box
            ml="l"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: "24px",
              fontSize: "16px",
              padding: "6px 20px",
              height: "40px",
              marginBottom: "26px",
            }}
          >
            {!dateRange && (
              <Button
                label="Select date range"
                backgroundColor="transparent"
                color="#C8C8C8"
                onClick={() => {
                  setDateRange([new Date(), new Date()]);
                }}
                mb="x"
              />
            )}
            {!!dateRange && (
              <Box display="flex">
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange as any} // the type is wrong here
                  calendarIcon={null}
                  clearIcon={null}
                  isOpen
                />
                <Button
                  backgroundColor="transparent"
                  color="#393939"
                  label="🅧"
                  onClick={() => setDateRange(undefined)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <PillSelector
        value={filter}
        options={[
          { value: "all", count: counts?.all || 0 },
          { value: "requested", count: counts?.requested || 0 },
          { value: "complication", count: counts?.complication || 0 },
          { value: "delivered", count: counts?.delivered || 0 },
          {
            value: "automaticallyDelivered",
            count: counts?.automaticallyDelivered || 0,
          },
        ]}
        onChange={(f) => {
          setFilter(f);
          setOffset(0);
        }}
      />
      <Box borderBottom="1px solid lightgrey" />
      <TableContainer component={Box} style={{ opacity: loading ? 0.5 : 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text weight="bold">User</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Brand</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Reward Type</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Date</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Status</Text>
              </TableCell>
              <TableCell>
                <Text weight="bold">Email</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveries.map((delivery) => {
              return (
                <TableRow key={delivery.id}>
                  <TableCell>
                    <Text>{delivery.name || delivery.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/rewards-management/${delivery.rewardId}`}
                      style={{ color: "#393939" }}
                    >
                      <Text>{delivery.brand}</Text>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Text>
                      {startCase(
                        delivery.rewardType
                          .replace("CHARITY", "Donate")
                          .toLowerCase()
                      )}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text>
                      {dayjs(delivery.createdAt).format("DD/MM/YYYY")}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <StatusBadge
                      status={delivery.status}
                      options={[
                        "requested",
                        "complication",
                        "delivered",
                        "automaticallyDelivered",
                      ]}
                      onChange={(status) => {
                        onUpdateActivityStatus(delivery.id, status);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <a
                      href={`mailto:${delivery.email}`}
                      style={{ color: "#393939" }}
                    >
                      <Text>{delivery.email}</Text>
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {deliveries.length < totalCounts && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="l"
          mb="l"
          width="100%"
        >
          <Button
            label="Load more"
            backgroundColor="transparent"
            color="#C8C8C8"
            onClick={loadMore}
          />
        </Box>
      )}
    </Box>
  );
};
