import { useEffect, useState } from "react";
import { Box, Loader } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

import {
  loadModulesList,
  selectModulesList,
  selectModulesDrafts,
  selectRequestStatus,
  setCurrCardIdx,
} from "state/learning";
import { LessonEditor } from "components/content-editor/LessonEditor";
import { ModulesList } from "components/content-editor/ModulesList";
import { ModuleMetaDataEditor } from "components/content-editor/ModuleMetaDataEditor";

export const ContentEditor = () => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);
  const modulesList = useAppSelector(selectModulesList);
  const moduleDrafts = useAppSelector(selectModulesDrafts);

  const [selectedLessonId, setSelectedLessonId] = useState(0);
  const [selectedModuleId, setSelectedModuleId] = useState(0);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(loadModulesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLessonSelected = (id: number, editMode = false) => {
    setSelectedLessonId(id);
    setEditMode(editMode);
    dispatch(setCurrCardIdx(undefined));
  };

  const handleModulesSelected = (id: number) => {
    setSelectedModuleId(id);
    dispatch(setCurrCardIdx(undefined));
  };

  if (requestStatus === "loadModulesList_loading" && modulesList.length === 0) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Box>
    );
  }
  return (
    <Box style={{ minHeight: "100vh" }} display="flex">
      <Box
        width={300}
        borderRightStyle="solid"
        borderRightColor="grey1"
        borderRightWidth={2}
      >
        <ModulesList
          modulesList={modulesList}
          moduleDrafts={moduleDrafts}
          selectedLessonId={selectedLessonId}
          selectedModuleId={selectedModuleId}
          onLessonSelected={handleLessonSelected}
          onModuleSelected={handleModulesSelected}
        />
      </Box>
      <Box width="100%">
        {selectedModuleId ? (
          <ModuleMetaDataEditor
            moduleId={selectedModuleId}
            onCreateLesson={(lessonId) => {
              handleLessonSelected(lessonId, true);
              handleModulesSelected(0);
            }}
          />
        ) : (
          <LessonEditor
            selectedLessonId={selectedLessonId}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        )}
      </Box>
    </Box>
  );
};
