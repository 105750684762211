import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "./partnershipFragment";
export type {
  UpsertPartnership,
  UpsertPartnershipVariables,
} from "./__generated__/UpsertPartnership";

export const UPSERT_PARTNERSHIP_MUTATION = gql`
  ${PARTNERSHIP_FRAGMENT}
  mutation UpsertPartnership($partnership: PartnershipInput!) {
    upsertPartnership(partnership: $partnership) {
      ...PartnershipFragment
      participants {
        id
        name
        email
        imageUrl
      }
    }
  }
`;
