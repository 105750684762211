import { gql } from "@apollo/client";
export type {
  CloneLesson,
  CloneLessonVariables,
} from "./__generated__/CloneLesson";

export const CLONE_LESSON_MUTATION = gql`
  mutation CloneLesson($lessonId: Float!) {
    cloneLesson(lessonId: $lessonId) {
      id
      title
      order
    }
  }
`;
