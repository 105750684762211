import { forwardRef } from "react";
import styled, { css } from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  variant,
} from "styled-system";

import { StyledBoxProps, Props } from "./types";

const StyledBox = styled.div<StyledBoxProps>`
  cursor: ${({ hovered }) => (hovered ? "pointer" : undefined)};
  ${variant({
    variants: {
      flex: { display: "flex" },
      box: { display: "block" },
      grid: { display: "grid" },
    },
  })}

  ${({ showScroll }) => {
    if (showScroll) {
      return css`
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          background-color: ${({ theme }) => theme?.colors?.disabled?.light};
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: ${({ theme }) => theme?.colors?.disabled?.normal};
        }
      `;
    }
  }}

  & :hover {
    background-color: ${({ hoveredBackgroundColor, theme }) =>
      hoveredBackgroundColor
        ? theme?.colors[hoveredBackgroundColor]
        : undefined};
  }

  & :hover > .show-on-hover {
      visibility: visible;
  }
  & > .show-on-hover {
    visibility: hidden;
  }

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  outline-offset: 2px;

  ${system({ w: { property: "width", scale: "size" } })};
  ${system({ h: { property: "height", scale: "size" } })};
  ${background};
  ${border};
  ${color};
  ${flexbox};
  ${grid};
  ${layout};
  ${position};
  ${space};
`;

export const Box = forwardRef(
  (
    {
      variant = "box",
      showScroll = false,
      hovered = false,
      hoveredBackgroundColor,
      children,
      ...boxProps
    }: Props,
    ref
  ) => {
    return (
      <StyledBox
        ref={ref}
        variant={variant}
        showScroll={showScroll}
        hovered={hovered}
        hoveredBackgroundColor={hoveredBackgroundColor}
        {...boxProps}
      >
        {children}
      </StyledBox>
    );
  }
);
