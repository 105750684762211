import { Switch, Route } from "react-router-dom";
import {
  ContentEditor,
  JunoPick,
  Stats,
  Polls,
  Reports,
  UsersPage,
  UserModulesPage,
  RewardsManagement,
  RewardsManagementDetails,
  RewardsDelivery,
  AuthenticationPage,
} from "pages";
import { NewsPage } from "pages/News";
import { NewsCreatePage } from "pages/NewsCreate";
import { NewsEditPage } from "pages/NewsEdit";
import { LeaderboardPage } from "pages/Leaderboard";
import { AuditLogPage } from "pages/AuditLogPage";
import { AnnouncementsPage } from "pages/AnnouncementsPage";
import { AnnouncementsEdit } from "pages/AnnouncementsEdit";
import { SearchTagsPage } from "pages/SearchTagsPage";
import { PathQuizPage } from "pages/PathQuiz";
import { PathQuizEditPage } from "pages/PathQuizEdit";
import { PartnershipPage } from "pages/Partnership";
import { CommunityPage } from "pages/CommunityPage";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/content">
        <ContentEditor />
      </Route>
      <Route path="/pick">
        <JunoPick />
      </Route>
      <Route path="/stats">
        <Stats />
      </Route>
      <Route path="/polls">
        <Polls />
      </Route>
      <Route path="/reports">
        <Reports />
      </Route>
      <Route path="/users">
        <UsersPage />
      </Route>
      <Route path="/leaderboard">
        <LeaderboardPage />
      </Route>
      <Route path="/rewards-management" exact={true}>
        <RewardsManagement />
      </Route>
      <Route path="/rewards-management/new" exact={true}>
        <RewardsManagementDetails />
      </Route>
      <Route path="/rewards-management/:rewardId">
        <RewardsManagementDetails />
      </Route>
      <Route path="/rewards-delivery" exact={true}>
        <RewardsDelivery />
      </Route>
      <Route path="/news" exact={true}>
        <NewsPage />
      </Route>
      <Route path="/news/create">
        <NewsCreatePage />
      </Route>
      <Route path="/news/edit/:newsId">
        <NewsEditPage />
      </Route>
      <Route path="/user/:userId">
        <UserModulesPage />
      </Route>
      <Route path="/login">
        <AuthenticationPage />
      </Route>
      <Route path="/audit-logs">
        <AuditLogPage />
      </Route>
      <Route path="/announcements">
        <AnnouncementsPage />
      </Route>
      <Route path="/announcement/create">
        <AnnouncementsEdit />
      </Route>
      <Route path="/announcement/edit/:announcementId">
        <AnnouncementsEdit />
      </Route>
      <Route path="/search-tags">
        <SearchTagsPage />
      </Route>
      <Route path="/path-quiz/create">
        <PathQuizEditPage />
      </Route>
      <Route path="/path-quiz/edit/:pathQuizId">
        <PathQuizEditPage />
      </Route>
      <Route path="/path-quiz">
        <PathQuizPage />
      </Route>
      <Route path="/partnerships">
        <PartnershipPage />
      </Route>
      <Route path="/communities">
        <CommunityPage />
      </Route>
      <Route path="/">
        <ContentEditor />
      </Route>
    </Switch>
  );
};
