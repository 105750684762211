import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "./partnershipFragment";
export type {
  PartnershipQuery,
  PartnershipQueryVariables,
} from "./__generated__/PartnershipQuery";

export const PARTNERSHIP_QUERY = gql`
  ${PARTNERSHIP_FRAGMENT}
  query PartnershipQuery($partnershipId: Float!) {
    partnership(partnershipId: $partnershipId) {
      ...PartnershipFragment
      participants {
        id
        name
        email
        imageUrl
      }
    }
  }
`;
