import { ImageUpload } from "components/ImageUpload";
import { useEffect, useState } from "react";
import {
  createLesson,
  loadModulesList,
  publishDraftModule,
  selectModule,
  updateModule,
} from "state/learning";
import { Box, Button, Text } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

type Props = {
  moduleId: number;
  onCreateLesson?: (lessonId: number) => void;
};

type ModuleInput = {
  title?: string;
  imageSmallUrl?: string;
  imageMediumUrl?: string;
  imageLargeUrl?: string;
  goalTitle?: string;
  goals?: string[];
  description?: string;
  deleted?: boolean;
};

export const ModuleMetaDataEditor = (props: Props) => {
  const [title, setTitle] = useState("");
  const [imageSmallUrl, setImageSmallUrl] = useState("");
  const [imageMediumUrl, setImageMediumUrl] = useState("");
  const [imageLargeUrl, setImageLargeUrl] = useState("");
  const [description, setDescription] = useState("");
  const [goalTitle, setGoalTitle] = useState("");
  const [goals, setGoals] = useState<string[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState("");
  const dispatch = useAppDispatch();

  const currModule = useAppSelector(selectModule(props.moduleId));

  const saveModule = (update: ModuleInput) => {
    dispatch(updateModule({ module: update, moduleId: props.moduleId! }));
  };

  useEffect(() => {
    setTitle(currModule?.title || "");
    setImageSmallUrl(currModule?.imageSmallUrl || "");
    setImageMediumUrl(currModule?.imageMediumUrl || "");
    setImageLargeUrl(currModule?.imageLargeUrl || "");
    setDescription(currModule?.description || "");
    setGoalTitle(currModule?.goalTitle || "");
    setDeleted(!!currModule?.deleted);
    setGoals(currModule?.goals || []);
  }, [currModule]);

  const addLesson = async () => {
    if (!currModule) return;
    const newLesson = await dispatch(
      createLesson({ moduleId: props.moduleId })
    );
    await dispatch(loadModulesList());
    if (props.onCreateLesson) {
      props.onCreateLesson(newLesson.payload.createLesson.id);
    }
  };

  const publishDraft = async () => {
    setLoading("publishing");
    await dispatch(publishDraftModule({ moduleId: currModule!.id }));
    await dispatch(loadModulesList());
    setLoading("");
  };

  return (
    <Box px="s" py="s" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Box mr="l">
          <Text mb="s" mt="s">
            Module Title:&nbsp;
            <input
              value={title}
              onBlur={() => saveModule({ title })}
              onChange={(e) => setTitle(e.target.value)}
              size={30}
              onKeyUp={(e) => {
                if (e.key === "Escape") {
                  setTitle(currModule!.title);
                }
                if (e.key === "Enter") {
                  saveModule({ title });
                }
              }}
            />
          </Text>
          <Box width="200px">
            <h3>Small Image</h3>
            <ImageUpload
              label="Upload Small Image"
              src={imageSmallUrl}
              onUpdateSrc={(imageSmallUrl) => saveModule({ imageSmallUrl })}
            />
          </Box>
          <Box width="400px">
            <h3>Medium Image</h3>
            <ImageUpload
              label="Upload Medium Image"
              src={imageMediumUrl}
              onUpdateSrc={(imageMediumUrl) => saveModule({ imageMediumUrl })}
            />
          </Box>
          <Box width="500px">
            <h3>Large Image</h3>
            <ImageUpload
              label="Upload Large Image"
              src={imageLargeUrl}
              onUpdateSrc={(imageLargeUrl) => saveModule({ imageLargeUrl })}
            />
          </Box>
          <Box mb="s">
            <Text>Description:</Text>
            <textarea
              style={{ width: "400px", height: "200px" }}
              onBlur={() => saveModule({ description })}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Escape") {
                  setDescription(currModule!.description);
                }
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Text mb="s" mt="s">
              Goal Title:&nbsp;
              <input
                value={goalTitle}
                onBlur={() => saveModule({ goalTitle })}
                onChange={(e) => setGoalTitle(e.target.value)}
                size={30}
                onKeyUp={(e) => {
                  if (e.key === "Escape") {
                    setGoalTitle(currModule!.goalTitle);
                  }
                }}
              />
            </Text>
          </Box>
          <Box width="200px" mt="l">
            <h3>Goal Sentences</h3>
            {[0, 1, 2].map((i) => (
              <Box mb="s" key={i}>
                <input
                  value={goals[i] as string}
                  onChange={(e) =>
                    setGoals(
                      goals.map((g, idx) => (idx === i ? e.target.value : g))
                    )
                  }
                  onBlur={() => saveModule({ goals })}
                  size={50}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box>
        <label htmlFor="module-deleted">Hidden </label>
        <input
          id="module-deleted"
          type="checkbox"
          checked={deleted}
          onChange={() => {
            setDeleted(!deleted);
            saveModule({ deleted: !deleted });
          }}
        />
      </Box>
      <Box display="flex" mt="m">
        <Button label="+ Add lesson" onClick={addLesson} />
      </Box>
      {currModule?.moduleType === "DRAFT" && (
        <Box display="flex" mt="m">
          <Button
            label={
              loading === "publishing"
                ? "publishing... 🌍🚀🌔"
                : "Publish draft 🌍✨"
            }
            onClick={publishDraft}
            disabled={loading === "publishing"}
          />
        </Box>
      )}
    </Box>
  );
};
