import { useCallback, useState, useEffect, useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import update from "immutability-helper";
import uniqid from "uniqid";
import { useAppSelector, useAppDispatch } from "utils/hooks";
import { Box } from "ui";
import { CardElement } from "queries/richTextElementFragment";
import { RichTextCard } from "queries/learning/lessonDetails";
import { selectCurrCard, updateCard } from "state/learning";

import { elementsDefaults } from "../dataDefaults";

import { Element } from "./Element";
import { AddElement } from "./AddElement";
import { CardUpdate } from "queries/learning/updateCardMutation";

type Props = {
  blocklist?: string[];
};

export const ElementsEditor = ({ blocklist }: Props) => {
  const dispatch = useAppDispatch();
  const currCard = useAppSelector(selectCurrCard);

  const elements: CardElement[] = (currCard as RichTextCard).elements;
  const sessionElements = useRef<CardElement[] | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRefresh] = useState(true);

  useEffect(() => {
    sessionElements.current = currCard
      ? (currCard as RichTextCard).elements.map((element, idx) => ({
          ...element,
          order: idx,
        }))
      : [];
    setRefresh((prev) => !prev);
  }, [currCard]);

  const [elementInEditMode, setElemenetInEditMode] = useState<
    number | undefined
  >(undefined);

  const saveCard = (cardUpdate: CardUpdate, idx?: number) => {
    const card = { id: cardUpdate.id, content: cardUpdate };
    dispatch(updateCard({ card }));
    if (idx !== undefined) {
      handleEditMode(idx, false);
    }
  };

  const handleEditMode = (idx: number, isEditMode: boolean) => {
    setElemenetInEditMode(isEditMode ? idx : undefined);
  };

  const handleUpdateElement = async (element: CardElement, idx: number) => {
    const card = cloneDeep(currCard) as RichTextCard;
    card.elements[idx] = element;
    saveCard(card, idx);
  };

  const handleAddElement = (idx: number) => (type: string) => {
    const card = cloneDeep(currCard) as RichTextCard;
    card.elements.splice(idx, 0, elementsDefaults[type]);
    saveCard(card, idx);
  };

  const handleDeleteElement = async (idx: number) => {
    const card = cloneDeep(currCard) as RichTextCard;
    card.elements.splice(idx, 1);
    saveCard(card, idx);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    if (!dragIndex) {
      return;
    }
    sessionElements.current = update(sessionElements.current, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, sessionElements.current![dragIndex] as CardElement],
      ],
    });
    setRefresh((prev) => !prev);
  }, []);

  const saveNewOrder = (inPlace: boolean) => {
    if (inPlace) {
      const card = cloneDeep(currCard) as RichTextCard;
      card.elements = sessionElements.current!.map((e) => ({ ...e }));
      saveCard(card);
    } else {
      sessionElements.current = currCard
        ? (currCard as RichTextCard).elements.map((element, idx) => ({
            ...element,
            order: idx,
          }))
        : [];
      setRefresh((prev) => !prev);
    }
  };

  if (!sessionElements.current) {
    return null;
  }

  return (
    <Box>
      <Box>
        {sessionElements.current!.map((element, idx) => (
          <Box mt={idx > 0 ? "s" : undefined} key={uniqid()}>
            <Element
              index={idx}
              element={element}
              onUpdateElement={(element) => handleUpdateElement(element, idx)}
              isEditMode={elementInEditMode === idx}
              isFirstElement={idx === 0}
              isLastElement={idx + 1 === elements.length}
              onToggleEditMode={(isEditMode) => handleEditMode(idx, isEditMode)}
              onDeletElement={() => handleDeleteElement(idx)}
              onAddElementBefore={handleAddElement(idx)}
              onAddElementAfter={handleAddElement(idx + 1)}
              onMoveCard={moveCard}
              onCardDropped={saveNewOrder}
              enableDragging={elementInEditMode === undefined}
            />
          </Box>
        ))}
        <AddElement
          onAddElement={handleAddElement(elements.length)}
          blocklist={blocklist}
        />
      </Box>
    </Box>
  );
};
