import { gql } from "@apollo/client";
export type {
  UpdateSearchTagMutation,
  UpdateSearchTagMutationVariables,
} from "./__generated__/UpdateSearchTagMutation";

export const UPDATE_SEARCH_TAG_MUTATION = gql`
  mutation UpdateSearchTagMutation($id: Float!, $tag: String!, $sorting: Float!) {
    updateSearchTag(tagId: $id, tag: $tag, sorting: $sorting) {
      id
      tag
      sorting
      tagType
    }
  }
`;
