import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import {
  color,
  flexbox,
  layout,
  space,
  typography,
  variant,
  border,
  position,
} from "styled-system";

import { Props } from "./types";

export const Text = styled.p.attrs(({ variant = "normal" }: Props) => ({
  variant,
}))<Props>`
  font-family: ${themeGet("fontFamily.primary")};
  font-size: ${themeGet("fontSizes.normal")}px;
  margin: 0px;

  outline: none;
  outline-offset: 2px;
  &.focus-ring {
    outline: 2px solid ${({ theme }) => theme?.colors?.grey1};
  }

  ${variant({
    variants: {
      error: { color: "secondary.100" },
      info: { color: "generic.75" },
      normal: { color: "text.normal" },
      inverted: { color: "generic.0" },
    },
  })};
  ${variant({
    prop: "size",
    variants: {
      large: { fontSize: "large" },
      big: { fontSize: "big" },
      medium: { fontSize: "22px" },
      small: { fontSize: "small" },
      tiny: { fontSize: "tiny" },
    },
  })}
  ${variant({
    prop: "weight",
    variants: {
      normal: { fontWeight: "normal" },
      bold: { fontWeight: "bold" },
      light: { fontWeight: "lighter" },
    },
  })} 

  ${flexbox};
  ${color};
  ${layout};
  ${space};
  ${typography};
  ${border}
  ${position}
`;
