import { gql } from "@apollo/client";
export type {
  AuthenticateMutation,
  AuthenticateMutationVariables,
} from "./__generated__/AuthenticateMutation";

export const AUTH_MUTATION = gql`
  mutation AuthenticateMutation($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      username
      error
    }
  }
`;
