import { gql } from "@apollo/client";
export type { ListCommunities } from "./__generated__/ListCommunities";

export const LIST_COMMUNITIES_QUERY = gql`
  query ListCommunities {
    communities {
      id
      title
      amityCommunityId
      partnershipId
    }
  }
`;
