import { gql } from "@apollo/client";
export type { PartnershipFragment } from "./__generated__/PartnershipFragment";

export const PARTNERSHIP_FRAGMENT = gql`
  fragment PartnershipFragment on PartnershipOutput {
    id
    internalName
    title
    code
    startDate
    logoImageUrl
    logoSmallUrl
    sponsorCopy
    sponsorUrl
    welcomeText
    benefits {
      title
      subcopy
      imageUrl
    }
    rewards {
      id
    }
    communities {
      id
      title
      amityCommunityId
      imageUrl
    }
    modules {
      id
    }
  }
`;
