import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import ReactLoader from "react-loader-spinner";

type LoaderProps = { size?: number };

export const Loader = (props: LoaderProps) => {
  const { size = 100 } = props;

  return <ReactLoader type="Puff" color="#00BFFF" height={size} width={size} />;
};
