import Select from "react-select";
import { Box, Text } from "ui";

type Answer = {
  id?: number | null;
  title: string;
  moduleIds: number[];
};

type Props = {
  answer: Answer;
  disabled: boolean;
  textLabel: string;
  moduleLabel: string;
  modules: { id: number; title: string }[];
  allAnserModules: number[];
  onChange: (answer: Answer) => void;
};

export const PathQuizAnswerEdit = (props: Props) => {
  const { answer, modules, onChange, disabled } = props;
  return (
    <Box mb="m">
      <Text weight="bold" mb="s">
        {props.textLabel}
      </Text>
      <input
        disabled={disabled}
        size={100}
        value={answer.title}
        onChange={(e) =>
          onChange({
            title: e.target.value,
            moduleIds: answer.moduleIds,
          })
        }
      />
      <Text weight="bold" mb="s" mt="s">
        {props.moduleLabel}
      </Text>{" "}
      <Select
        isMulti
        isDisabled={disabled}
        options={modules
          .filter(
            ({ id }) =>
              !props.allAnserModules.includes(id) ||
              answer.moduleIds.includes(id)
          )
          .map((module) => ({
            value: module.id,
            label: module.title,
          }))}
        value={
          answer.moduleIds?.map((moduleId) => ({
            value: moduleId,
            label: modules.find((module) => module.id === moduleId)?.title,
          })) || []
        }
        onChange={(selectedModules) => {
          onChange({
            title: answer.title,
            moduleIds: selectedModules.map((module) => module.value),
          });
        }}
      />
    </Box>
  );
};
