import {
  Avatar,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { max } from "lodash";
import { useEffect, useState } from "react";
import { Box, Text, theme } from "ui";
import { useQuery } from "@apollo/client";
import {
  LeaderboardQuery,
  LeaderboardQueryVariables,
  LEADERBOARD_QUERY,
} from "queries/leaderboardQuery";
import { apolloClient } from "utils/apolloClient";
import { DateRanger } from "components/DateRanger";
import { SelectorPanel } from "components/SelectorPanel";

const modes = [
  {
    label: "collectors 🤑",
    description: "people who collected the most avocados",
    key: "collectors",
  },
  {
    label: "gifters 💝",
    description: "people who gifted the most avocados to other users",
    key: "gifters",
  },
  {
    label: "receivers 🙏",
    description:
      "people who received the most avocados gifted from other users",
    key: "receivers",
  },
  {
    label: "referrers 🫵",
    description: "people who got the most avocados from refering new users",
    key: "referrers",
  },
];

export const LeaderboardPage = () => {
  const [selectedMode, setMode] = useState(modes[0]);
  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [leaderboard, setLeaderboard] = useState<
    LeaderboardQuery["leaderboard"]
  >([]);
  const [maxPoints, setMaxPoints] = useState(1);
  const { error, refetch } = useQuery<
    LeaderboardQuery,
    LeaderboardQueryVariables
  >(LEADERBOARD_QUERY, { client: apolloClient, skip: true });

  useEffect(() => {
    if (!dateRange || !dateRange[0] || !dateRange[1] || !selectedMode) return;
    refetch({
      type: selectedMode.key,
      startDate: dateRange[0],
      endDate: dateRange[1],
    }).then((data) => {
      setLeaderboard(data.data.leaderboard);
      setMaxPoints(max(data.data.leaderboard.map((u) => u.points)) || 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMode, dateRange]);

  return (
    <Box
      width="100%"
      maxWidth="1400px"
      margin="auto"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
      p="xl"
    >
      <Text size="large" mb="l">
        Leaderboard
      </Text>
      {error && <Text variant="error">{error.toString()}</Text>}
      <Box display="flex">
        <Paper
          style={{ marginBottom: theme.space.m, marginRight: theme.space.m }}
        >
          <SelectorPanel
            label="Type of acheivement"
            options={modes}
            value={selectedMode}
            onChange={setMode}
          />
        </Paper>
        <Paper style={{ marginBottom: theme.space.m }}>
          <DateRanger value={dateRange} onChange={setDateRange} />
        </Paper>
      </Box>
      <Box mt="s" width={900}>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableBody>
              {leaderboard.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar alt={user.name} src={user.imageUrl} />
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell width={100}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round((user.points * 100) / (maxPoints || 1))}
                    />
                  </TableCell>
                  <TableCell>{user.points}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
