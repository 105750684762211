import { gql } from "@apollo/client";
export type { ListSearchTagsQuery } from "./__generated__/ListSearchTagsQuery";

export const LIST_SEARCH_TAGS_QUERY = gql`
  query ListSearchTagsQuery {
    listSearchTags {
      id
      tag
      sorting
      tagType
    }
  }
`;
