import { gql } from "@apollo/client";
export type {
  GiftAvocadosMutation,
  GiftAvocadosMutationVariables,
} from "./__generated__/GiftAvocadosMutation";

export const GIFT_AVOCADOS_MUTATION = gql`
  mutation GiftAvocadosMutation($avocados: Float!, $userId: Float!) {
    giftAvocados(avocados: $avocados, userId: $userId) {
      id
      points
    }
  }
`;
