import { gql } from "@apollo/client";
export type {
  ReportQuery,
  ReportQueryVariables,
} from "./__generated__/ReportQuery";

export const REPORT_QUERY = gql`
  query ReportQuery($partnershipId: Float) {
    listReports(partnershipId: $partnershipId) {
      key
      title
      description
      options {
        key
        title
      }
    }
  }
`;
