import { Box } from "ui";

import { PollsTable } from "components/polls";
import { useAppDispatch, useAppSelector } from "utils";
import { listPolls, selectPolls } from "state/polls";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

export const Polls = () => {
  const polls = useAppSelector(selectPolls);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listPolls());
  }, [dispatch]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <PollsTable polls={cloneDeep(polls)} isLoading={false} />
    </Box>
  );
};
