import { gql } from "@apollo/client";
export type {
  MediaGetQuery,
  MediaGetQueryVariables,
} from "./__generated__/MediaGetQuery";

export const MEDIA_GET_QUERY = gql`
  query MediaGetQuery($id: Float!) {
    getMedia(id: $id) {
      key
      type
      content
      json
      createdAt
      startDate
    }
  }
`;
