import { useEffect, useState } from "react";
import { omit } from "lodash";
import { Box, Button, Text } from "ui";
import { PartnershipInput } from "../../__generated__/globalTypes";
import { useAppDispatch, useAppSelector } from "utils";
import {
  loadModulesList,
  selectAllModulesList,
  selectModule,
} from "state/learning";
import { ModuleListItem } from "components/content-editor/ModuleListItem";
import { LessonEditor } from "components/content-editor/LessonEditor";
import { Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import {
  archivePartnership,
  getPartnership,
  listPartnerships,
  selectCurrentPartnership,
  selectPartnershipError,
  selectPartnershipLoading,
  selectPartnerships,
  setSelectedPartnershipId,
  upsertPartnership,
} from "state/partnership";
import { PartnershipEdit } from "components/partnership/PartnershipEdit";

const emptyChallenge = (): PartnershipInput => ({
  internalName: "a new challenge",
  title: "new challenge",
  code: "juno-" + Math.random().toString(36).substring(2, 7) + "",
  sponsorCopy: "",
  startDate: new Date().toISOString(),
  logoImageUrl: "",
  benefits: [],
});

export const PartnershipPage = () => {
  const selectedPartnership = useAppSelector(selectCurrentPartnership);
  const modulesList = useAppSelector(selectAllModulesList);
  const module = useAppSelector(
    selectModule(selectedPartnership?.modules[0]?.id)
  );
  const dispatch = useAppDispatch();
  const [selectedLessonId, setSelectedLessonId] = useState(0);
  const [lessonEditMode, setLessonEditMode] = useState(false);
  const partnerships = useAppSelector(selectPartnerships);
  const { listLoading, upsertLoading } = useAppSelector(
    selectPartnershipLoading
  );
  const error = useAppSelector(selectPartnershipError);
  const loading = upsertLoading || (!selectedPartnership && listLoading);

  const selectLesson = (id: number, editMode = false) => {
    if (selectedLessonId === id) {
      setSelectedLessonId(0);
      setLessonEditMode(false);
    } else {
      setSelectedLessonId(id);
      setLessonEditMode(editMode);
    }
  };

  const save = async (partnershipUpdate: PartnershipInput) => {
    const partnership = {
      ...omit(
        partnershipUpdate,
        "__typename",
        "rewards",
        "modules",
        "communities",
        "participants"
      ),
      benefits: partnershipUpdate.benefits.map((b) => omit(b, "__typename")),
    };
    await dispatch(upsertPartnership({ partnership }));
  };

  const archive = async () => {
    if (!selectedPartnership) return;
    dispatch(setSelectedPartnershipId(undefined));
    setSelectedLessonId(0);
    await dispatch(
      archivePartnership({ partnershipId: selectedPartnership.id })
    );
  };

  const addChallenge = async () => {
    const result = await dispatch(
      upsertPartnership({ partnership: { ...emptyChallenge() } })
    );
    dispatch(setSelectedPartnershipId(result?.payload.upsertPartnership.id));
    setSelectedLessonId(0);
  };

  useEffect(() => {
    if (!modulesList.length) {
      dispatch(loadModulesList());
    }
  }, [modulesList.length, dispatch]);

  useEffect(() => {
    dispatch(listPartnerships());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedPartnership) return;
    dispatch(getPartnership({ partnershipId: selectedPartnership.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartnership?.id]);

  const onEditPartnership = () => {
    if (!selectedPartnership) return;
    setSelectedLessonId(0);
    setLessonEditMode(false);
  };

  return (
    <Box>
      <Box display="flex">
        <Box
          style={{ width: "230px", minHeight: "100vh" }}
          borderRight="1px solid grey"
          display="flex"
          flexDirection="column"
        >
          <Box
            p="m"
            borderBottom="1px solid grey"
            style={{ fontWeight: "bold" }}
          >
            Challenges
          </Box>
          {partnerships.map((partnership) => (
            <Box key={partnership.id}>
              <Box
                p="m"
                display="flex"
                flexDirection="column"
                borderBottom="1px solid grey"
                key={partnership.id}
                onClick={() => {
                  dispatch(
                    setSelectedPartnershipId(
                      selectedPartnership?.id === partnership.id
                        ? undefined
                        : partnership.id
                    )
                  );
                  setSelectedLessonId(0);
                }}
                backgroundColor={
                  selectedPartnership?.id === partnership.id
                    ? "grey1"
                    : "transparent"
                }
                style={{ cursor: "pointer" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Text weight="bold">{partnership.title}</Text>
                  <Box
                    className="show-on-hover"
                    p="s"
                    mr={-24}
                    my={-10}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onEditPartnership();
                    }}
                  >
                    <Tooltip title="Edit partnership challenge info">
                      <Edit style={{ height: "20px" }} />
                    </Tooltip>
                  </Box>
                </Box>
                <Text size="tiny" mt="xs" color="grey">
                  {partnership.internalName}
                </Text>
              </Box>
              {selectedPartnership?.id === partnership.id && module && (
                <ModuleListItem
                  module={module}
                  key={`module_${module.id}`}
                  selectedLessonId={selectedLessonId}
                  selectedModuleId={0}
                  onLessonSelected={selectLesson}
                  hideHeader={true}
                  collapsed={false}
                />
              )}
            </Box>
          ))}
          {loading ? (
            <Box p="m" color="grey1">
              Loading...
            </Box>
          ) : (
            <Button m="l" label="Add challenge" onClick={addChallenge} />
          )}
        </Box>
        <Box flexDirection="column" flex={1} style={{ maxWidth: 500 }}>
          {error && (
            <Box color="red" m="xl" border="1px solid red" p="m">
              {error}
            </Box>
          )}
          {selectedPartnership && !selectedLessonId && (
            <PartnershipEdit
              partnership={selectedPartnership}
              save={save}
              archive={archive}
            />
          )}
          <Box>
            {selectedPartnership && !!selectedLessonId && (
              <LessonEditor
                selectedLessonId={selectedLessonId}
                editMode={lessonEditMode}
                setEditMode={setLessonEditMode}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
