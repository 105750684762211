import { gql } from "@apollo/client";
import { REWARDS_FRAGMENT } from "./rewardsFragment";
import { ListRewards } from "./__generated__/ListRewards";
export type { ListRewards };
export type RewardType = ListRewards["rewards"][0];

export const LIST_REWARDS = gql`
  ${REWARDS_FRAGMENT}
  query ListRewards {
    rewards {
      ...RewardsFragment
    }
  }
`;
