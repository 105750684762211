import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { learningReducer } from "state/learning";
import { pollReducer } from "state/polls";
import { statsReducer } from "state/stats";
import { userReducer } from "state/user";
import { rewardReducer } from "state/reward";
import { communicationReducer } from "state/communication";
import { partnershipReducer } from "state/partnership";

const reducers = combineReducers({
  learning: learningReducer,
  stats: statsReducer,
  polls: pollReducer,
  user: userReducer,
  reward: rewardReducer,
  communication: communicationReducer,
  partnership: partnershipReducer,
});

export const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkOptions = { state: RootState };
