import { gql } from "@apollo/client";
export type { ListPollsQuery } from "./__generated__/ListPollsQuery";

export const LIST_POLLS_QUERY = gql`
  query ListPollsQuery {
    listPolls {
      id
      slug
      startDate
      endDate
      amityPostId
      amityCommentId
      status
      poll {
        title
        options {
          id
          label
        }
      }
    }
  }
`;
