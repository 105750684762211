import { gql } from "@apollo/client";
import { REWARDS_FRAGMENT } from "./rewardsFragment";
export type {
  UpsertReward,
  UpsertRewardVariables,
} from "./__generated__/UpsertReward";

export const UPSERT_REWARD = gql`
  ${REWARDS_FRAGMENT}
  mutation UpsertReward($reward: RewardInput!) {
    upsertReward(reward: $reward) {
      ...RewardsFragment
    }
  }
`;
