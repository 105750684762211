import { gql } from "@apollo/client";
import { REWARD_ACTIVITY_FRAGMENT } from "./rewardActivityFragment";
export type {
  UpdateRewardActivity,
  UpdateRewardActivityVariables,
} from "./__generated__/UpdateRewardActivity";

export const UPDATE_REWARD_ACTIVITY = gql`
  ${REWARD_ACTIVITY_FRAGMENT}
  mutation UpdateRewardActivity($id: Float!, $status: String!) {
    updateRewardActivity(id: $id, status: $status) {
      ...RewardActivityFragment
    }
  }
`;
