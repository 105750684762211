import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Box, Text } from "ui";

type PanelItem = {
  key: string;
  label: string;
  description: string;
};

type Props = {
  label: string;
  options: PanelItem[];
  value: PanelItem;
  onChange: (newValue: PanelItem) => void;
};
export const SelectorPanel = (props: Props) => {
  return (
    <Box mr="l" mx="m" my="s" flexDirection="column" display="flex">
      <Text mb="m">{props.label}</Text>
      <ToggleButtonGroup
        color="primary"
        size="small"
        value={props.value}
        exclusive
        onChange={(e, mode) => props.onChange(mode)}
      >
        {props.options.map((mode) => (
          <ToggleButton key={mode.key} value={mode}>
            <Tooltip title={mode.description}>
              <span>{mode.label}</span>
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
