import {
  ARCHIVE_PARTNERSHIP_MUTATION,
  ArchivePartnershipMutation,
  ArchivePartnershipMutationVariables,
} from "queries/partnership/archivePartnershipMutation";
import {
  CONNECT_PARTNERSHIP_MODULE_MUTATION,
  ConnectPartnershipModule,
  ConnectPartnershipModuleVariables,
} from "queries/partnership/connectPartnershipModule";
import {
  LIST_PARTNERSHIPS,
  ListPartnerships,
} from "queries/partnership/listPartnershipsQuery";
import {
  PARTNERSHIP_QUERY,
  PartnershipQuery,
  PartnershipQueryVariables,
} from "queries/partnership/partershipQuery";
import {
  UPSERT_PARTNERSHIP_MUTATION,
  UpsertPartnership,
  UpsertPartnershipVariables,
} from "queries/partnership/upserPartnershipMutation";
import {
  createMutationThunk,
  createQueryThunk,
} from "utils/createMutationThunk";

export const getPartnership = createQueryThunk<
  PartnershipQuery,
  PartnershipQueryVariables
>("partnership/getPartnership", PARTNERSHIP_QUERY);

export const upsertPartnership = createMutationThunk<
  UpsertPartnership,
  UpsertPartnershipVariables
>("partnership/upsertPartnership", UPSERT_PARTNERSHIP_MUTATION);

export const listPartnerships = createQueryThunk<ListPartnerships>(
  "partnership/listPartnerships",
  LIST_PARTNERSHIPS
);

export const archivePartnership = createMutationThunk<
  ArchivePartnershipMutation,
  ArchivePartnershipMutationVariables
>("partnership/archivePartnership", ARCHIVE_PARTNERSHIP_MUTATION);

export const connectPartnershipModule = createMutationThunk<
  ConnectPartnershipModule,
  ConnectPartnershipModuleVariables
>("partnership/connectPartnershipModule", CONNECT_PARTNERSHIP_MODULE_MUTATION);
