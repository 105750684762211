import { Box, Text, theme } from "ui";

type Props = {
  text: string;
  optionTextAlign: "center" | "left";
  backgroundColor?: string;
};

export const PollOption = ({
  text,
  optionTextAlign,
  backgroundColor,
}: Props) => (
  <Box
    px="l"
    py={12}
    mt="m"
    mb="xs"
    hovered
    backgroundColor={backgroundColor || theme.colors.white}
    color={theme.colors.primary}
    display="flex"
    justifyContent={optionTextAlign}
    alignItems="center"
    width="100%"
    borderRadius={16}
  >
    <Text textAlign={optionTextAlign} fontSize={14}>
      {text}
    </Text>
  </Box>
);
