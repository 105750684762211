import { useEffect, useState } from "react";
import {
  getCommunityPollStatistics,
  listPolls,
  selectPollStatistics,
  upsertPoll,
} from "state/polls";
import { Box, Text, TextField, Button } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

import { Poll } from "./types";
type PollDetailsProps = {
  poll: Poll;
  disabled?: boolean;
};

const pollDetails = (poll: Poll) => ({
  slug: poll.slug,
  title: poll.poll.title,
  status: poll.status,
  option1: poll.poll.options[0].label,
  option2: poll.poll.options[1].label,
  option3: poll.poll.options[2].label,
});

const pollUpdate = (id: number, details: ReturnType<typeof pollDetails>) =>
  ({
    id: id,
    slug: details.title.toLowerCase().replace(/\s/g, "-"),
    poll: {
      title: details.title,
      options: [
        {
          id: 1,
          label: details.option1,
        },
        {
          id: 2,
          label: details.option2,
        },
        {
          id: 3,
          label: details.option3,
        },
      ],
    },
  } as Poll);

export const PollDetails = (props: PollDetailsProps) => {
  const { poll } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState("");
  const [currPollDetails, setCurrPollDetails] = useState(pollDetails(poll));
  const dispatch = useAppDispatch();
  const statistics = useAppSelector(selectPollStatistics(props.poll.id));

  useEffect(() => {
    dispatch(getCommunityPollStatistics({ feedbackRequestId: poll!.id }));
    const intervalId = setInterval(() => {
      dispatch(getCommunityPollStatistics({ feedbackRequestId: poll!.id }));
    }, 2000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poll]);

  const updateField = (e: any, field: string) => {
    setCurrPollDetails({ ...currPollDetails, [field]: e.target.value });
  };

  return (
    <Box m="m" p="m" border="1px solid black">
      <Text weight="bold">Title</Text>
      {isEditMode ? (
        <TextField
          value={currPollDetails.title}
          onChange={(e) => {
            updateField(e, "title");
          }}
        />
      ) : (
        <Text>{currPollDetails.title}</Text>
      )}
      <Text mt="m" weight="bold">
        Option 1 -{" "}
        {statistics?.find((o) => o.option === 1)?.chosenPercentage || 0}% (
        {statistics?.find((o) => o.option === 1)?.count || 0})
      </Text>
      {isEditMode ? (
        <TextField
          value={currPollDetails.option1}
          onChange={(e) => {
            updateField(e, "option1");
          }}
        />
      ) : (
        <Text>{currPollDetails.option1}</Text>
      )}
      <Text mt="m" weight="bold">
        Option 2 -{" "}
        {statistics?.find((o) => o.option === 2)?.chosenPercentage || 0}% (
        {statistics?.find((o) => o.option === 2)?.count || 0})
      </Text>
      {isEditMode ? (
        <TextField
          value={currPollDetails.option2}
          onChange={(e) => {
            updateField(e, "option2");
          }}
        />
      ) : (
        <Text>{currPollDetails.option2}</Text>
      )}
      <Text mt="m" weight="bold">
        Option 3 -{" "}
        {statistics?.find((o) => o.option === 3)?.chosenPercentage || 0}% (
        {statistics?.find((o) => o.option === 3)?.count || 0})
      </Text>
      {isEditMode ? (
        <TextField
          value={currPollDetails.option3}
          onChange={(e) => {
            updateField(e, "option3");
          }}
        />
      ) : (
        <Text>{currPollDetails.option3}</Text>
      )}
      <Box mt="xl" width={600}>
        {!isEditMode ? (
          <Box width={200}>
            <Button
              label="Edit"
              onClick={() => {
                setIsEditMode(true);
              }}
              disabled={props.disabled}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="row">
            <Box>
              <Button
                label="Cancel"
                onClick={() => {
                  setCurrPollDetails(pollDetails(poll));
                  setIsEditMode(false);
                }}
              />
            </Box>
            {error && <Text color="red">There was an error: {error}</Text>}
            <Box ml="m">
              <Button
                label="Save"
                onClick={async () => {
                  const update = pollUpdate(poll.id, currPollDetails);
                  const res = await dispatch(upsertPoll({ poll: update }));
                  if (res.payload.error) {
                    setError(res.payload.error);
                    return;
                  } else {
                    setError("");
                  }
                  await dispatch(listPolls());
                  // setIsEditMode(false);
                }}
              />
            </Box>
            {poll.status === "draft" && (
              <Box ml="m">
                <Button
                  label='Mark as "ready for publishing"'
                  onClick={async () => {
                    const update = pollUpdate(poll.id, currPollDetails);
                    await dispatch(
                      upsertPoll({ poll: { ...update, status: "ready" } })
                    );
                    await dispatch(listPolls());
                  }}
                />
              </Box>
            )}
            {poll.status === "ready" && (
              <Box ml="m">
                <Button
                  label="Mark as draft"
                  onClick={async () => {
                    const update = pollUpdate(poll.id, currPollDetails);
                    await dispatch(
                      upsertPoll({ poll: { ...update, status: "draft" } })
                    );
                    await dispatch(listPolls());
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
