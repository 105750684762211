import { UserModulesQuery } from "queries/user/userModulesQuery";
import { CSSProperties, FC, useRef } from "react";
import { Box, Text } from "ui";
import Eyecon from "@material-ui/icons/RemoveRedEyeOutlined";
import CrossedEyecon from "@material-ui/icons/VisibilityOffOutlined";
import { UserLessons } from "./UserLessons";

type ModuleItem = UserModulesQuery["user"]["userModules"][number];

export interface BoxProps {
  module: ModuleItem;
  variant: "enabled" | "disabled";
}

const style: CSSProperties = {
  padding: "0 1.5rem",
  marginRight: "16px",
  marginBottom: "1rem",
  borderRadius: "18px",
};

export const ModuleCard: FC<BoxProps> = ({ module, variant }) => {
  const ref = useRef<HTMLDivElement>(null);

  const variantColor = !module.movable
    ? "#CDEC85"
    : variant === "enabled"
    ? "#F0F0FB"
    : "#ECECEC";
  return (
    <div>
      <div
        style={{
          height: "2px",
          marginTop: "-8px",
          marginBottom: "8px",
        }}
      />
      <div
        ref={ref}
        style={{ ...style, backgroundColor: variantColor }}
        data-testid={`box`}
      >
        <Box
          height="68px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="s"
            title={
              module.movable
                ? "drag to move 🕺"
                : "💡 Free modules and modules that have already been completed or are in prorgress can't be moved or hidden."
            }
          >
            <Text size="small">{module.title}</Text>
            {module.movable &&
              (variant === "enabled" ? <Eyecon /> : <CrossedEyecon />)}
          </Box>
          <UserLessons lessons={module.userLessons} clickable={true} />
        </Box>
      </div>
    </div>
  );
};
