import { gql } from "@apollo/client";
export type {
  DeletePathQuizMutation,
  DeletePathQuizMutationVariables,
} from "./__generated__/DeletePathQuizMutation";

export const DELETE_PATH_QUIZ_MUTATION = gql`
  mutation DeletePathQuizMutation($id: Float!) {
    deletePathQuiz(id: $id)
  }
`;
