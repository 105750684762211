import { gql } from "@apollo/client";
export type {
  SaveLessonOrder,
  SaveLessonOrderVariables,
} from "./__generated__/SaveLessonOrder";

export const SAVE_LESSON_ORDER_MUTATION = gql`
  mutation SaveLessonOrder($lessonIds: [Float!]!) {
    saveLessonOrder(lessonIds: $lessonIds)
  }
`;
