import { gql } from "@apollo/client";
export type {
  DeleteReward,
  DeleteRewardVariables,
} from "./__generated__/DeleteReward";

export const DELETE_REWARD = gql`
  mutation DeleteReward($id: Float!) {
    deleteReward(id: $id) {
      id
    }
  }
`;
