import { gql } from "@apollo/client";
import { MODULES_FRAGMENT } from "./modulesFragment";
export type {
  CopyModuleAsDraft,
  CopyModuleAsDraftVariables,
} from "./__generated__/CopyModuleAsDraft";

export const COPY_MODULE_AS_DRAFT = gql`
  ${MODULES_FRAGMENT}
  mutation CopyModuleAsDraft($moduleId: Float!) {
    draftModule(moduleId: $moduleId) {
      ...ModulesFragment
    }
  }
`;
