import { useCallback, useState, useEffect, useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import update from "immutability-helper";
import uniqid from "uniqid";
import { Box, Text } from "ui";
import { CardElement } from "queries/richTextElementFragment";

import { elementsDefaults } from "../dataDefaults";

import { Element } from "./Element";
import { AddElement } from "./AddElement";

type Props = {
  elements: CardElement[];
  onUpdate: (elements: CardElement[]) => void;
};

export const GenericElementsEditor = ({ elements, ...props }: Props) => {
  const sessionElements = useRef<CardElement[] | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRefresh] = useState(true);

  useEffect(() => {
    sessionElements.current = elements.map((element, idx) => ({
      ...element,
      order: idx,
    }));
    setRefresh((prev) => !prev);
  }, [elements]);

  const [elementInEditMode, setElemenetInEditMode] = useState<
    number | undefined
  >(undefined);

  const onUpdate = (updatedElements: CardElement[], idx?: number) => {
    props.onUpdate(updatedElements);
    if (idx !== undefined) {
      handleEditMode(idx, false);
    }
  };

  const handleEditMode = (idx: number, isEditMode: boolean) => {
    setElemenetInEditMode(isEditMode ? idx : undefined);
  };

  const handleUpdateElement = async (element: CardElement, idx: number) => {
    const updatedElements = cloneDeep(elements);
    updatedElements[idx] = element;
    onUpdate(updatedElements, idx);
  };

  const handleAddElement = (idx: number) => (type: string) => {
    const updatedElements = cloneDeep(elements);
    updatedElements.splice(idx, 0, elementsDefaults[type]);
    onUpdate(updatedElements, idx);
  };

  const handleDeleteElement = async (idx: number) => {
    const updatedElements = cloneDeep(elements);
    updatedElements.splice(idx, 1);
    onUpdate(updatedElements, idx);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    if (!dragIndex) {
      return;
    }
    sessionElements.current = update(sessionElements.current, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, sessionElements.current![dragIndex] as CardElement],
      ],
    });
    setRefresh((prev) => !prev);
  }, []);

  const saveNewOrder = (inPlace: boolean) => {
    if (inPlace) {
      // const updatedElements = cloneDeep(elements);
      const updatedElements = sessionElements.current!.map((e) => ({ ...e }));
      onUpdate(updatedElements);
    } else {
      sessionElements.current = sessionElements.current!.map(
        (element, idx) => ({
          ...element,
          order: idx,
        })
      );
      setRefresh((prev) => !prev);
    }
  };

  if (!sessionElements.current) {
    return <Text>loading...</Text>;
  }

  return (
    <Box>
      <Box>
        {sessionElements.current!.map((element, idx) => (
          <Box mt={idx > 0 ? "m" : undefined} key={uniqid()}>
            <Element
              index={idx}
              element={element}
              onUpdateElement={(element) => handleUpdateElement(element, idx)}
              isEditMode={elementInEditMode === idx}
              isFirstElement={idx === 0}
              isLastElement={idx + 1 === elements.length}
              onToggleEditMode={(isEditMode) => handleEditMode(idx, isEditMode)}
              onDeletElement={() => handleDeleteElement(idx)}
              onAddElementBefore={handleAddElement(idx)}
              onAddElementAfter={handleAddElement(idx + 1)}
              onMoveCard={moveCard}
              onCardDropped={saveNewOrder}
              enableDragging={elementInEditMode === undefined}
            />
          </Box>
        ))}
        <AddElement onAddElement={handleAddElement(elements.length)} />
      </Box>
    </Box>
  );
};
