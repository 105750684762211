import { useEffect } from "react";

import { Box, Text, Card, Loader, Button } from "ui";
import { useAppSelector, useAppDispatch } from "utils";
import { loadStats, selectRequestStatus, selectGlobalStats } from "state/stats";
import { ToDosTable } from "components/stats";

export const Stats = () => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);
  const globalStats = useAppSelector(selectGlobalStats);

  useEffect(() => {
    dispatch(loadStats());
  }, [dispatch]);

  const refreshStats = () => {
    dispatch(loadStats());
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="100%" display="flex" pl={20} pt={20}>
        <Button label="refresh" onClick={refreshStats} />
      </Box>
      <Box display="flex" flexDirection="column" pt={20}>
        <Box display="flex" flexDirection="row">
          <Card
            width={200}
            height={150}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text>No of users</Text>
            {requestStatus === "loading" ? (
              <Loader size={50} />
            ) : (
              <Text size="big" mt="s">
                {globalStats.users}
              </Text>
            )}
          </Card>
          <Card
            width={200}
            height={150}
            ml="m"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text>Lessons Completed</Text>
            {requestStatus === "loading" ? (
              <Loader size={50} />
            ) : (
              <Text size="big" mt="s">
                {globalStats.lessonsCompleted}
              </Text>
            )}
          </Card>
          <Card
            width={200}
            height={150}
            ml="m"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text>Modules Completed</Text>
            {requestStatus === "loading" ? (
              <Loader size={50} />
            ) : (
              <Text size="big" mt="s">
                {globalStats.modulesCompleted}
              </Text>
            )}
          </Card>
          <Card
            width={200}
            height={150}
            ml="m"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text>To-Dos Completed</Text>
            {requestStatus === "loading" ? (
              <Loader size={50} />
            ) : (
              <Text size="big" mt="s">
                {globalStats.todosTaken}
              </Text>
            )}
          </Card>
        </Box>
        <Box mt="xl">
          <Card>
            <ToDosTable
              todos={globalStats.todos.map((todo) => ({
                title: todo.title
                  .replace("<p>", "")
                  .replace("</p>", "")
                  .replace("<strong>", "")
                  .replace("</strong>", "")
                  .replace("&nbsp;", ""),
                count: todo.count,
              }))}
              isLoading={requestStatus === "loading"}
            />
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
