import ReactPopup from "reactjs-popup";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { Box } from "ui";

type PopupProps = {
  trigger: JSX.Element;
  items: string[];
  onChoose: (item: string) => void;
};
export const Popup = (props: PopupProps) => {
  const { trigger, items, onChoose } = props;

  return (
    <ReactPopup
      trigger={trigger}
      position="bottom center"
      on="click"
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{
        padding: "0px",
        border: "2px black solid",
        borderRadius: 8,
      }}
      arrow={false}
    >
      {
        ((close: any) => (
          <List>
            {items.map((item: string, idx: number) => (
              <Box key={`popup_${idx}`}>
                <ListItem
                  button
                  dense
                  onClick={() => {
                    onChoose(item);
                    close();
                  }}
                >
                  <ListItemText>{item}</ListItemText>
                </ListItem>
                {idx + 1 < items.length && <Divider />}
              </Box>
            ))}
          </List>
        )) as any
      }
    </ReactPopup>
  );
};
