import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { Provider as StateProvider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";
import { theme } from "ui";
import { store } from "./store";
import { Layout } from "components/Layout";

import "./index.css";
import "./app.css";
import "@fontsource/roboto";
import "react-datepicker/dist/react-datepicker.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StateProvider store={store}>
          <DndProvider backend={HTML5Backend}>
            <Layout />
          </DndProvider>
        </StateProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
