import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, theme } from "ui";
import { StatusBadge } from "components/StatusBadge";
import { Link } from "react-router-dom";
import {
  deletePathQuiz,
  listPathQuizzes,
  reorderPathQuizzes,
  selectPathQuizzes,
} from "state/learning";
import { useAppDispatch, useAppSelector } from "utils";
import { useEffect } from "react";

function moveItem(from: number, to: number, data: any[]) {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0];
  // insert stored item into position `to`
  data.splice(to, 0, f);
}

export const PathQuizPage = () => {
  const quizzes = useAppSelector(selectPathQuizzes);
  const dispatch = useAppDispatch();
  const quizIds = quizzes.map((quiz) => quiz.id);

  useEffect(() => {
    dispatch(listPathQuizzes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteQuiz = (id: number) => {
    dispatch(deletePathQuiz({ id }));
  };

  const moveQuiz = (index: number, direction: -1 | 1) => {
    if (index === 0 && direction === -1) return;
    if (index === quizzes.length - 1 && direction === 1) return;
    const quizOrder = [...quizIds];
    moveItem(index, index + direction, quizOrder);
    dispatch(reorderPathQuizzes({ ids: quizOrder }));
  };

  return (
    <Box my="l" mx="xl">
      <h1>Path Quizzes</h1>
      <h2>Questions</h2>
      <Box mb="l" display="flex">
        <TableContainer component={Box}>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "gray" }}>Title</TableCell>
              <TableCell style={{ color: "gray", textAlign: "center" }}>
                Type
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizzes.map((question, i) => (
              <TableRow key={question.id}>
                <TableCell>{question.title}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <StatusBadge status={question.type} />
                </TableCell>
                <TableCell>
                  <Box width={90} display="flex" justifyContent="space-between">
                    <Tooltip title="Delete this quiz 🔥">
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteQuiz(question.id)}
                      />
                    </Tooltip>
                    <Tooltip title="Edit this quiz ✏️">
                      <Link
                        to={`/path-quiz/edit/${question.id}`}
                        style={{ color: theme.colors.almostBlack }}
                      >
                        <EditIcon />
                      </Link>
                    </Tooltip>
                    <KeyboardArrowDownIcon
                      style={
                        i === quizzes.length - 1
                          ? buttonStyle.notAllowed
                          : buttonStyle.allowed
                      }
                      color={i === quizzes.length - 1 ? "disabled" : undefined}
                      onClick={() => moveQuiz(i, 1)}
                    />
                    <KeyboardArrowUpIcon
                      style={
                        i === 0 ? buttonStyle.notAllowed : buttonStyle.allowed
                      }
                      color={i === 0 ? "disabled" : undefined}
                      onClick={() => moveQuiz(i, -1)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Box>
      <Box display="flex">
        <Link to={`/path-quiz/create`} style={{ textDecoration: "none" }}>
          <Button label="Add Question" />
        </Link>
      </Box>
    </Box>
  );
};

const buttonStyle = {
  allowed: {
    cursor: "pointer",
  },
  notAllowed: {
    cursor: "not-allowed",
  },
};
