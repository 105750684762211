import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Edit } from "@material-ui/icons";

import { Box, Text } from "ui";

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

type ListItemProps = {
  label: string;
  subLabel?: string;
  icon?: ReactNode;
  selected?: boolean;
  onSelected?: () => void;
  onEditMetadata?: () => void;
  noBorder?: boolean;
};

export const ListItem: FC<ListItemProps> = ({
  label,
  subLabel,
  icon,
  selected = false,
  onSelected,
  onEditMetadata,
  noBorder,
}) => {
  return (
    <Box
      minHeight={50}
      width="100%"
      hovered
      backgroundColor={selected ? "grey1" : undefined}
      borderTop={noBorder ? undefined : "1px grey solid"}
      borderBottom="1px grey solid"
      display="flex"
      alignItems="center"
      onClick={onSelected}
    >
      <Box width="100%" display="flex" alignItems="center" pl="s">
        {icon && <Box mr="s">{icon}</Box>}
        <Box width="100%" pl="s" display="flex" flexDirection="column">
          <Text>{label}</Text>
          {subLabel && <StyledText size="tiny">{subLabel}</StyledText>}
        </Box>

        {onEditMetadata && (
          <Box
            onClick={(e: any) => {
              e.stopPropagation();
              onEditMetadata();
            }}
            className="show-on-hover"
          >
            <Edit style={{ height: "20px" }} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
