import CreatableSelect from "react-select/creatable";
import { Tooltip } from "@material-ui/core";
import { ImageUpload } from "components/ImageUpload";
import { useEffect, useState } from "react";
import {
  cloneLesson,
  createSearchTag,
  listSearchTags,
  loadModulesList,
  saveLessonOrder,
  selectCurrLesson,
  selectCurrLessonId,
  selectLessonOrder,
  selectRequestStatus,
  selectSearchTags,
  updateLesson,
} from "state/learning";
import { Box, Text, Button } from "ui";
import { useAppDispatch, useAppSelector } from "utils";

type LessonUpdate = {
  imageUrl?: string;
  title?: string;
  minutes?: number;
  milestone?: boolean;
  searchTags?: string[];
};

type Props = {
  onDelete: () => void;
};

export const LessonMetaDataEditor = (props: Props) => {
  const [title, setTitle] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [milestone, setMilestone] = useState(false);
  const searchTags = useAppSelector(selectSearchTags("lesson"));
  const dispatch = useAppDispatch();

  const currLesson = useAppSelector(selectCurrLesson);
  const currLessonId = useAppSelector(selectCurrLessonId);
  const lessonOrder = useAppSelector(selectLessonOrder);
  const requestStatus = useAppSelector(selectRequestStatus);

  const saveLesson = (update: LessonUpdate) => {
    const lesson = { ...currLesson, ...update };
    dispatch(updateLesson({ lesson, lessonId: currLessonId! }));
  };

  useEffect(() => {
    setTitle(currLesson?.title || "");
    setImageUrl(currLesson?.imageUrl || "");
    setMinutes(currLesson?.minutes || 0);
    setMilestone(currLesson?.milestone || false);
  }, [currLesson]);

  useEffect(() => {
    dispatch(listSearchTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMove = (direction: number) => {
    // Swap the lessons!
    const newLessonOrder = [...lessonOrder];
    const currIndex = newLessonOrder.indexOf(currLessonId!);
    const targetIndex = currIndex + direction;
    const targetId = newLessonOrder[targetIndex];
    newLessonOrder[targetIndex] = currLessonId!;
    newLessonOrder[currIndex] = targetId;
    dispatch(saveLessonOrder({ lessonIds: newLessonOrder }));
  };

  const onCloneLesson = async () => {
    await dispatch(cloneLesson({ lessonId: currLessonId! }));
    await dispatch(loadModulesList());
  };

  return (
    <Box px="s" py="s">
      <Text mb="s">
        Title:&nbsp;
        <input
          value={title}
          onBlur={() => saveLesson({ title })}
          onChange={(e) => setTitle(e.target.value)}
          size={30}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              setTitle(currLesson!.title);
            }
            if (e.key === "Enter") {
              saveLesson({ title });
            }
          }}
        />
      </Text>
      <Text mb="s">
        Minutes:&nbsp;
        <input
          value={minutes}
          type="number"
          onBlur={() => saveLesson({ minutes })}
          onChange={(e) => setMinutes(parseInt(e.target.value, 10))}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              setTitle(currLesson!.title);
            }
            if (e.key === "Enter") {
              saveLesson({ title });
            }
          }}
        />
      </Text>
      <Box width="400px">
        <ImageUpload
          src={imageUrl}
          onUpdateSrc={(imageUrl) => saveLesson({ imageUrl })}
        />
      </Box>
      <Box mt="xl" display="flex">
        <Button
          label="Move up ⬆️"
          onClick={() => onMove(-1)}
          disabled={
            lessonOrder.indexOf(currLessonId!) === 0 ||
            requestStatus === "saveLessonOrder_loading"
          }
        />
        <Button
          ml="s"
          label="Move down ⬇️"
          onClick={() => onMove(1)}
          disabled={
            lessonOrder.indexOf(currLessonId!) === lessonOrder.length - 1 ||
            requestStatus === "saveLessonOrder_loading"
          }
        />
        <Tooltip title="This feature is disabled for now.">
          <Button
            ml="s"
            label="Clone lesson 🐑🐑"
            onClick={onCloneLesson}
            backgroundColor="lightgrey"
            disabled={true}
          />
        </Tooltip>
      </Box>
      <Box mt="l">
        <input
          checked={milestone}
          type="checkbox"
          id="milestone_check"
          onChange={() => {
            setMilestone(!milestone);
            saveLesson({ milestone: !milestone });
          }}
        />{" "}
        <label htmlFor="milestone_check">this lesson is a milestone</label>
      </Box>
      <Box mt="l">
        <Text mb="xs">Choose Tags:</Text>
        <CreatableSelect
          isMulti
          isClearable
          options={searchTags.map((t) => ({ label: t.tag, value: t.id }))}
          value={
            currLesson?.searchTags.map((t) => ({
              label: t,
              value: searchTags.find((st) => st.tag === t)?.id,
            })) || []
          }
          onChange={(v) => {
            const newSearchTags = v?.map((t) => t.label) || [];
            saveLesson({ searchTags: newSearchTags });
          }}
          onCreateOption={async (value) => {
            await dispatch(createSearchTag({ tag: value, tagType: "lesson" }));
            saveLesson({ searchTags: currLesson?.searchTags.concat(value) });
          }}
        />
      </Box>
      {showDelete ? (
        <Box mt="xl">
          <hr />
          Really? You will loose all the data!
          <Box display="flex" mt="s">
            <Button
              label="🔥YES🔥"
              onClick={props.onDelete}
              backgroundColor="darkred"
              mr="s"
            />
            <Button
              label="ok mabye not..."
              onClick={() => setShowDelete(false)}
            />
          </Box>
        </Box>
      ) : (
        <Box mt="xl">
          <Button
            label="DELETE LESSON 🗑"
            onClick={() => setShowDelete(true)}
            backgroundColor="lightgrey"
          />
        </Box>
      )}
    </Box>
  );
};
