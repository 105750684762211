import { gql } from "@apollo/client";
import { RICHTEXT_ELEMENT_FRAGMENT } from "../richTextElementFragment";
export type {
  LessonDetailsQuery,
  LessonDetailsQueryVariables,
  LessonDetailsQuery_getLesson,
  LessonDetailsQuery_getLesson_cards,
  LessonDetailsQuery_getLesson_cards_richtext as RichTextCard,
  LessonDetailsQuery_getLesson_cards_video as VideoCard,
  LessonDetailsQuery_getLesson_cards_quiz as QuizCard,
  LessonDetailsQuery_getLesson_cards_poll as PollCard,
  LessonDetailsQuery_getLesson_cards_actionitem as ActionItemCard,
  LessonDetailsQuery_getLesson_cards_survey as SurveyCard,
  LessonDetailsQuery_getLesson_cards as LessonCard,
  LessonDetailsQuery_getLesson_cards_richtext_elements,
} from "./__generated__/LessonDetailsQuery";

export const LESSON_DETAILS = gql`
  ${RICHTEXT_ELEMENT_FRAGMENT}
  query LessonDetailsQuery($lessonId: Float!) {
    getLesson(lessonId: $lessonId) {
      title
      imageUrl
      minutes
      milestone
      searchTags
      cards {
        __typename
        ... on richtext {
          id
          title
          elements {
            ...RichTextElementFragment
          }
        }
        ... on video {
          id
          title
          uri
        }
        ... on quiz {
          id
          title
          trueReply
          falseReply
          options {
            id
            label
            answer
            falseReply
          }
          elements {
            ...RichTextElementFragment
          }
        }
        ... on poll {
          id
          title
          options {
            id
            label
          }
          elements {
            ...RichTextElementFragment
          }
        }
        ... on actionitem {
          id
          title
          taskDuration
          elements {
            ...RichTextElementFragment
          }
        }
        ... on survey {
          id
          title
          elements {
            ...RichTextElementFragment
          }
        }
      }
    }
  }
`;
